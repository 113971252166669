import { isCustomRolesEnabled, isNoAccessRoleEnabled } from '@gonfalon/dogfood-flags';
import { formattedRoleName, RoleName } from '@gonfalon/permissions';
import { FilterOption } from 'launchpad';

import { getFilteredRoles, getRole } from 'sources/RoleAPI';
import { jsonToImmutableError } from 'utils/httpUtils';
import { createRole, Role } from 'utils/roleUtils';
import { stringContains } from 'utils/stringUtils';

type RoleOptionType = { name: string; value: string; isChecked: boolean };

const createTempRoleForDropdown = (roleKey: RoleName) => createRole({ key: roleKey, name: formattedRoleName(roleKey) });

const getBuiltInRoles = () => {
  const builtInRoles = ['admin', 'writer', 'reader'];
  if (isNoAccessRoleEnabled()) {
    builtInRoles.push('no_access');
  }
  return builtInRoles;
};

const isBuiltInRole = (roleKey: string) => getBuiltInRoles().includes(roleKey);

const getMenuOptions = ({
  maxOptions = 8,
  rolesMatchingFilter,
  searchValue,
  selectedRoles,
}: {
  fetchedRoles?: Role[];
  maxOptions?: number;
  rolesMatchingFilter?: Role[];
  searchValue: string;
  selectedRoles: Role[];
}): FilterOption[] => {
  if (searchValue !== '' && rolesMatchingFilter?.length === 0) {
    return [
      {
        isDisabled: true,
        name: 'No matching roles found',
        value: '',
      },
    ];
  }
  const roleOptions: RoleOptionType[] = [];
  const builtInRoles = getBuiltInRoles();
  const selectedRoleKeys = selectedRoles.map((role: Role) => role.key);

  for (const builtInRoleKey of builtInRoles) {
    if (
      searchValue === '' ||
      stringContains(builtInRoleKey, searchValue) ||
      stringContains(formattedRoleName(builtInRoleKey as RoleName), searchValue)
    ) {
      const roleName = formattedRoleName(builtInRoleKey as RoleName);
      roleOptions.push({
        name: roleName,
        value: builtInRoleKey,
        isChecked: selectedRoleKeys.includes(builtInRoleKey),
      });
    }
  }

  if (isCustomRolesEnabled() && !!rolesMatchingFilter?.length) {
    for (const role of rolesMatchingFilter) {
      if (stringContains(role.key, searchValue) || stringContains(role.name, searchValue)) {
        roleOptions.push({
          name: role.name,
          value: role.key,
          isChecked: selectedRoleKeys.includes(role.key),
        });
      }
    }
  }

  let menuOptions: FilterOption[] = [...roleOptions.slice(0, maxOptions)];
  menuOptions = [
    { value: 'all', name: 'All', isChecked: selectedRoles.length === 0 },
    { isDivider: true, value: '' },
    ...menuOptions,
  ];
  return menuOptions;
};

export type RoleFetchingQueryStringProps = {
  limit?: number;
  offset?: number;
  searchValue?: string;
};
const getRoleFetchingQueryString = ({ limit, offset, searchValue }: RoleFetchingQueryStringProps) => {
  const queryStringParts = [];
  if (limit && limit >= 0) {
    queryStringParts.push(`limit=${limit}`);
  }
  if (offset) {
    queryStringParts.push(`offset=${offset}`);
  }
  if (searchValue) {
    queryStringParts.push(`filter=query:${searchValue}`);
  }
  return queryStringParts.join('&');
};

const getDescription = (selectedRoles: Role[]): string =>
  selectedRoles?.length ? selectedRoles?.map((role) => role.name).join(', ') : 'All';

// pass the url in for easier testing
const getRoleKeysFromUrlString = (url: string) => {
  const params = new URL(url).searchParams;
  return params.getAll('roles');
};

// this function parses the URL string, then does  fetch for each roleKey listed
// it returns an array of Roles that the dropdown menu can use
const getRolesFromParams = (fetchedRoles: Role[]): Role[] => {
  const roleKeys = getRoleKeysFromUrlString(document.location.href);
  const roles: Role[] = [];
  roleKeys.forEach((roleKey: string) => {
    if (isBuiltInRole(roleKey)) {
      roles.push(createTempRoleForDropdown(roleKey as RoleName));
    } else {
      const foundRole = fetchedRoles.find((fetchedRole) => fetchedRole.key === roleKey);
      // if we found it, no need to fetch it from the backend
      if (!!foundRole) {
        roles.push(foundRole);
      } else {
        getRole(roleKey)
          .then((fetchedRole) => {
            if (fetchedRole) {
              roles.push(fetchedRole);
            }
          })
          .catch(jsonToImmutableError);
      }
    }
  });
  return roles;
};

const getRolesFromSearchString = async (searchValue: string): Promise<Role[]> => {
  const newlyFetchedRoles = await getFilteredRoles({ searchValue });
  const newRolesForDropdown: Role[] = [];
  newlyFetchedRoles.getIn(['entities', 'roles']).forEach((role: Role) => {
    newRolesForDropdown.push(
      createRole({
        key: role.key,
        name: role.name,
      }),
    );
  });
  return newRolesForDropdown;
};

// This function constructs an updates string that we'll use to update the page address.
// We have to do it this way because of the goofy way we structure our URLs on the Members page,
// with `roles=abc&roles=def&roles=ghi`, rather than some sort of `roles=abc,def,ghi` alternate.
// The "multiple identical params" syntax is a legitimate URL structure, but it's not as common.
const getSearchStringForURL = ({
  selectedRoleKeys,
  url = document.location.href,
  resetPagination,
}: {
  selectedRoleKeys?: string[];
  url?: string;
  resetPagination?: boolean;
}) => {
  const urlParams = new URL(url).searchParams;
  urlParams.delete('roles');
  if (resetPagination) {
    // make sure to reset the pagination
    urlParams.delete('limit');
    urlParams.delete('offset');
  }
  const roles = selectedRoleKeys?.join('&roles=');
  let searchString = `${urlParams.toString()}`;
  if (!!roles?.length) {
    if (searchString.length) {
      searchString += '&';
    }
    searchString += `roles=${roles}`;
  }
  return searchString;
};

const getUpdatedSelectedRoles = (option: FilterOption, selectedRoles: Role[]) => {
  const keysFromSelectedRoles = selectedRoles.map((role: Role) => role.key);
  if (keysFromSelectedRoles.includes(option.value)) {
    // remove that role from selectedRoles
    return selectedRoles.filter((item) => item.key !== option.value);
  }
  if (isBuiltInRole(option.value)) {
    return [...selectedRoles, createTempRoleForDropdown(option.value)];
  }
  return [...selectedRoles, createRole({ key: option.value, name: `${option.name}` })];
};

export {
  createTempRoleForDropdown,
  getBuiltInRoles,
  getDescription,
  getMenuOptions,
  getRoleFetchingQueryString,
  getRoleKeysFromUrlString,
  getRolesFromParams,
  getRolesFromSearchString,
  getSearchStringForURL,
  getUpdatedSelectedRoles,
  isBuiltInRole,
};
