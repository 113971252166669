import { NavigateFunction, To, useNavigate } from 'react-router';
import {
  debuggerFullFidelityEventDurationMinutes,
  enableReleaseGuardianRefreshedUIDropdown,
  isFlagStatusEnabled,
  shouldCleanupAfterFlagDeletion,
} from '@gonfalon/dogfood-flags';
import { FeatureFlag } from '@gonfalon/flags';
import { getQueryClient } from '@gonfalon/react-query-client';
import { experimentsList, flagsDetail, flagsList, getMeasuredRolloutsQuery, JSONPatch } from '@gonfalon/rest-api';
import { List, Map, OrderedMap, Set } from 'immutable';
import { defer, isArray, isEqual, noop } from 'lodash';
import nullthrows from 'nullthrows';

import { fetchScheduledChangesForFlag } from 'actions/scheduledChanges';
import flagLinksActionTypes from 'actionTypes/flagLinks';
import actionTypes from 'actionTypes/flags';
import { PendingProgressiveRollout } from 'components/automated-rollouts/progressive-rollouts/FlagTargetingPendingProgressiveRolloutsProvider';
import { ExperimentsResultQuery } from 'components/FlagExperiments/types';
import { ReleaseStrategy } from 'components/LegacyReleaseStrategyModal';
import { useDispatch } from 'hooks/useDispatch';
import { GetState, GlobalDispatch, GlobalState } from 'reducers';
import {
  ContextTargetingExpirationUpdates,
  ExpiringContextTargetsByContextKindAndKey,
} from 'reducers/expiringContextTargets';
import { flagExperimentSummarySelector } from 'reducers/flagExperiments';
import { flagManagerSelector } from 'reducers/flagManager';
import {
  FlagAndEnvKeys,
  flagListRequestSelector,
  flagRequestByKeySelector,
  flagsSelector,
  flagStatusesByProjectKeySelector,
} from 'reducers/flags';
import {
  envFlagSettingsFormSelector,
  projectFlagMaintainerFormSelector,
  projectFlagSettingsFormSelector,
} from 'reducers/flagSettings';
import { pendingFlagChangesInstructionsSelector } from 'reducers/pendingChanges';
import { currentEnvironmentSelector, currentProjectSelector } from 'reducers/projects';
import { ruleExclusionFormSelector } from 'reducers/ruleExclusion';
import { reportAccesses } from 'sources/AccountAPI';
// eslint-disable-next-line import/no-namespace
import * as FlagAPI from 'sources/FlagAPI';
import { accessResponseDeniesUpdateTags, DeniedAccess, makeResourceSpec } from 'utils/accessUtils';
import { EventLocation } from 'utils/analyticsUtils';
import { Clause, createClause } from 'utils/clauseUtils';
import { USER_CONTEXT_KIND } from 'utils/constants';
import { getExpiringTargetKeys, getPendingExpiringTargetKeys } from 'utils/expiringContextTargetsUtils';
import { createFlagFilters, FlagFilters } from 'utils/flagFilterUtils';
import { FlagStatus } from 'utils/flagStatusUtils';
import {
  clearPercentageRollout,
  createFallthrough,
  createRule,
  createRuleMeasuredRolloutInstruction,
  EnvironmentFlagSettings,
  EnvironmentFlagSettingsProps,
  Experiment,
  ExperimentResults,
  ExperimentRollout,
  Fallthrough,
  Flag,
  FlagValue,
  getControlAndTestVariations,
  idOrKey,
  MeasuredRolloutConfig,
  Prerequisite,
  removePendingGuardedRollout,
  Rule,
  RuleExclusionSettings,
  RuleExclusionSettingsProps,
  setDefaultVariationForRollout,
  trackFlagTargetingEvent,
  Variation,
  VariationProps,
  WeightedVariation,
} from 'utils/flagUtils';
import { FieldPath } from 'utils/formUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import {
  makeAddClausesInstruction,
  makeAddValuesToClauseInstruction,
  makeRemoveClausesInstruction,
  makeRemoveValuesFromClauseInstruction,
  makeUpdateClauseInstruction,
} from 'utils/instructions/clauses/helpers';
import {
  findAllGuardedRolloutInstructions,
  makeOffVariationInstruction,
  makeStopMeasuredRolloutOnFallthroughInstruction,
  makeToggleFlagInstruction,
  makeTurnFlagOnInstruction,
  makeUpdateFallthroughRolloutInstruction,
  makeUpdateFallthroughVariationInstruction,
  makeUpdateFallthroughWithMeasuredRolloutV2Instruction,
} from 'utils/instructions/onOff/helpers';
import { OnOffInstructionKind } from 'utils/instructions/onOff/types';
import {
  makeRemovePrerequisiteInstruction,
  makeUpdatedPrerequisiteInstructionsArray,
} from 'utils/instructions/prerequisites/helpers';
import {
  convertRuleToAddRuleSemanticInstruction,
  makeAddRuleInstruction,
  makeRemoveRuleInstruction,
  makeReorderRulesInstruction,
  makeStopMeasuredRolloutOnRuleInstruction,
  makeUpdateRuleDescription,
  makeUpdateRuleRolloutInstruction,
  makeUpdateRuleVariationInstruction,
  makeUpdateRuleWithMeasuredRolloutV2Instruction,
} from 'utils/instructions/rules/helpers';
import {
  convertRolloutToSemanticInstructionRollout,
  DiscardableCustomRuleInstruction,
  ReorderRulesSemanticInstruction,
  RuleInstructionKind,
} from 'utils/instructions/rules/types';
import { getInstructionsByKind, getPendingInstructionsList } from 'utils/instructions/shared/helpers';
import { InstructionsType, SemanticInstruction } from 'utils/instructions/shared/types';
import { makeAddTargetsInstruction, makeRemoveTargetsInstruction } from 'utils/instructions/targets/helpers';
import { VariationSemanticInstruction } from 'utils/instructions/variations/types';
import Logger from 'utils/logUtils';
import { GenerateActionType } from 'utils/reduxUtils';
import { Request } from 'utils/requestUtils';
import { dotSeparateKey } from 'utils/stringUtils';

import { deleteContextTargetingExpiration, undoContextTargetingExpiration } from './expiringContextTargets';
import { deleteAllTriggersAction } from './integrations';

const logger = Logger.get('FlagActions');
const asArray = <T>(field: T | T[]) => (isArray(field) ? field : [field]);

const requestFlagStatus = (projectKey: string, flagKey: string, environmentKey: string, parentLink: string) =>
  ({
    type: 'flags/REQUEST_FLAG_STATUS',
    projectKey,
    flagKey,
    environmentKey,
    parentLink,
  }) as const;

const requestFlagStatusDone = (
  projectKey: string,
  flagKey: string,
  environmentKey: string,
  parentLink: string,
  status?: FlagStatus,
) =>
  ({
    type: 'flags/RECEIVE_FLAG_STATUS',
    projectKey,
    flagKey,
    environmentKey,
    parentLink,
    status,
  }) as const;

const requestFlagStatusFailed = (
  projectKey: string,
  flagKey: string,
  environmentKey: string,
  parentLink: string,
  error: ImmutableServerError,
) =>
  ({
    type: 'flags/REQUEST_FLAG_STATUS_FAILED',
    projectKey,
    flagKey,
    environmentKey,
    parentLink,
    error,
  }) as const;

function fetchFlagStatusByKey(projectKey: string, flagKey: string, environmentKey: string) {
  const parentLink = `/api/v2/flags/${projectKey}/${flagKey}`;
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestFlagStatus(projectKey, flagKey, environmentKey, parentLink));
    return new Promise((resolve, reject) => {
      FlagAPI.getFlagStatusesByEnvKeys(projectKey, flagKey, [environmentKey])
        .then((statuses) => {
          dispatch(
            requestFlagStatusDone(projectKey, flagKey, environmentKey, parentLink, statuses.get(environmentKey)),
          );

          resolve(statuses);
        })
        .catch((error) => {
          dispatch(requestFlagStatusFailed(projectKey, flagKey, environmentKey, parentLink, error));
          reject(error);
        });
    });
  };
}

function shouldFetchFlagStatus(state: GlobalState, projectKey: string, flagKey: string, environmentKey: string) {
  if (!isFlagStatusEnabled()) {
    return false;
  }

  const statuses = flagStatusesByProjectKeySelector(state, { projectKey });

  if (!statuses) {
    return true;
  }

  const status = statuses.getIn([environmentKey, flagKey]);

  return status ? !status.get('lastFetched') && !status.get('isFetching') : true;
}

function fetchFlagStatusByKeyIfNeeded(projectKey: string, flagKey: string, environmentKey: string) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchFlagStatus(getState(), projectKey, flagKey, environmentKey)) {
      return dispatch(fetchFlagStatusByKey(projectKey, flagKey, environmentKey));
    } else {
      return Promise.resolve();
    }
  };
}

const requestFlags = (
  filters: FlagFilters | undefined,
  projectKey: string,
  environmentKeys: string[],
  controller?: AbortController,
) =>
  ({
    type: 'flags/REQUEST_FLAGS',
    filters,
    projectKey,
    environmentKeys,
    controller,
  }) as const;

const requestFlagsDone = (
  response: OrderedMap<string, Flag>,
  filters: FlagFilters | undefined,
  currentFlagKey: string,
  isSummaryRep: boolean,
  projectKey: string,
  environmentKeys: string[],
  options: FlagAPI.FetchFlagsOptions,
) =>
  ({
    type: 'flags/RECEIVE_FLAGS',
    response,
    filters,
    currentFlagKey,
    isSummaryRep,
    projectKey,
    environmentKeys,
    options,
  }) as const;

const requestFlagsFailed = (
  projectKey: string,
  environmentKeys: string[],
  error: ImmutableServerError,
  filters: FlagFilters | undefined,
) =>
  ({
    type: 'flags/REQUEST_FLAGS_FAILED',
    projectKey,
    environmentKeys,
    error,
    filters,
  }) as const;

const requestFlagsAborted = (
  projectKey: string,
  environmentKeys: string[],
  signal: AbortSignal,
  filters: FlagFilters | undefined,
) =>
  ({
    type: 'flags/REQUEST_FLAGS_ABORTED',
    projectKey,
    environmentKeys,
    signal,
    filters,
  }) as const;

function fetchFlags(
  projectKey: string,
  environmentKeys: string[] = [],
  filters?: FlagFilters,
  options: FlagAPI.FetchFlagsOptions = {},
  requestOptions: FlagAPI.FetchFlagsRequestOptions = {},
) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (options.refetch) {
      const state = getState();
      const { isFetching, controller } = flagsSelector(state, projectKey).listRequest.toJS() as Request;
      if (isFetching) {
        try {
          controller && controller.abort('refetching flags');
        } catch (e) {
          // ignore
        }
      }
    }
    dispatch(requestFlags(filters, projectKey, environmentKeys, requestOptions.controller));
    return new Promise((resolve, reject) => {
      FlagAPI.getFlags(projectKey, environmentKeys, filters, options)
        .then((response) => {
          const state = getState();
          const currentFlagKey = flagManagerSelector(state).getIn(['original', 'key']) as string;
          dispatch(requestFlagsDone(response, filters, currentFlagKey, true, projectKey, environmentKeys, options));
          resolve(response);
        })
        .catch((error) => {
          const { signal } = requestOptions;

          if (signal && signal.aborted) {
            // Anything more useful can do here?
            dispatch(requestFlagsAborted(projectKey, environmentKeys, error, filters));
            return;
          }
          dispatch(requestFlagsFailed(projectKey, environmentKeys, error, filters));
          reject(error);
        });
    });
  };
}

function shouldFetchFlags(state: GlobalState, options: { refetch?: boolean } = {}) {
  const req = flagListRequestSelector(state);
  return !req || (!req.lastFetched && !req.isFetching) || options.refetch;
}

function fetchFlagsIfNeeded(
  projectKey: string,
  environmentKeys: string[] = [],
  filters?: FlagFilters,
  options: FlagAPI.FetchFlagsOptions = {},
  requestOptions: FlagAPI.FetchFlagsRequestOptions = {},
) {
  const queryClient = getQueryClient();
  const isFetching = queryClient.isFetching({ queryKey: flagsList({ projectKey }).queryKey });
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchFlags(getState(), options)) {
      if (isFetching) {
        await queryClient.cancelQueries(flagsList({ projectKey }));
      }
      return dispatch(fetchFlags(projectKey, environmentKeys, filters, options, requestOptions));
    } else {
      return Promise.resolve();
    }
  };
}

export function fetchFlagsForSegment(
  projectKey: string,
  environmentKey: string,
  segmentKey: string,
  filters?: FlagFilters,
  options: FlagAPI.FetchFlagsOptions = {},
  requestOptions: FlagAPI.FetchFlagsRequestOptions = {},
) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchFlags(getState(), options)) {
      return dispatch(
        fetchFlags(
          projectKey,
          [environmentKey],
          filters
            ? filters.merge({
                segmentTargeted: segmentKey,
                filterEnv: environmentKey,
              })
            : createFlagFilters({
                segmentTargeted: segmentKey,
                filterEnv: environmentKey,
              }),
          options,
          requestOptions,
        ),
      );
    } else {
      return Promise.resolve();
    }
  };
}

const requestFlagStatuses = (projectKey: string, environmentKey: string) =>
  ({
    type: 'flags/REQUEST_FLAG_STATUSES',
    projectKey,
    environmentKey,
  }) as const;

const requestFlagStatusesDone = (projectKey: string, environmentKey: string, response: Map<string, FlagStatus>) =>
  ({
    type: 'flags/RECEIVE_FLAG_STATUSES',
    projectKey,
    environmentKey,
    response,
  }) as const;

const requestFlagStatusesFailed = (projectKey: string, environmentKey: string, error: ImmutableServerError) =>
  ({
    type: 'flags/REQUEST_FLAG_STATUSES_FAILED',
    projectKey,
    environmentKey,
    error,
  }) as const;

function fetchFlagStatusesForEnvironment(projectKey: string, environmentKey: string) {
  return async (dispatch: GlobalDispatch) => {
    if (!isFlagStatusEnabled()) {
      return;
    }

    dispatch(requestFlagStatuses(projectKey, environmentKey));
    return FlagAPI.getAllFlagStatusesForEnvironment(projectKey, environmentKey)
      .then((response) => dispatch(requestFlagStatusesDone(projectKey, environmentKey, response)))
      .catch((error) => dispatch(requestFlagStatusesFailed(projectKey, environmentKey, error)));
  };
}

const requestFlagStatusesByEnvKeysAndFlagKeys = (projectKey: string, environmentKeys: string[], flagKeys: string[]) =>
  ({
    type: 'flags/REQUEST_FLAG_STATUSES_BY_ENVS_AND_FLAGS',
    projectKey,
    environmentKeys,
    flagKeys,
  }) as const;

const requestFlagStatusesByEnvKeysAndFlagKeysDone = (
  projectKey: string,
  environmentKeys: string[],
  flagKeys: string[],
  response: Map<string, FlagStatus>,
) =>
  ({
    type: 'flags/RECEIVE_FLAG_STATUSES_BY_ENVS_AND_FLAGS',
    projectKey,
    environmentKeys,
    flagKeys,
    response,
  }) as const;

const requestFlagStatusesByEnvKeysAndFlagKeysFailed = (
  projectKey: string,
  environmentKeys: string[],
  flagKeys: string[],
  error: ImmutableServerError,
) =>
  ({
    type: 'flags/REQUEST_FLAG_STATUSES_BY_ENVS_AND_FLAGS_FAILED',
    projectKey,
    environmentKeys,
    flagKeys,
    error,
  }) as const;

const requestFlag = (flagKey: string, projectKey: string, environmentKey: string) =>
  ({
    type: 'flags/REQUEST_FLAG',
    flagKey,
    projectKey,
    environmentKey,
  }) as const;

export const requestFlagDone = (flag: Flag, flagKey: string, projectKey: string, environmentKey: string) =>
  ({
    type: 'flags/RECEIVE_FLAG',
    flag,
    flagKey,
    projectKey,
    environmentKey,
  }) as const;

export const requestFlagFailed = (
  flagKey: string,
  error: ImmutableServerError,
  projectKey: string,
  environmentKey: string,
) =>
  ({
    type: 'flags/REQUEST_FLAG_FAILED',
    flagKey,
    error,
    projectKey,
    environmentKey,
  }) as const;

function fetchFlag(
  projectKey: string,
  flagKey: string,
  environmentKey: string,
  options: FlagAPI.FetchFlagByKeyOptions & { skipRequestDispatch?: boolean } = {},
) {
  return async (dispatch: GlobalDispatch) => {
    // set `skipRequestDispatch` to true if you want to update a flag without putting it in the "fetching" state
    if (!options.skipRequestDispatch) {
      dispatch(requestFlag(flagKey, projectKey, environmentKey));
    }
    return new Promise<Flag>((resolve, reject) => {
      const fetchOptions = { ...options };
      delete fetchOptions.skipRequestDispatch;
      FlagAPI.getFlagByKey(projectKey, flagKey, environmentKey ? [environmentKey] : [], fetchOptions)
        .then((flag) => {
          dispatch(requestFlagDone(flag, flagKey, projectKey, environmentKey));
          resolve(flag);
        })
        .catch((error) => {
          dispatch(requestFlagFailed(flagKey, error, projectKey, environmentKey));
          reject(error);
        });
    });
  };
}

function shouldFetchFlag(state: GlobalState, projectKey: string, flagKey: string) {
  const req = flagRequestByKeySelector(state, { flagKey });
  return req ? (!req.lastFetched || req.isSummaryRep) && !req.isFetching : true;
}

function fetchFlagIfNeeded(
  projectKey: string,
  flagKey: string,
  environmentKey: string,
  options?: FlagAPI.FetchFlagByKeyOptions,
) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchFlag(getState(), projectKey, flagKey)) {
      return dispatch(fetchFlag(projectKey, flagKey, environmentKey, options));
    } else {
      return Promise.resolve();
    }
  };
}

function fetchFlagWithStatus(
  projectKey: string,
  flagKey: string,
  environmentKey: string,
  options?: FlagAPI.FetchFlagByKeyOptions,
) {
  return async (dispatch: GlobalDispatch) =>
    dispatch(fetchFlagIfNeeded(projectKey, flagKey, environmentKey, options)).then(() => {
      defer(async () => dispatch(fetchFlagStatusByKeyIfNeeded(projectKey, flagKey, environmentKey)));
    });
}

function fetchFlagByKeyForCurrentProject(
  projectKey: string,
  flagKey: string,
  environmentKey: string,
  options?: FlagAPI.FetchFlagByKeyOptions,
) {
  return async (dispatch: GlobalDispatch) =>
    new Promise((resolve, reject) => {
      const action = isFlagStatusEnabled()
        ? fetchFlagWithStatus(projectKey, flagKey, environmentKey, options)
        : fetchFlagIfNeeded(projectKey, flagKey, environmentKey, options);
      dispatch(action).then(resolve, reject);
    });
}

const requestDeleteFlagDone = (flag: Flag, projectKey: string) =>
  ({
    type: 'flags/DELETE_FLAG_DONE',
    flag,
    projectKey,
  }) as const;

export function useDeleteFlag() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (
    projectKey: string,
    flag: Flag,
    options: {
      pathOnSuccess?: To;
    } = {},
  ) => {
    await FlagAPI.deleteFlag(flag);
    // Invalidate the flags list since we've deleted a flag
    // associated story: https://launchdarkly.atlassian.net/jira/software/c/projects/REL/boards/204?selectedIssue=REL-3048
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: flagsList({ projectKey }).queryKey });
    if (options.pathOnSuccess) {
      // adding a replace because page breaks when users go back to a flag they just deleted using the browser back button
      await navigate(options.pathOnSuccess, {
        replace: true,
      });
    }
    dispatch(requestDeleteFlagDone(flag, projectKey));
    if (shouldCleanupAfterFlagDeletion()) {
      dispatch({ type: flagLinksActionTypes.DELETE_ALL_FLAG_LINKS, flag, flagKey: flag.key });
      dispatch(deleteAllTriggersAction());
    }
  };
}

export type UpdateFlagOptions = {
  envKey?: string;
  environmentKey?: string;
  pathOnSuccess?: To;
  pathOnError?: string;
  navigate?: NavigateFunction;
  fullPageReload?: boolean;
  shouldRefetchScheduledFlagChanges?: boolean;
  eventLocation?: EventLocation;
  comment?: string;
  patchOperations?: JSONPatch | { comment: string; patch: JSONPatch };
  removeEmptyFields?: boolean;
  variationSemanticInstructions?: List<VariationSemanticInstruction>;
  releaseStrategy?: ReleaseStrategy;
  progressiveRollout?: { originalFlag: Flag; draftFlag: Flag; pendingProgressiveRollout: PendingProgressiveRollout };
};

type UpdateRequest<T extends string> = (
  oldFlag: Flag,
  newFlag: Flag,
) => Readonly<{
  type: T;
  oldFlag: Flag;
  newFlag: Flag;
}>;

type UpdateRequestDone<T extends string> = (
  flag: Flag,
  projectKey: string,
  options: UpdateFlagOptions,
) => Readonly<{
  type: T;
  flag: Flag;
  projectKey: string;
  options: UpdateFlagOptions;
}>;

type UpdateRequestFailed<T extends string> = (
  flag: Flag,
  error: ImmutableServerError,
) => Readonly<{
  type: T;
  flag: Flag;
  error: ImmutableServerError;
}>;

export const updateFlagBase =
  <T extends string>(updateActionCreators: [UpdateRequest<T>, UpdateRequestDone<T>, UpdateRequestFailed<T>]) =>
  (projectKey: string, environmentKey: string, oldFlag: Flag, newFlag: Flag, options: UpdateFlagOptions = {}) => {
    const [requestActionCreator, successActionCreator, failureActionCreator] = updateActionCreators;
    return async (dispatch: GlobalDispatch, getState: GetState): Promise<Flag> => {
      dispatch(requestActionCreator(oldFlag, newFlag));

      const { variationSemanticInstructions, releaseStrategy, progressiveRollout } = options;
      const hasVariationSemanticInstructions = Boolean(variationSemanticInstructions?.size);
      // pull pending semantic patch instructions out of flag store
      const ins = pendingFlagChangesInstructionsSelector(getState());
      // updateFlag is called from either Targeting or Variations with its own respective instructions
      const pendingInstructions =
        variationSemanticInstructions ??
        getPendingInstructionsList(ins, { releaseStrategy, environmentKey, flag: oldFlag, progressiveRollout });
      // If the patch is marked invalid, or contains no instructions, we want to do a regular JSON patch instead.
      const isValidSemanticPatch = pendingInstructions.size > 0 || hasVariationSemanticInstructions;

      const queryClient = getQueryClient();

      return new Promise<Flag>((resolve, reject) => {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        // If we have a semantic patch to send and the correct flag is turned on, send only the semantic patch.
        //const promise = FlagAPI.updateFlag(oldFlag, newFlag, optionsWithSemanticPatch);
        const promise = isValidSemanticPatch
          ? FlagAPI.updateFlagSemantically(
              projectKey,
              newFlag,
              // we must have one or the other env-key property
              (options.envKey || options.environmentKey)!,
              pendingInstructions,
              options,
            )
          : FlagAPI.updateFlag(
              projectKey,
              oldFlag,
              newFlag,
              options,
            ); /* eslint-enable @typescript-eslint/no-non-null-assertion */

        promise
          .then(async (flag) => {
            options.shouldRefetchScheduledFlagChanges &&
              (await dispatch(
                fetchScheduledChangesForFlag({
                  flagKey: newFlag.key,
                  projKey: projectKey,
                  envKey: environmentKey,
                }),
              ));
            if (options.pathOnSuccess) {
              if (options.fullPageReload) {
                window.location.href =
                  typeof options.pathOnSuccess === 'object'
                    ? `${options.pathOnSuccess.pathname}?${options.pathOnSuccess.search}`
                    : options.pathOnSuccess;
                return;
              }
              await options.navigate?.(options.pathOnSuccess);
            }

            dispatch(successActionCreator(flag, projectKey, options));

            if (isFlagStatusEnabled()) {
              defer(async () => {
                try {
                  await dispatch(fetchFlagStatusByKeyIfNeeded(projectKey, flag.key, environmentKey));
                } catch (error) {
                  // noop
                }
              });
            }

            const hasMeasuredRolloutInstructions = pendingInstructions.some(
              (instruction) =>
                instruction.kind === OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT ||
                instruction.kind === OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT_V2 ||
                instruction.kind === RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT ||
                instruction.kind === RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT_V2 ||
                instruction.kind === OnOffInstructionKind.STOP_MEASURED_ROLLOUT_ON_FALLTHROUGH ||
                instruction.kind === RuleInstructionKind.STOP_MEASURED_ROLLOUT_ON_RULE,
            );

            if (hasMeasuredRolloutInstructions) {
              await dispatch(fetchFlag(projectKey, flag.key, environmentKey));
              await queryClient.invalidateQueries(flagsDetail({ projectKey, flagKey: flag.key }));
              await queryClient.invalidateQueries(experimentsList({ projectKey, environmentKey }));
              await queryClient.invalidateQueries({
                queryKey: getMeasuredRolloutsQuery.partialQueryKey({
                  projectKey,
                  flagKey: flag.key,
                  params: { filter: { environmentKey } },
                }),
              });
            }

            resolve(flag);
          })
          .catch(async (error) => {
            if (options.pathOnError && options.navigate) {
              await options.navigate(options.pathOnError);
            }
            dispatch(failureActionCreator(newFlag, error));
            reject(error);
          });
      });
    };
  };

export const createUpdateFlagRequestActionCreator =
  <T extends string>(type: T) =>
  (oldFlag: Flag, newFlag: Flag) =>
    ({ type, oldFlag, newFlag }) as const;

export const createUpdateFlagRequestDoneActionCreator =
  <T extends string>(type: T) =>
  (flag: Flag, projectKey: string, options: UpdateFlagOptions = {}) =>
    ({
      type,
      flag,
      projectKey,
      options,
    }) as const;

export const createUpdateFlagRequestFailedActionCreator =
  <T extends string>(type: T) =>
  (flag: Flag, error: ImmutableServerError) =>
    ({
      type,
      flag,
      error,
    }) as const;

export const updateFlagRequest = createUpdateFlagRequestActionCreator('flags/UPDATE_FLAG');

export const updateFlagRequestDone = createUpdateFlagRequestDoneActionCreator('flags/UPDATE_FLAG_DONE');

const updateFlagRequestFailed = createUpdateFlagRequestFailedActionCreator('flags/UPDATE_FLAG_FAILED');

const updateFlag = updateFlagBase([updateFlagRequest, updateFlagRequestDone, updateFlagRequestFailed]);

const updateFlagGoalsRequest = createUpdateFlagRequestActionCreator('flags/UPDATE_FLAG_GOALS');

const updateFlagGoalsRequestDone = createUpdateFlagRequestDoneActionCreator('flags/UPDATE_FLAG_GOALS_DONE');

const updateFlagGoalsRequestFailed = createUpdateFlagRequestFailedActionCreator('flags/UPDATE_FLAG_GOALS_FAILED');

const updateFlagGoals = updateFlagBase([
  updateFlagGoalsRequest,
  updateFlagGoalsRequestDone,
  updateFlagGoalsRequestFailed,
]);

const updateFlagMaintainerRequest = createUpdateFlagRequestActionCreator('flags/UPDATE_FLAG_MAINTAINER');
const updateFlagMaintainerRequestDone = createUpdateFlagRequestDoneActionCreator('flags/UPDATE_FLAG_MAINTAINER_DONE');
const updateFlagMaintainerRequestFailed = createUpdateFlagRequestFailedActionCreator(
  'flags/UPDATE_FLAG_MAINTAINER_FAILED',
);
const updateFlagMaintainer = updateFlagBase([
  updateFlagMaintainerRequest,
  updateFlagMaintainerRequestDone,
  updateFlagMaintainerRequestFailed,
]);

const startExperimentRequest = createUpdateFlagRequestActionCreator('flags/START_EXPERIMENT');

const startExperimentRequestDone = createUpdateFlagRequestDoneActionCreator('flags/START_EXPERIMENT_DONE');

const startExperimentRequestFailed = createUpdateFlagRequestFailedActionCreator('flags/START_EXPERIMENT_FAILED');

const startFlagExperimentBase = updateFlagBase([
  startExperimentRequest,
  startExperimentRequestDone,
  startExperimentRequestFailed,
]);

const startFlagExperiment = (
  projectKey: string,
  flag: Flag,
  metricKey: string,
  environmentKey: string,
  options: UpdateFlagOptions & { eventLocation?: EventLocation },
) =>
  startFlagExperimentBase(projectKey, environmentKey, flag, flag.startExperiment(metricKey, environmentKey), options);

const stopExperimentRequest = createUpdateFlagRequestActionCreator('flags/STOP_EXPERIMENT');

const stopExperimentRequestDone = createUpdateFlagRequestDoneActionCreator('flags/STOP_EXPERIMENT_DONE');

const stopExperimentRequestFailed = createUpdateFlagRequestFailedActionCreator('flags/STOP_EXPERIMENT_FAILED');

const stopFlagExperimentBase = updateFlagBase([
  stopExperimentRequest,
  stopExperimentRequestDone,
  stopExperimentRequestFailed,
]);

const stopFlagExperiment = (
  projectKey: string,
  flag: Flag,
  metricKey: string,
  environmentKey: string,
  options: UpdateFlagOptions & { eventLocation?: EventLocation },
) => stopFlagExperimentBase(projectKey, environmentKey, flag, flag.stopExperiment(metricKey, environmentKey), options);

const createExperimentIntervalFailed = () =>
  ({
    type: 'flags/CREATE_EXPERIMENT_INTERVAL_FAILED',
  }) as const;

const createExperimentIntervalFailedGuidance = () =>
  ({
    type: 'flags/CREATE_EXPERIMENT_INTERVAL_FAILED_GUIDANCE',
  }) as const;

const createExperimentIntervalDone = (flag: Flag, options: { eventLocation?: EventLocation }, projectKey: string) =>
  ({
    type: 'flags/CREATE_EXPERIMENT_INTERVAL_DONE',
    flag,
    options,
    projectKey,
  }) as const;

const createFlagExperimentInterval =
  (
    projectKey: string,
    flag: Flag,
    metricKey: string,
    environmentKey: string,
    options: { eventLocation?: EventLocation },
  ) =>
  async (dispatch: GlobalDispatch) => {
    let stoppedFlagResponse: Flag;
    try {
      stoppedFlagResponse = await FlagAPI.updateFlag(
        projectKey,
        flag,
        flag.stopExperiment(metricKey, environmentKey),
        options,
      );
    } catch (error) {
      dispatch(createExperimentIntervalFailed());
      return;
    }

    let startedFlagResponse: Flag;
    try {
      startedFlagResponse = await FlagAPI.updateFlag(
        projectKey,
        stoppedFlagResponse,
        stoppedFlagResponse.startExperiment(metricKey, environmentKey),
        options,
      );
    } catch (error) {
      dispatch(createExperimentIntervalFailedGuidance());
      return;
    }

    dispatch(createExperimentIntervalDone(startedFlagResponse, options, projectKey));
  };

const updateProjectFlagSettingsRequest = createUpdateFlagRequestActionCreator('flags/UPDATE_PROJECT_FLAG_SETTINGS');

const updateProjectFlagSettingsRequestDone = createUpdateFlagRequestDoneActionCreator(
  'flags/UPDATE_PROJECT_FLAG_SETTINGS_DONE',
);

const updateProjectFlagSettingsRequestFailed = createUpdateFlagRequestFailedActionCreator(
  'flags/UPDATE_PROJECT_FLAG_SETTINGS_FAILED',
);

const updateProjectFlagSettings = updateFlagBase([
  updateProjectFlagSettingsRequest,
  updateProjectFlagSettingsRequestDone,
  updateProjectFlagSettingsRequestFailed,
]);

const updateEnvironmentFlagSettingsRequest = createUpdateFlagRequestActionCreator('flags/UPDATE_ENV_FLAG_SETTINGS');

const updateEnvironmentFlagSettingsRequestDone = createUpdateFlagRequestDoneActionCreator(
  'flags/UPDATE_ENV_FLAG_SETTINGS_DONE',
);

const updateEnvironmentFlagSettingsRequestFailed = createUpdateFlagRequestFailedActionCreator(
  'flags/UPDATE_ENV_FLAG_SETTINGS_FAILED',
);

const updateEnvFlagSettings = updateFlagBase([
  updateEnvironmentFlagSettingsRequest,
  updateEnvironmentFlagSettingsRequestDone,
  updateEnvironmentFlagSettingsRequestFailed,
]);

const updateRuleExclusionRequest = createUpdateFlagRequestActionCreator('flags/UPDATE_RULE_EXCLUSION');

const updateRuleExclusionRequestDone = createUpdateFlagRequestDoneActionCreator('flags/UPDATE_RULE_EXCLUSION_DONE');

const updateRuleExclusionRequestFailed = createUpdateFlagRequestFailedActionCreator(
  'flags/UPDATE_RULE_EXCLUSION_FAILED',
);

const updateRuleExclusion = updateFlagBase([
  updateRuleExclusionRequest,
  updateRuleExclusionRequestDone,
  updateRuleExclusionRequestFailed,
]);

// internal api, does not get exported. Consumers should use toggleFlagSafely.
function toggleFlag(
  projectKey: string,
  flag: Flag,
  environmentKey: string,
  ins: List<SemanticInstruction>,
  comment: string,
) {
  return async (dispatch: GlobalDispatch) =>
    new Promise((resolve, reject) => {
      // FlagList does not have a modified copy so we just use the passed flag.
      dispatch(updateFlagSemantically(projectKey, flag, environmentKey, ins, { comment })).then(resolve, reject);
    });
}

const toggleFlagSafely =
  (
    projectKey: string,
    environmentKey: string,
    flag: Flag,
    instructions: List<SemanticInstruction>,
    comment: string = '',
  ) =>
  async (dispatch: GlobalDispatch) =>
    dispatch(toggleFlag(projectKey, flag, environmentKey, instructions, comment)).catch(noop);

function addVariation(variation: Variation) {
  return {
    type: 'flags/ADD_VARIATION',
    variation,
  } as const;
}

function removeVariation(variation: Variation) {
  return {
    type: 'flags/REMOVE_VARIATION',
    variation,
  } as const;
}

function editVariation(
  variation: Variation,
  field: keyof VariationProps,
  value: VariationProps[keyof VariationProps],
  options = {},
) {
  return {
    type: 'flags/EDIT_VARIATION',
    variation: variation.set(field, value),
    field,
    options,
  } as const;
}

function changeFlagProject(selectedProject: string) {
  return {
    type: 'flags/CHANGE_FLAG_PROJECT',
    selectedProject,
  } as const;
}

function changeFlagSelection(selectedFlag: Flag) {
  return {
    type: 'flags/CHANGE_FLAG_SELECTION',
    flag: selectedFlag,
  } as const;
}

const editFlag = (field: FieldPath, value: FlagValue, options = {}) =>
  ({
    type: 'flags/EDIT_FLAG',
    field,
    value,
    options,
  }) as const;

function selectOffVariation(flag: Flag, environmentKey: string, variation: Variation) {
  return {
    type: 'flags/CHANGE_FLAG_CONFIGURATION',
    instructions: [makeOffVariationInstruction(variation._id)] as SemanticInstruction[],
    flag: flag.setOffVariation(environmentKey, variation),
    environmentKey,
  } as const;
}

function clearOffVariation(flag: Flag, environmentKey: string) {
  return {
    type: 'flags/CHANGE_FLAG_CONFIGURATION',
    instructions: [makeOffVariationInstruction(null)] as SemanticInstruction[],
    flag: flag.clearOffVariation(environmentKey),
    environmentKey,
  } as const;
}

function resetConfiguration() {
  return {
    type: 'flags/RESET_FLAG_CONFIGURATION',
  } as const;
}

function initializeConfigurationManager(flag: Flag) {
  return {
    type: 'flags/INITIALIZE_FLAG_CONFIG_MANAGER',
    flag,
  } as const;
}

function destroyConfigurationManager() {
  return {
    type: 'flags/DESTROY_FLAG_CONFIG_MANAGER',
  } as const;
}

const editProjectFlagMaintainerAction = (flag: Flag) =>
  ({
    type: 'flags/EDIT_PROJECT_FLAG_MAINTAINER',
    flag,
  }) as const;

const initializeProjectFlagMaintainer = (flag: Flag) =>
  ({
    type: 'flags/INITIALIZE_PROJECT_FLAG_MAINTAINER',
    flag,
  }) as const;

const editProjectFlagSettingsAction = (field: FieldPath, flag: Flag) =>
  ({
    type: 'flags/EDIT_PROJECT_FLAG_SETTINGS',
    field,
    flag,
  }) as const;

const checkAccessResource = (willRemoveEditingAbility: boolean) =>
  ({
    type: 'flags/CHECK_ACCESS_RESOURCE',
    willRemoveEditingAbility,
  }) as const;

function initializeProjectFlagMaintainerFormAction(flag: Flag) {
  return (dispatch: GlobalDispatch) => dispatch(initializeProjectFlagMaintainer(flag));
}

function editProjectFlagMaintainer(newMaintainerID: string, kind: 'member' | 'team' = 'member') {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const form = projectFlagMaintainerFormSelector(getState());
    const validatedField = asArray(kind === 'member' ? 'maintainerId' : 'maintainerTeamKey');
    let modified = form.modified.setIn(validatedField, newMaintainerID);
    modified = modified.setIn(asArray(kind === 'member' ? 'maintainerTeamKey' : 'maintainerId'), undefined);
    return dispatch(editProjectFlagMaintainerAction(modified));
  };
}

function editProjectFlagSettings(
  projectKey: string,
  environmentKey: string,
  field: FieldPath,
  // This is tricky: value can be any valid property type on flag, including on nested properties (since field can point to nested properties).
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  options: { flagKey?: string; oldFlag?: Flag; isAccessWarningEnabled?: boolean; isNewCustomProperty?: boolean } = {},
) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const form = projectFlagSettingsFormSelector(getState());
    const validatedField = asArray(field);
    let modified = form.modified.setIn(validatedField, value);
    // Autofill dot-seperated key for custom properties
    if (
      options.isNewCustomProperty &&
      validatedField.length === 3 &&
      validatedField[2] === 'name' &&
      !form.wasChanged(['customProperties', validatedField[1], 'key'])
    ) {
      modified = modified.setIn(['customProperties', validatedField[1], 'key'], dotSeparateKey(value));
    }

    dispatch(editProjectFlagSettingsAction(field, modified));

    if (options.isAccessWarningEnabled && field === 'tags') {
      const { flagKey, oldFlag } = options;
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      const newResourceSpec = makeResourceSpec(
        projectKey,
        '*', // project settings affect all environments
        'flag',
        flagKey!,
        value,
      ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      const oldAccessDenied =
        oldFlag!.getConfiguration(environmentKey)?._access?.get('denied') ||
        List<DeniedAccess>(); /* eslint-enable @typescript-eslint/no-non-null-assertion */

      reportAccesses(newResourceSpec).then((accesses) => {
        const willRemoveEditingAbility = accessResponseDeniesUpdateTags(accesses, newResourceSpec, oldAccessDenied);
        dispatch(checkAccessResource(willRemoveEditingAbility));
      }, noop);
    }
  };
}

const editEnvironmentFlagSettingsAction = (
  field: keyof EnvironmentFlagSettingsProps,
  envFlagSettings: EnvironmentFlagSettings,
) =>
  ({
    type: 'flags/EDIT_ENV_FLAG_SETTINGS',
    field,
    envFlagSettings,
  }) as const;

function editEnvFlagSetting(
  field: keyof EnvironmentFlagSettingsProps,
  value: EnvironmentFlagSettingsProps[keyof EnvironmentFlagSettingsProps],
) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const form = envFlagSettingsFormSelector(getState());
    let modified = form.modified.set(field, value);
    if (field === 'trackEvents' && !value) {
      modified = modified.withMutations((ctx) => {
        const clearRuleTrackEvents = form.modified.rules.map((rule) => rule.set('trackEvents', false));
        ctx.set('trackEvents', false).set('trackEventsFallthrough', false).set('rules', clearRuleTrackEvents);
      });
    }
    dispatch(editEnvironmentFlagSettingsAction(field, modified));
  };
}

const editRuleExclusionAction = (
  fieldNameOrRuleIndex: keyof RuleExclusionSettingsProps | number,
  ruleExclusionSettings: RuleExclusionSettings,
) =>
  ({
    type: 'flags/EDIT_RULE_EXCLUSION',
    fieldNameOrRuleIndex,
    ruleExclusionSettings,
  }) as const;

function editRuleExclusion(
  fieldNameOrRuleIndex: keyof RuleExclusionSettingsProps | number,
  value: RuleExclusionSettingsProps[keyof RuleExclusionSettingsProps],
  isCustomRule?: boolean,
) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const form = ruleExclusionFormSelector(getState());
    let modified: RuleExclusionSettings;
    if (isCustomRule) {
      modified = form.modified.setIn(['rules', fieldNameOrRuleIndex, 'trackEvents'], value);
    } else {
      modified = form.modified.set(fieldNameOrRuleIndex, value);
    }

    return dispatch(editRuleExclusionAction(fieldNameOrRuleIndex, modified));
  };
}

export const changeFlagConfiguration = (flag: Flag, instructions: SemanticInstruction[], environmentKey: string) =>
  ({
    type: 'flags/CHANGE_FLAG_CONFIGURATION',
    flag,
    instructions,
    environmentKey,
  }) as const;

function generateInstructionsForTargetChanges(
  flag: Flag,
  environmentKey: string,
  variation: Variation,
  values: List<string>,
  contextKind: string = 'user',
  existingInstructions: SemanticInstruction[] = [],
) {
  const variationIndex = flag.getVariations().findIndex((v) => v.get('_id') === variation.get('_id'));
  const targets =
    contextKind === 'user'
      ? flag.getTarget(environmentKey, variationIndex)
      : flag.getContextTarget(environmentKey, variationIndex, contextKind);
  let added: Set<string>;
  let removed: Set<string>;
  if (targets) {
    const existingValues = targets.get('values');
    added = Set(values).subtract(existingValues);
    removed = Set(existingValues).subtract(values);
  } else {
    added = values.toSet();
    removed = Set();
  }
  if (!added.isEmpty()) {
    trackFlagTargetingEvent('Individual Targets Added', { contextKind });
  }
  const instructions = existingInstructions;
  if (!added.isEmpty()) {
    instructions.push(makeAddTargetsInstruction(added.toArray(), variation.get('_id'), contextKind));
  }
  if (!removed.isEmpty()) {
    instructions.push(makeRemoveTargetsInstruction(removed.toArray(), variation.get('_id'), contextKind));
  }

  return instructions;
}

function setTarget(
  flag: Flag,
  environmentKey: string,
  variation: Variation,
  values: List<string>,
  contextKind: string = 'user',
) {
  return changeFlagConfiguration(
    contextKind === 'user'
      ? flag.setTarget(environmentKey, variation, values)
      : flag.setContextTarget(environmentKey, variation, values, contextKind),
    generateInstructionsForTargetChanges(flag, environmentKey, variation, values, contextKind),
    environmentKey,
  );
}

function clearAllTargets(flag: Flag, environmentKey: string, variation: Variation, contextsWithTargets: string[]) {
  const values = List();
  let instructions: SemanticInstruction[] = [];
  let modifiedFlag = flag;
  contextsWithTargets.forEach((contextKind) => {
    modifiedFlag =
      contextKind === 'user'
        ? modifiedFlag.setTarget(environmentKey, variation, values)
        : modifiedFlag.setContextTarget(environmentKey, variation, values, contextKind);
    instructions = generateInstructionsForTargetChanges(
      flag,
      environmentKey,
      variation,
      values,
      contextKind,
      instructions,
    );
  });

  return changeFlagConfiguration(modifiedFlag, instructions, environmentKey);
}

function setFallthroughVariation(originalFlag: Flag, draftFlag: Flag, environmentKey: string, variation: Variation) {
  let newFlag = draftFlag.setFallthroughVariation(environmentKey, variation);
  let newFallthrough = newFlag.getFallthrough(environmentKey);

  const { controlVariation, testVariation } = getControlAndTestVariations(
    originalFlag,
    newFlag,
    environmentKey,
    newFallthrough,
  );

  // Always clear the measured rollout config if the flag is on
  if (
    enableReleaseGuardianRefreshedUIDropdown() ||
    (newFallthrough.measuredRolloutConfig && controlVariation === testVariation)
  ) {
    newFlag = newFlag.setFallthroughMeasuredRolloutConfig(environmentKey, undefined);
    newFallthrough = newFlag.getFallthrough(environmentKey);
  }

  const testVariationId = testVariation?._id;
  const controlVariationId = controlVariation?._id;

  if (!newFallthrough.measuredRolloutConfig) {
    return changeFlagConfiguration(newFlag, [makeUpdateFallthroughVariationInstruction(variation._id)], environmentKey);
  }

  const instruction: SemanticInstruction = makeUpdateFallthroughWithMeasuredRolloutV2Instruction(
    nullthrows(testVariationId),
    nullthrows(controlVariationId),
    newFallthrough.measuredRolloutConfig,
  );

  return changeFlagConfiguration(newFlag, [instruction], environmentKey);
}

function setFallthroughExperimentRollout(
  flag: Flag,
  environmentKey: string,
  rolloutData: List<WeightedVariation>,
  experimentData: ExperimentRollout,
) {
  let newFlag = flag.setFallthroughExperimentRollout(environmentKey, rolloutData, experimentData);
  const newRollout = getFallthroughRolloutFromUpdatedFlag(newFlag, environmentKey);

  if (newFlag.getFallthrough(environmentKey).measuredRolloutConfig) {
    newFlag = newFlag.setFallthroughMeasuredRolloutConfig(environmentKey, undefined);
  }

  return changeFlagConfiguration(newFlag, [makeUpdateFallthroughRolloutInstruction(newRollout)], environmentKey);
}

function setFallthroughRollout(flag: Flag, environmentKey: string, variation: Variation, weight: number) {
  let newFlag = flag.setFallthroughRollout(environmentKey, variation, weight);
  const newRollout = getFallthroughRolloutFromUpdatedFlag(newFlag, environmentKey);

  if (enableReleaseGuardianRefreshedUIDropdown() && flag.getFallthrough(environmentKey).measuredRolloutConfig) {
    newFlag = newFlag.setFallthroughMeasuredRolloutConfig(environmentKey, undefined);
  }
  return changeFlagConfiguration(newFlag, [makeUpdateFallthroughRolloutInstruction(newRollout)], environmentKey);
}

function setFallthroughBucket(flag: Flag, environmentKey: string, bucketBy: string, contextKind?: string) {
  const newFlag = flag.setFallthroughBucket(
    environmentKey,
    bucketBy === 'key' && !contextKind ? undefined : bucketBy,
    contextKind,
  );
  const newRollout = getFallthroughRolloutFromUpdatedFlag(newFlag, environmentKey);

  return changeFlagConfiguration(newFlag, [makeUpdateFallthroughRolloutInstruction(newRollout)], environmentKey);
}

function setProgressiveRolloutConfig(
  originalFlag: Flag,
  draftFlag: Flag,
  environmentKey: string,
  rule: Rule | Fallthrough,
) {
  let instructions: List<SemanticInstruction> = List();
  let updatedDraftFlag = draftFlag;
  updatedDraftFlag = setDefaultVariationForRollout(originalFlag, updatedDraftFlag, environmentKey, rule);
  updatedDraftFlag = removePendingGuardedRollout(updatedDraftFlag, environmentKey);
  updatedDraftFlag = changeFlagConfiguration(updatedDraftFlag, instructions.toArray(), environmentKey).flag;

  if (!draftFlag.isOn(environmentKey)) {
    updatedDraftFlag = updatedDraftFlag.updateConfiguration(environmentKey, (draftFlagConfig) =>
      draftFlagConfig.set('on', true),
    );
    instructions = instructions.push(makeTurnFlagOnInstruction());
  }

  return changeFlagConfiguration(updatedDraftFlag, instructions.toArray(), environmentKey);
}

const stopMeasuredRolloutOnFallthrough = ({
  flag,
  environmentKey,
  finalVariationId,
  comment,
}: {
  flag: Flag;
  environmentKey: string;
  finalVariationId: string;
  comment: string;
}) => {
  const instruction = makeStopMeasuredRolloutOnFallthroughInstruction(finalVariationId, comment);
  return changeFlagConfiguration(flag, [instruction], environmentKey);
};

export function getFallthroughRolloutFromUpdatedFlag(flag: Flag, environmentKey: string) {
  const rollout = flag.getConfiguration(environmentKey)?.getIn(['fallthrough', 'rollout']);

  return convertRolloutToSemanticInstructionRollout(flag.getVariations(), rollout);
}

export function getRuleRolloutFromUpdatedFlag(flag: Flag, environmentKey: string, ruleId: string) {
  const rules = flag.getRules(environmentKey) || List<Rule>();
  const rule = rules.find((r) => idOrKey(r) === ruleId);
  const rollout = rule?.get('rollout');

  return convertRolloutToSemanticInstructionRollout(flag.getVariations(), rollout);
}

function addPrerequisite(flag: Flag, environmentKey: string) {
  return changeFlagConfiguration(flag.addPrerequisite(environmentKey), [], environmentKey);
}

function deletePrerequisite(flag: Flag, environmentKey: string, prerequisite: Prerequisite) {
  return changeFlagConfiguration(
    flag.deletePrerequisite(environmentKey, prerequisite),
    [makeRemovePrerequisiteInstruction(prerequisite.key)],
    environmentKey,
  );
}

function editPrerequisite(
  flag: Flag,
  environmentKey: string,
  prerequisite: Prerequisite,
  variationId: string,
  originalPrerequisites: List<Prerequisite>,
  previousPrerequisiteKey: string | null,
) {
  return changeFlagConfiguration(
    flag.editPrerequisite(environmentKey, prerequisite),
    makeUpdatedPrerequisiteInstructionsArray(
      variationId,
      prerequisite.key,
      originalPrerequisites,
      previousPrerequisiteKey,
    ),
    environmentKey,
  );
}

const modifySegmentRule = (segmentKey: string, rule: Rule) => (dispatch: GlobalDispatch, getState: GetState) => {
  const state = getState();
  const environment = currentEnvironmentSelector(state).get('entity');
  const envKey = environment.key;
  const contextKind = rule.clauses.get(0)?.contextKind || 'user';
  const clause = createClause({
    attribute: 'segmentMatch',
    op: 'segmentMatch',
    contextKind,
    values: List([segmentKey]),
  });
  let removeClauses = List<string>();

  let flag = flagManagerSelector(state).get('modified') as Flag;
  flag = flag.updateConfiguration(envKey, (config) =>
    config.update('rules', (rules) =>
      rules.update(
        rules.findIndex((r) => r._key === rule._key),
        (r) => {
          removeClauses = r.clauses.map((c) => idOrKey(c));
          return r.set('clauses', List([clause]));
        },
      ),
    ),
  );

  dispatch(
    changeFlagConfiguration(
      flag,
      [
        makeAddClausesInstruction(idOrKey(rule), [clause.toJS()]),
        makeRemoveClausesInstruction(idOrKey(rule), removeClauses.toArray()),
      ],
      envKey,
    ),
  );
};

const addSegmentRule =
  (segmentKey: string, variation: Variation, contextKind: string = 'user') =>
  (dispatch: GlobalDispatch, getState: GetState) => {
    const state = getState();
    const environment = currentEnvironmentSelector(state).get('entity');
    const envKey = environment.key;

    const clause = createClause({
      attribute: 'segmentMatch',
      op: 'segmentMatch',
      values: List([segmentKey]),
      contextKind,
    });
    const rule = createRule({ clauses: List([clause]) });

    let flag = flagManagerSelector(state).get('modified') as Flag;
    flag = flag.updateConfiguration(envKey, (cfg) => cfg.update('rules', (rs) => rs.unshift(rule)));
    flag = flag.setRuleVariation(envKey, rule, variation);
    const flagConfig = flag.getConfiguration(envKey);
    dispatch(
      changeFlagConfiguration(
        flag,
        [
          makeAddRuleInstruction(rule),
          makeUpdateRuleVariationInstruction(idOrKey(rule), variation._id),
          makeReorderRulesInstruction(flagConfig.rules.map((r) => idOrKey(r)).toArray()),
        ],
        envKey,
      ),
    );
  };
function addRule(flag: Flag, environmentKey: string, defaultClause?: Clause | Clause[], rule?: Rule) {
  let updatedFlag = flag.addRule(environmentKey, defaultClause, rule);

  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  // The flag already has the newly created rule in that environment, so it's safe to assume we have it here.
  const newRule = updatedFlag
    .getRules(environmentKey)!
    .last<undefined>()!; /* eslint-enable @typescript-eslint/no-non-null-assertion */

  const instructions: SemanticInstruction[] = [makeAddRuleInstruction(newRule)];
  if (flag.isMigrationFlag()) {
    updatedFlag = updatedFlag
      .setRuleBucket(environmentKey, newRule, 'key', flag.getMigrationContextKind() || USER_CONTEXT_KIND)
      .setRuleRollout(environmentKey, newRule, flag.variations.first(), 100000);
    const newRollout = getRuleRolloutFromUpdatedFlag(updatedFlag, environmentKey, idOrKey(newRule));
    instructions.push(makeUpdateRuleRolloutInstruction(idOrKey(newRule), newRollout));
  }
  return changeFlagConfiguration(updatedFlag, instructions, environmentKey);
}

function deleteRule(flag: Flag, environmentKey: string, rule: Rule) {
  return changeFlagConfiguration(
    flag.deleteRule(environmentKey, rule),
    [makeRemoveRuleInstruction(idOrKey(rule))],
    environmentKey,
  );
}

function duplicateRule(flag: Flag, environmentKey: string, rule: Rule) {
  const { updatedFlag, newRule, newRuleIndex } = flag.duplicateRule(environmentKey, rule);
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  const rules = updatedFlag.getRules(environmentKey)!; /* eslint-enable @typescript-eslint/no-non-null-assertion */

  const addRuleInstruction = convertRuleToAddRuleSemanticInstruction(updatedFlag, newRule);
  const instructions: SemanticInstruction[] = [addRuleInstruction];

  // only skip the reorder rules instruction if the last rule was the one duplicated
  const ruleIds = rules.map((r) => idOrKey(r));
  if (newRuleIndex < ruleIds.size - 1) {
    instructions.push(makeReorderRulesInstruction(ruleIds.toArray()));
  }

  return changeFlagConfiguration(updatedFlag, instructions, environmentKey);
}

function setRuleIndex(flag: Flag, environmentKey: string, ruleKey: string, index: number) {
  const updatedFlag = flag.setRuleIndex(environmentKey, ruleKey, index);
  const rules = updatedFlag.getRules(environmentKey) || List();
  const ruleIds = rules.map((rule) => idOrKey(rule));
  return changeFlagConfiguration(updatedFlag, [makeReorderRulesInstruction(ruleIds.toArray())], environmentKey);
}

function addRuleAtIndex(
  flag: Flag,
  environmentKey: string,
  index: number,
  defaultClause?: Clause | Clause[],
  newRule?: Rule,
) {
  /* eslint-disable @typescript-eslint/no-non-null-assertion */

  // add rule to the end of the rules list
  const updatedFlag = flag.addRule(environmentKey, defaultClause, newRule);
  const rule = updatedFlag.getRules(environmentKey)!.last<undefined>()!;
  const addRuleInstruction = makeAddRuleInstruction(rule);

  // move newly added rule to specified index
  let reorderedFlag = updatedFlag.setRuleIndex(environmentKey, rule._key!, index);
  const rules = reorderedFlag.getRules(environmentKey) || List();
  const ruleIds = rules.map((r) => idOrKey(r));
  const reorderRulesInstruction = makeReorderRulesInstruction(ruleIds.toArray());

  /* eslint-enable @typescript-eslint/no-non-null-assertion */

  const instructions: SemanticInstruction[] = [addRuleInstruction];
  if (flag.isMigrationFlag()) {
    reorderedFlag = reorderedFlag
      .setRuleBucket(environmentKey, rule, 'key', flag.getMigrationContextKind() || USER_CONTEXT_KIND)
      .setRuleRollout(environmentKey, rule, flag.variations.first(), 100000);
    const newRollout = getRuleRolloutFromUpdatedFlag(reorderedFlag, environmentKey, idOrKey(rule));
    instructions.push(makeUpdateRuleRolloutInstruction(idOrKey(rule), newRollout));
  }
  instructions.push(reorderRulesInstruction);
  return changeFlagConfiguration(reorderedFlag, instructions, environmentKey);
}

function setToggleFlag(flag: Flag, environmentKey: string) {
  let newFlag = flag.toggle(environmentKey);

  // When we are turning the flag off, we need to reset measured rollout rules
  if (!newFlag.isOn(environmentKey)) {
    const newFlagRules = newFlag.getRules(environmentKey) || List<Rule>();
    newFlag = newFlag
      .setRules(
        environmentKey,
        newFlagRules.map((rule) => rule.set('measuredRolloutConfig', undefined)),
      )
      .setFallthroughMeasuredRolloutConfig(environmentKey, undefined);
  }

  return changeFlagConfiguration(newFlag, [makeToggleFlagInstruction(flag.isOn(environmentKey))], environmentKey);
}

function turnFlagOn(flag: Flag, environmentKey: string) {
  const newFlag = flag.updateConfiguration(environmentKey, (config) => config.set('on', true));
  return changeFlagConfiguration(newFlag, [makeTurnFlagOnInstruction()], environmentKey);
}

function setGuardedRolloutVariation(
  originalFlag: Flag,
  draftFlag: Flag,
  environmentKey: string,
  rule: Fallthrough | Rule,
  testVariation: Variation,
  newControlVariation?: Variation,
) {
  const instructions: SemanticInstruction[] = [];
  const measuredRolloutConfig = nullthrows(
    rule.measuredRolloutConfig,
    'setGuardedRolloutVariation should only be called with a rule that has a measuredRolloutConfig',
  );
  const ruleId = rule instanceof Rule ? idOrKey(rule) : 'fallthrough';
  const { controlVariation: fallbackControlVariation } = getControlAndTestVariations(
    originalFlag,
    draftFlag,
    environmentKey,
    rule,
  );

  const validControlVariation = nullthrows(newControlVariation || fallbackControlVariation);

  if (rule instanceof Rule) {
    instructions.push(
      makeUpdateRuleWithMeasuredRolloutV2Instruction(
        ruleId,
        testVariation._id,
        validControlVariation._id,
        measuredRolloutConfig,
      ),
    );
  } else if (rule instanceof Fallthrough) {
    instructions.push(
      makeUpdateFallthroughWithMeasuredRolloutV2Instruction(
        testVariation._id,
        validControlVariation._id,
        measuredRolloutConfig,
      ),
    );
  }

  return changeFlagConfiguration(draftFlag, instructions, environmentKey);
}

function setRuleVariation(
  originalFlag: Flag,
  draftFlag: Flag,
  environmentKey: string,
  rule: Rule,
  variation: Variation,
) {
  let newFlag = draftFlag.setRuleVariation(environmentKey, rule, variation);
  let newRule = nullthrows(newFlag.getRuleById(environmentKey, idOrKey(rule)));

  if (enableReleaseGuardianRefreshedUIDropdown()) {
    newFlag = newFlag.setRuleMeasuredRolloutConfig(environmentKey, newRule, undefined);
    newRule = nullthrows(newFlag.getRuleById(environmentKey, idOrKey(rule)));

    return changeFlagConfiguration(
      newFlag,
      [makeUpdateRuleVariationInstruction(idOrKey(rule), variation._id)],
      environmentKey,
    );
  }
  const { controlVariation, testVariation } = getControlAndTestVariations(
    originalFlag,
    newFlag,
    environmentKey,
    newRule,
  );

  if (
    enableReleaseGuardianRefreshedUIDropdown() ||
    (newRule.measuredRolloutConfig && controlVariation === testVariation)
  ) {
    newFlag = newFlag.setRuleMeasuredRolloutConfig(environmentKey, newRule, undefined);
    newRule = nullthrows(newFlag.getRuleById(environmentKey, idOrKey(rule)));
  }

  if (!newRule.measuredRolloutConfig) {
    return changeFlagConfiguration(
      newFlag,
      [makeUpdateRuleVariationInstruction(idOrKey(rule), variation._id)],
      environmentKey,
    );
  }

  const measuredRolloutConfig = nullthrows(newRule.measuredRolloutConfig);

  const instruction: SemanticInstruction = makeUpdateRuleWithMeasuredRolloutV2Instruction(
    idOrKey(rule),
    variation._id,
    nullthrows(controlVariation?._id),
    measuredRolloutConfig,
  );

  return changeFlagConfiguration(newFlag, [instruction], environmentKey);
}

function setRuleRollout(flag: Flag, environmentKey: string, rule: Rule, variation: Variation, weight: number) {
  let newFlag = flag.setRuleRollout(environmentKey, rule, variation, weight);
  const newRollout = getRuleRolloutFromUpdatedFlag(newFlag, environmentKey, idOrKey(rule));

  if (rule.measuredRolloutConfig) {
    newFlag = newFlag.setRuleMeasuredRolloutConfig(environmentKey, rule, undefined);
  }

  return changeFlagConfiguration(
    newFlag,
    [makeUpdateRuleRolloutInstruction(idOrKey(rule), newRollout)],
    environmentKey,
  );
}

function setRuleExperimentRollout(
  flag: Flag,
  environmentKey: string,
  rule: Rule,
  rolloutData: List<WeightedVariation>,
  experimentData: ExperimentRollout,
) {
  const newFlag = flag.setRuleExperimentRollout(environmentKey, rule, rolloutData, experimentData);
  const newRollout = getRuleRolloutFromUpdatedFlag(newFlag, environmentKey, idOrKey(rule));
  return changeFlagConfiguration(
    newFlag,
    [makeUpdateRuleRolloutInstruction(idOrKey(rule), newRollout)],
    environmentKey,
  );
}

function setRuleBucket(flag: Flag, environmentKey: string, rule: Rule, bucketBy: string, contextKind?: string) {
  const newFlag = flag.setRuleBucket(
    environmentKey,
    rule,
    bucketBy === 'key' && !contextKind ? undefined : bucketBy,
    contextKind,
  );
  const newRollout = getRuleRolloutFromUpdatedFlag(newFlag, environmentKey, idOrKey(rule));
  return changeFlagConfiguration(
    newFlag,
    [makeUpdateRuleRolloutInstruction(idOrKey(rule), newRollout)],
    environmentKey,
  );
}

const stopMeasuredRolloutOnRule = ({
  flag,
  environmentKey,
  ruleId,
  finalVariationId,
  comment,
}: {
  flag: Flag;
  environmentKey: string;
  ruleId: string;
  finalVariationId: string;
  comment: string;
}) => {
  const instruction = makeStopMeasuredRolloutOnRuleInstruction(ruleId, finalVariationId, comment);
  return changeFlagConfiguration(flag, [instruction], environmentKey);
};

function addRuleClause(flag: Flag, environmentKey: string, rule: Rule, clause?: Clause) {
  const updatedFlag = flag.addRuleClause(environmentKey, rule, clause);
  const rules = updatedFlag.getRules(environmentKey) || List();
  const newClause = rules
    .find((r) => idOrKey(r) === idOrKey(rule))
    .get('clauses')
    .last();
  return changeFlagConfiguration(updatedFlag, [makeAddClausesInstruction(idOrKey(rule), [newClause])], environmentKey);
}

function deleteRuleClause(flag: Flag, environmentKey: string, rule: Rule, clause: Clause) {
  return changeFlagConfiguration(
    flag.deleteRuleClause(environmentKey, rule, clause),
    [makeRemoveClausesInstruction(idOrKey(rule), [idOrKey(clause)])],
    environmentKey,
  );
}

function editRuleClause(flag: Flag, environmentKey: string, rule: Rule, clause: Clause) {
  const instructions = [];

  // If this change is only in clause values, use one of the more specific instructions.
  const existingClause = rule.clauses.find((c) => idOrKey(c) === idOrKey(clause));
  if (
    existingClause &&
    clause.attribute === existingClause.attribute &&
    clause.op === existingClause.op &&
    clause.negate === existingClause.negate &&
    clause.getContextKind() === existingClause.getContextKind()
  ) {
    const valuesToAdd = clause.values.filter((value) => !existingClause.values.contains(value));
    if (valuesToAdd.size) {
      instructions.push(makeAddValuesToClauseInstruction(idOrKey(rule), idOrKey(clause), valuesToAdd.toArray()));
    }
    const valuesToRemove = existingClause.values.filter((value) => !clause.values.contains(value));
    if (valuesToRemove.size) {
      instructions.push(
        makeRemoveValuesFromClauseInstruction(idOrKey(rule), idOrKey(clause), valuesToRemove.toArray()),
      );
    }
  } else {
    instructions.push(makeUpdateClauseInstruction(idOrKey(rule), idOrKey(clause), clause));
  }

  return changeFlagConfiguration(flag.editRuleClause(environmentKey, rule, clause), instructions, environmentKey);
}

function editRuleDescription(flag: Flag, environmentKey: string, rule: Rule, description: string) {
  return changeFlagConfiguration(
    flag.editRuleDescription(environmentKey, rule, description),
    rule.description !== description ? [makeUpdateRuleDescription(idOrKey(rule), description)] : [],
    environmentKey,
  );
}

function editRuleTrackEvents(flag: Flag, environmentKey: string, rule: Rule, value: boolean) {
  return changeFlagConfiguration(flag.editRuleTrackEvents(environmentKey, rule, value), [], environmentKey);
}

function shouldFetchExperimentSummary(state: GlobalState, flagKey: string, metricKey: string) {
  const experimentSummaryResults = flagExperimentSummarySelector(flagKey, metricKey)(state);
  return (
    !experimentSummaryResults ||
    (!experimentSummaryResults.get('lastFetched') && !experimentSummaryResults.get('isFetching'))
  );
}

function discardGuardedRolloutsInstructions(flag: Flag, environmentKey: string) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const state = getState();
    const instructions = state.flagManager.get('pendingSemanticPatch').get('instructions');
    const guardedRolloutInstructionsToRemove = findAllGuardedRolloutInstructions(
      List(Object.values(instructions.toJS())).toArray(),
    );
    dispatch(discardSpecificConfigChanges(flag, environmentKey, guardedRolloutInstructionsToRemove));
  };
}

function discardOffVariationChanges(
  originalFlag: Flag,
  flag: Flag,
  environmentKey: string,
  pendingInstructions: SemanticInstruction[],
) {
  return (dispatch: GlobalDispatch) => {
    const originalOffVariation = originalFlag.getOffVariation(environmentKey);
    if (!originalOffVariation) {
      const updatedFlag = flag.clearOffVariation(environmentKey);
      dispatch(discardSpecificConfigChanges(updatedFlag, environmentKey, pendingInstructions));
      return;
    }
    const updatedFlag = flag.setOffVariation(environmentKey, originalOffVariation);
    dispatch(discardSpecificConfigChanges(updatedFlag, environmentKey, pendingInstructions));
    return;
  };
}

function discardPrerequisitesChanges(
  originalFlag: Flag,
  flag: Flag,
  environmentKey: string,
  pendingInstructions: SemanticInstruction[],
) {
  return (dispatch: GlobalDispatch) => {
    const originalPrereqs = originalFlag.getPrerequisites(environmentKey);
    if (!originalPrereqs) {
      const updatedFlag = flag.setPrerequisites(environmentKey, List());
      dispatch(discardSpecificConfigChanges(updatedFlag, environmentKey, pendingInstructions));
      return;
    }
    const updatedFlag = flag.setPrerequisites(environmentKey, originalPrereqs);
    dispatch(discardSpecificConfigChanges(updatedFlag, environmentKey, pendingInstructions));
    return;
  };
}

function discardTargetingChanges(
  originalFlag: Flag,
  flag: Flag,
  environmentKey: string,
  pendingInstructions: SemanticInstruction[],
) {
  return (dispatch: GlobalDispatch) => {
    const originalTargets = originalFlag.getTargets(environmentKey);
    if (!originalTargets) {
      const updatedFlag = flag.setTargets(environmentKey, List());
      dispatch(discardSpecificConfigChanges(updatedFlag, environmentKey, pendingInstructions));
      return;
    }

    const updatedFlag = flag.setTargets(environmentKey, originalTargets);
    dispatch(discardSpecificConfigChanges(updatedFlag, environmentKey, pendingInstructions));
    return;
  };
}

function discardCustomRuleChanges(
  originalFlag: Flag,
  flag: Flag,
  environmentKey: string,
  ruleId: string,
  pendingInstructions: DiscardableCustomRuleInstruction[],
) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const originalRules = originalFlag.getRules(environmentKey) || List<Rule>();
    const modifiedRules = flag.getRules(environmentKey) || List<Rule>();

    const originalRule = originalRules.find((r) => idOrKey(r) === ruleId);
    const modifiedRule = modifiedRules.find((r) => idOrKey(r) === ruleId);

    const ruleIsNew = !originalRule && modifiedRule;

    // if rule is brand new and not saved, just remove it
    if (ruleIsNew) {
      const newRulePendingSemanticIns: SemanticInstruction[] = [...pendingInstructions];
      const appendInstructions: SemanticInstruction[] = [];

      // If brand new (unsaved) rules are reordered,
      // when saved (PATCH request), the order will be correct, but the reorder instruction will not be sent to the API.
      // The reorder instruction is only sent to the API on PATCH if the reorder applies to existing rules.
      const pendingInsHasReorderRules = pendingInstructions.find(
        (ins) => ins.kind === RuleInstructionKind.REORDER_RULES,
      );
      if (!pendingInsHasReorderRules) {
        const flagManagerReorderInstruction = getFlagManagerReorderRulesInstruction(
          getState().flagManager.get('pendingSemanticPatch').get('instructions'),
        );
        if (flagManagerReorderInstruction) {
          // add the reorder instruction to the array for removal
          newRulePendingSemanticIns.push(flagManagerReorderInstruction);

          // if more than 2 ruleIds in the reorder instruction, preserve the remaining ids
          if (flagManagerReorderInstruction.ruleIds.length > 2) {
            const updatedReorderInstruction: ReorderRulesSemanticInstruction = {
              kind: RuleInstructionKind.REORDER_RULES,
              ruleIds: flagManagerReorderInstruction.ruleIds.filter((rId) => rId !== ruleId),
            };
            appendInstructions.push(updatedReorderInstruction);
          }
        }
      }

      const updatedRules = modifiedRules.filter((r) => idOrKey(r) !== ruleId);
      const updatedFlag = flag.setRules(environmentKey, updatedRules);
      dispatch(
        discardSpecificConfigChanges(updatedFlag, environmentKey, newRulePendingSemanticIns, appendInstructions),
      );
      return;
    }

    if (originalRule && modifiedRule) {
      const originalRuleIndex = originalRules.indexOf(originalRule);
      const modifiedRuleIndex = modifiedRules.indexOf(modifiedRule);

      const rulesWereReordered =
        getInstructionsByKind(pendingInstructions, RuleInstructionKind.REORDER_RULES).length > 0;

      // reset rule back to original value first, before taking into account order
      let updatedRules = modifiedRules.set(modifiedRuleIndex, originalRule);
      let updatedReorderInstructions;

      if (rulesWereReordered) {
        // put the rule that was shifted during reordering back into its original position
        updatedRules = updatedRules.delete(modifiedRuleIndex);
        updatedRules = updatedRules.splice(originalRuleIndex, 0, originalRule);

        // Compare the original order of the rules to the updated order.
        // If they are different still, then there are some other rules
        // that have changed orders and we want to keep those semantic instructions.
        const originalOrder = originalRules.map((r) => idOrKey(r)).toJS();
        const updatedOrder = updatedRules.map((r) => idOrKey(r)).toJS();
        const rulesStillHaveChangedOrders = !isEqual(originalOrder, updatedOrder);

        if (rulesStillHaveChangedOrders) {
          updatedReorderInstructions = [makeReorderRulesInstruction(updatedRules.toArray().map((r) => idOrKey(r)))];
        }
      }

      const updatedFlag = flag.setRules(environmentKey, updatedRules);

      dispatch(
        discardSpecificConfigChanges(updatedFlag, environmentKey, pendingInstructions, updatedReorderInstructions),
      );
      return;
    }
  };
}

function discardFallthroughVariationChanges(
  originalFlag: Flag,
  flag: Flag,
  environmentKey: string,
  pendingInstructions: SemanticInstruction[],
) {
  return (dispatch: GlobalDispatch) => {
    const originalFallthrough = originalFlag.getFallthrough(environmentKey);
    if (!originalFallthrough) {
      const updatedFlag = flag.setFallthrough(environmentKey, createFallthrough());
      dispatch(discardSpecificConfigChanges(updatedFlag, environmentKey, pendingInstructions));
      return;
    }

    const updatedFlag = flag.setFallthrough(environmentKey, originalFallthrough);
    dispatch(discardSpecificConfigChanges(updatedFlag, environmentKey, pendingInstructions));
    return;
  };
}

function discardSpecificConfigChanges(
  flag: Flag,
  environmentKey: string,
  removeInstructions: SemanticInstruction[],
  appendInstructions?: SemanticInstruction[],
) {
  return {
    type: 'flags/DISCARD_SPECIFIC_CONFIG_CHANGES',
    flag,
    removeInstructions,
    appendInstructions,
    environmentKey,
  } as const;
}

function resetVariations() {
  return {
    type: 'flags/RESET_VARIATIONS',
  } as const;
}

function initializeVariationManager(flag: Flag) {
  return {
    type: 'flags/INITIALIZE_VARIATION_MANAGER',
    flag,
  } as const;
}

function destroyVariationManager() {
  return {
    type: 'flags/DESTROY_VARIATION_MANAGER',
  } as const;
}

function changeVariations(flag: Flag) {
  return {
    type: 'flags/CHANGE_VARIATIONS',
    flag,
  } as const;
}

const requestGoalResults = (flag: Flag, goalId: string) =>
  ({
    type: 'flags/REQUEST_GOAL_RESULTS',
    flag,
    goalId,
  }) as const;

const requestGoalResultsDone = (flag: Flag, goalId: string, results: ExperimentResults) =>
  ({ type: 'flags/REQUEST_GOAL_RESULTS_DONE', flag, goalId, results }) as const;

const requestGoalResultsFailed = (flag: Flag, goalId: string, error: ImmutableServerError) =>
  ({ type: 'flags/REQUEST_GOAL_RESULTS_FAILED', flag, goalId, error }) as const;

const requestExperimentSummaryResults = (flag: Flag, metricKey: string) =>
  ({
    type: 'flags/REQUEST_EXPERIMENT_SUMMARY_RESULTS',
    flag,
    metricKey,
  }) as const;

const requestExperimentSummaryResultsDone = (flag: Flag, metricKey: string, data: ExperimentResults) =>
  ({
    type: 'flags/REQUEST_EXPERIMENT_SUMMARY_RESULTS_DONE',
    flag,
    metricKey,
    data,
  }) as const;

const requestExperimentSummaryResultsFailed = (flag: Flag, metricKey: string, error: ImmutableServerError) =>
  ({
    type: 'flags/REQUEST_EXPERIMENT_SUMMARY_RESULTS_FAILED',
    flag,
    metricKey,
    error,
  }) as const;

function fetchExperimentSummaryResults(
  flag: Flag,
  projectKey: string,
  environmentKey: string,
  metricKey: string,
  filters: ExperimentsResultQuery,
) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchExperimentSummary(getState(), flag.key, metricKey)) {
      dispatch(requestExperimentSummaryResults(flag, metricKey));
      return FlagAPI.getExperimentSummariesResults(flag.key, projectKey, environmentKey, metricKey, filters)
        .then((data) => {
          dispatch(requestExperimentSummaryResultsDone(flag, metricKey, data));
        })
        .catch((error) => {
          dispatch(requestExperimentSummaryResultsFailed(flag, metricKey, error));
        });
    }
  };
}

const requestExperimentSeriesResults = (flag: Flag, metricKey: string) =>
  ({
    type: 'flags/REQUEST_EXPERIMENT_SERIES_RESULTS',
    flag,
    metricKey,
  }) as const;

const requestExperimentSeriesResultsDone = (flag: Flag, metricKey: string, data: ExperimentResults) =>
  ({
    type: 'flags/REQUEST_EXPERIMENT_SERIES_RESULTS_DONE',
    flag,
    metricKey,
    data,
  }) as const;

const requestExperimentSeriesResultsFailed = (flag: Flag, metricKey: string, error: ImmutableServerError) =>
  ({
    type: 'flags/REQUEST_EXPERIMENT_SERIES_RESULTS_FAILED',
    flag,
    metricKey,
    error,
  }) as const;

function fetchExperimentSeriesResults(
  flag: Flag,
  projectKey: string,
  environmentKey: string,
  metricKey: string,
  filters: ExperimentsResultQuery,
) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestExperimentSeriesResults(flag, metricKey));
    return FlagAPI.getExperimentSeriesResults(flag.key, projectKey, environmentKey, metricKey, filters)
      .then((data) => {
        dispatch(requestExperimentSeriesResultsDone(flag, metricKey, data));
      })
      .catch((error) => {
        dispatch(requestExperimentSeriesResultsFailed(flag, metricKey, error));
      });
  };
}

const requestDependentFlagsWithEnv = (flagKey: string, projectKey: string) =>
  ({
    type: 'flags/REQUEST_DEPENDENT_FLAGS_WITH_ENV',
    flagKey,
    projectKey,
  }) as const;

const requestDependentFlagsWithEnvDone = (flagKey: string, projectKey: string, dependentFlags: FlagAndEnvKeys) =>
  ({
    type: 'flags/RECEIVE_DEPENDENT_FLAGS_WITH_ENV',
    flagKey,
    projectKey,
    dependentFlags,
  }) as const;

const requestDependentFlagsWithEnvFailed = (flagKey: string, projectKey: string, error: ImmutableServerError) =>
  ({
    type: 'flags/RECEIVE_DEPENDENT_FLAGS_WITH_ENV_FAILED',
    flagKey,
    projectKey,
    error,
  }) as const;

function fetchDependentFlagsByEnv(projectKey: string, flagKey: string, envKey: string) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestDependentFlagsWithEnv(flagKey, projectKey));
    return new Promise<void>((resolve, reject) => {
      FlagAPI.getDependentFlagsByEnv(projectKey, flagKey, envKey)
        .then((dependentFlags) => {
          dispatch(requestDependentFlagsWithEnvDone(flagKey, projectKey, dependentFlags));
          resolve();
        })
        .catch((error) => {
          dispatch(requestDependentFlagsWithEnvFailed(flagKey, projectKey, error));
          reject(error);
        });
    });
  };
}

const requestDependentFlags = (flagKey: string, projectKey: string) =>
  ({
    type: 'flags/REQUEST_DEPENDENT_FLAGS',
    flagKey,
    projectKey,
  }) as const;

const requestDependentFlagsDone = (flagKey: string, projectKey: string, dependentFlags: FlagAndEnvKeys) =>
  ({
    type: 'flags/RECEIVE_DEPENDENT_FLAGS',
    flagKey,
    projectKey,
    dependentFlags,
  }) as const;

const requestDependentFlagsFailed = (flagKey: string, projectKey: string, error: ImmutableServerError) =>
  ({
    type: 'flags/RECEIVE_DEPENDENT_FLAGS_FAILED',
    flagKey,
    projectKey,
    error,
  }) as const;

function fetchDependentFlags(projectKey: string, flagKey: string) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestDependentFlags(flagKey, projectKey));
    return new Promise<void>((resolve, reject) => {
      FlagAPI.getDependentFlags(projectKey, flagKey)
        .then((dependentFlags) => {
          dispatch(requestDependentFlagsDone(flagKey, projectKey, dependentFlags));
          resolve();
        })
        .catch((error) => {
          dispatch(requestDependentFlagsFailed(flagKey, projectKey, error));
          reject(error);
        });
    });
  };
}

const updateFlagDebug = (flag: Flag, projectKey: string) =>
  ({
    type: 'flags/UPDATE_FLAG_DEBUG',
    flag,
    projectKey,
  }) as const;

const updateFlagDebugDone = (flag: Flag, projectKey: string) =>
  ({
    type: 'flags/UPDATE_FLAG_DEBUG_DONE',
    flag,
    projectKey,
  }) as const;

const updateFlagDebugFailed = (flag: Flag, projectKey: string, error: ImmutableServerError) =>
  ({
    type: 'flags/UPDATE_FLAG_DEBUG_FAILED',
    flag,
    projectKey,
    error,
  }) as const;

function debugFlag(flag: Flag, timeInSeconds: number) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    const state = getState();
    const environment = currentEnvironmentSelector(state).get('entity');
    const project = currentProjectSelector(state).get('entity');
    dispatch(updateFlagDebug(flag, project.key));
    return FlagAPI.postFlagDebug(flag, environment, timeInSeconds)
      .then((modifiedFlagConfig) => {
        const updatedFlag = flag.withMutations((f) => {
          f.setIn(['environments', environment.key], modifiedFlagConfig);
        });
        dispatch(updateFlagDebugDone(updatedFlag, project.key));
      })
      .catch((error) => {
        dispatch(updateFlagDebugFailed(flag, project.key, error));
      });
  };
}

function startDebuggingFlag(flag: Flag) {
  const minutes = debuggerFullFidelityEventDurationMinutes();
  return debugFlag(flag, minutes * 60);
}

function stopDebuggingFlag(flag: Flag) {
  return debugFlag(flag, 0);
}

function resetPagination(projectKey: string) {
  return {
    type: 'flags/RESET_PAGINATION',
    projectKey,
  } as const;
}

const disconnectFlagMetric = (
  projectKey: string,
  environmentKey: string,
  originalFlag: Flag,
  metricKey: string,
  options: UpdateFlagOptions & { eventLocation: EventLocation },
) => {
  const updatedFlag = originalFlag.updateIn(['experiments', 'items'], (expItems: List<Experiment>) =>
    expItems.filter((item) => item.metricKey !== metricKey),
  );
  return updateFlagGoals(projectKey, environmentKey, originalFlag, updatedFlag, options);
};

const requestResetExperiment = (metricKey: string) =>
  ({
    type: 'flags/RESET_EXPERIMENT',
    metricKey,
  }) as const;

const requestResetExperimentDone = (metricKey: string) =>
  ({
    type: 'flags/RESET_EXPERIMENT_DONE',
    metricKey,
  }) as const;

const requestResetExperimentFailed = (metricKey: string, error: boolean) =>
  ({
    type: 'flags/RESET_EXPERIMENT_FAILED',
    metricKey,
    error,
  }) as const;

function resetExperiment(flag: Flag, metricKey: string) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    try {
      const state = getState();
      const project = currentProjectSelector(state).get('entity');
      const environment = currentEnvironmentSelector(state).get('entity');

      logger.log(`Start resetting ${flag.key}-${metricKey}...`);
      dispatch(requestResetExperiment(metricKey));

      await FlagAPI.resetExperiment(project.key, environment.key, flag.key, metricKey);

      logger.log(`Done resetting ${flag.key}-${metricKey}. Re-fetching series results now...`);
      dispatch(requestResetExperimentDone(metricKey));

      dispatch(fetchExperimentSeriesResults(flag, project.key, environment.key, metricKey, {})).then(noop, noop);
      dispatch(fetchFlag(project.key, flag.key, environment.key, { skipRequestDispatch: true })).then(noop, noop);
      logger.log('Done re-fetching series results.');
    } catch (error) {
      logger.error(`Error resetting ${flag.key}-${metricKey}: ${error}`);
      dispatch(requestResetExperimentFailed(metricKey, true));
    }
  };
}

// This function is only used by toggleFlagSafely. It's weird as there's no "newFlag", so we fake it.
function updateFlagSemantically(
  projectKey: string,
  oldFlag: Flag,
  environmentKey: string,
  instructions: Iterable<SemanticInstruction>,
  options = {},
) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(updateFlagRequest(oldFlag, oldFlag.toggle(environmentKey)));
    return FlagAPI.updateFlagSemantically(projectKey, oldFlag, environmentKey, instructions, options)
      .then((flag) => {
        dispatch(updateFlagRequestDone(flag, projectKey));
        if (isFlagStatusEnabled()) {
          defer(async () => dispatch(fetchFlagStatusByKeyIfNeeded(projectKey, flag.key, environmentKey)));
        }
      })
      .catch((error) => {
        dispatch(updateFlagRequestFailed(oldFlag, error));
        dispatch({ type: actionTypes.UPDATE_FLAG_FAILED, oldFlag, error });
      });
  };
}

export const clearAllTargetsAndExpirations =
  ({
    flag,
    environmentKey,
    variationIndex,
    contextsToClear,
    expiringContextTargetsForFlag,
    targetingExpirationUpdates,
  }: {
    flag: Flag;
    environmentKey: string;
    variationIndex: number;
    contextsToClear: string[];
    expiringContextTargetsForFlag?: ExpiringContextTargetsByContextKindAndKey;
    targetingExpirationUpdates?: ContextTargetingExpirationUpdates;
  }) =>
  (dispatch: GlobalDispatch) => {
    const variationId = flag.getVariations().getIn([variationIndex, '_id']);
    // Remove pending expiring target updates
    contextsToClear.forEach((contextKind) => {
      const pendingExpiringContextKeys = getPendingExpiringTargetKeys(
        contextKind,
        flag.key,
        variationId,
        targetingExpirationUpdates,
      );
      pendingExpiringContextKeys.forEach((contextKey) =>
        dispatch(
          undoContextTargetingExpiration({
            contextKind,
            contextKey,
            flagKey: flag.key,
            variationId,
          }),
        ),
      );
    });

    // Remove expiring targets
    contextsToClear.forEach((contextKind) => {
      const expiringContextKeys = getExpiringTargetKeys(contextKind, variationId, expiringContextTargetsForFlag);
      expiringContextKeys.forEach((contextKey) =>
        dispatch(
          deleteContextTargetingExpiration({
            contextKind,
            contextKey,
            flagKey: flag.key,
            variationId,
          }),
        ),
      );
    });

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Assume the variation does exists since we built the UI when it was present. If something's changed
    // on the server, we'll get a 409 and we can handle that elsewhere.
    const variation = flag.variations.get(variationIndex)!; /* eslint-enable @typescript-eslint/no-non-null-assertion */
    dispatch(clearAllTargets(flag, environmentKey, variation, contextsToClear));
  };

const getFlagManagerReorderRulesInstruction = (instructions: InstructionsType) => {
  const reorderInstruction = Object.values(instructions.toJS()).find(
    (ins) => ins.kind === RuleInstructionKind.REORDER_RULES,
  );
  if (reorderInstruction) {
    return reorderInstruction as ReorderRulesSemanticInstruction;
  }
};

// This is a hack to keep reducers in sync with the new shell. We do this to avoid HTTP 409
// if the user saves a project setting change after they've updated the maintainer or tags,
// while the UI that uses this reducer is mounted.
const syncFlagWithRestAPI = (projectKey: string, flag: FeatureFlag) =>
  ({
    type: 'flags/SYNC_FLAG_WITH_REST_API',
    projectKey,
    flag,
  }) as const;

function setRuleMeasuredRolloutConfig(
  originalFlag: Flag,
  draftFlag: Flag,
  environmentKey: string,
  rule: Rule,
  config?: MeasuredRolloutConfig,
) {
  const { controlVariation, testVariation } = getControlAndTestVariations(
    originalFlag,
    draftFlag,
    environmentKey,
    rule,
  );

  const ruleId = nullthrows(rule._id ?? rule._key);
  const controlVariationId = nullthrows(controlVariation?._id);

  if (enableReleaseGuardianRefreshedUIDropdown()) {
    const instructions: SemanticInstruction[] = [];
    let updatedFlag = ensureFlagOnForMeasuredRollout(draftFlag, environmentKey, config)?.updatedFlag;
    instructions.push(makeTurnFlagOnInstruction());

    const newTestVariation: Variation = nullthrows(
      updatedFlag.getDefaultRolloutVariation(controlVariationId, testVariation?._id),
    );

    updatedFlag = updatedFlag.updateConfiguration(environmentKey, (draftFlagConfig) => {
      const ruleIndex = updatedFlag.getRuleIndex(environmentKey, ruleId);
      const newTestVariationIndex = updatedFlag.variations.indexOf(newTestVariation);
      return draftFlagConfig.setIn(['rules', ruleIndex, 'variation'], newTestVariationIndex);
    });

    updatedFlag = clearPercentageRollout(updatedFlag, environmentKey, rule);
    instructions.push(createRuleMeasuredRolloutInstruction(ruleId, newTestVariation._id, controlVariationId, config));
    return changeFlagConfiguration(
      updatedFlag.setRuleMeasuredRolloutConfig(environmentKey, rule, config),
      instructions,
      environmentKey,
    );
  }

  const testVariationId = nullthrows(testVariation?._id);

  if (!config) {
    return changeFlagConfiguration(
      draftFlag.setRuleMeasuredRolloutConfig(environmentKey, rule, config),
      [makeUpdateRuleVariationInstruction(ruleId, testVariationId)],
      environmentKey,
    );
  }

  return changeFlagConfiguration(
    draftFlag.setRuleMeasuredRolloutConfig(environmentKey, rule, config),
    [makeUpdateRuleWithMeasuredRolloutV2Instruction(ruleId, testVariationId, controlVariationId, config)],
    environmentKey,
  );
}

function ensureFlagOnForMeasuredRollout(
  draftFlag: Flag,
  environmentKey: string,
  config?: MeasuredRolloutConfig,
): { updatedFlag: Flag; instructions: SemanticInstruction[] } {
  const instructions: SemanticInstruction[] = [];
  let updatedFlag = draftFlag;

  // If we're adding a measured rollout config, ensure that the flag is on.
  if (config && !draftFlag.isOn(environmentKey)) {
    updatedFlag = draftFlag.updateConfiguration(environmentKey, (draftFlagConfig) => draftFlagConfig.set('on', true));
    instructions.push(makeTurnFlagOnInstruction());
  }

  return {
    updatedFlag,
    instructions,
  };
}

function setFallthroughMeasuredRolloutConfig(
  originalFlag: Flag,
  draftFlag: Flag,
  environmentKey: string,
  config?: MeasuredRolloutConfig,
) {
  let updatedDraftFlag = draftFlag;
  const instructions: SemanticInstruction[] = [];

  const { updatedFlag, instructions: turnFlagOnInstructions } = ensureFlagOnForMeasuredRollout(
    draftFlag,
    environmentKey,
    config,
  );
  // Add instructions to turn flag on if necessary
  instructions.push(...turnFlagOnInstructions);
  updatedDraftFlag = updatedFlag;
  const draftConfig = updatedFlag.getConfiguration(environmentKey);
  const { controlVariation, testVariation } = getControlAndTestVariations(
    originalFlag,
    updatedDraftFlag,
    environmentKey,
    draftConfig.fallthrough,
  );

  const controlVariationId = nullthrows(controlVariation?._id);

  let rolloutInstruction: SemanticInstruction;
  if (!config) {
    const testVariationId = nullthrows(testVariation?._id);
    rolloutInstruction = makeUpdateFallthroughVariationInstruction(testVariationId);
  } else {
    const newTestVariation: Variation | undefined = nullthrows(
      updatedDraftFlag.getDefaultRolloutVariation(controlVariation?._id, testVariation?._id),
    );

    if (draftFlag.getIn(['environments', environmentKey, 'fallthrough', 'rollout', 'variations'])) {
      // Delete fallthrough rollout for percentage rollouts, so that we can override with guarded rollout.
      updatedDraftFlag = updatedDraftFlag.deleteIn(['environments', environmentKey, 'fallthrough', 'rollout']);
    }

    // Update fallthrough variation to new test variation
    updatedDraftFlag = updatedDraftFlag.updateConfiguration(environmentKey, (draftFlagConfig) =>
      draftFlagConfig.setIn(['fallthrough', 'variation'], draftFlag.variations.indexOf(newTestVariation)),
    );

    rolloutInstruction = makeUpdateFallthroughWithMeasuredRolloutV2Instruction(
      newTestVariation._id,
      controlVariationId,
      config,
    );
  }

  instructions.push(rolloutInstruction);

  return changeFlagConfiguration(
    updatedDraftFlag.setFallthroughMeasuredRolloutConfig(environmentKey, config),
    instructions,
    environmentKey,
  );
}

export {
  ensureFlagOnForMeasuredRollout,
  fetchFlags as fetchFlagsExplicitly,
  fetchFlagsIfNeeded as fetchFlagsForProject,
  fetchFlagByKeyForCurrentProject as fetchFlag,
  fetchFlag as forceFetchFlag,
  fetchFlagIfNeeded,
  fetchFlagWithStatus,
  fetchFlagStatusByKeyIfNeeded,
  fetchFlagStatusesForEnvironment,
  updateFlag,
  updateFlagGoals,
  updateFlagMaintainer,
  updateProjectFlagSettings,
  updateEnvFlagSettings,
  updateRuleExclusion,
  editEnvFlagSetting,
  editRuleExclusion,
  toggleFlagSafely,
  addVariation,
  removeVariation,
  editVariation,
  editFlag,
  editProjectFlagMaintainer,
  editProjectFlagSettings,
  initializeConfigurationManager,
  initializeProjectFlagMaintainerFormAction,
  destroyConfigurationManager,
  resetConfiguration,
  selectOffVariation,
  clearOffVariation,
  setTarget,
  clearAllTargets,
  setFallthroughVariation,
  setFallthroughRollout,
  setFallthroughExperimentRollout,
  setFallthroughBucket,
  setProgressiveRolloutConfig,
  stopMeasuredRolloutOnFallthrough,
  initializeVariationManager,
  destroyVariationManager,
  resetVariations,
  changeVariations,
  addPrerequisite,
  deletePrerequisite,
  editPrerequisite,
  addRule,
  addRuleAtIndex,
  deleteRule,
  duplicateRule,
  addRuleClause,
  deleteRuleClause,
  editRuleClause,
  editRuleDescription,
  editRuleTrackEvents,
  setRuleVariation,
  setGuardedRolloutVariation,
  setRuleRollout,
  setRuleExperimentRollout,
  setRuleBucket,
  setRuleIndex,
  stopMeasuredRolloutOnRule,
  setToggleFlag,
  fetchExperimentSummaryResults,
  fetchExperimentSeriesResults,
  fetchDependentFlags,
  fetchDependentFlagsByEnv,
  startDebuggingFlag,
  stopDebuggingFlag,
  changeFlagProject,
  changeFlagSelection,
  resetPagination,
  startFlagExperiment,
  stopFlagExperiment,
  createFlagExperimentInterval,
  resetExperiment,
  disconnectFlagMetric,
  updateFlagSemantically,
  addSegmentRule,
  modifySegmentRule,
  discardOffVariationChanges,
  discardPrerequisitesChanges,
  discardTargetingChanges,
  discardCustomRuleChanges,
  discardFallthroughVariationChanges,
  syncFlagWithRestAPI,
  setRuleMeasuredRolloutConfig,
  setFallthroughMeasuredRolloutConfig,
  turnFlagOn,
  discardGuardedRolloutsInstructions,
};

const FlagActionCreators = {
  addVariation,
  changeFlagConfiguration,
  changeFlagProject,
  changeFlagSelection,
  changeVariations,
  checkAccessResource,
  clearOffVariation,
  createExperimentIntervalDone,
  createExperimentIntervalFailed,
  createExperimentIntervalFailedGuidance,
  destroyConfigurationManager,
  destroyVariationManager,
  editEnvironmentFlagSettingsAction,
  editFlag,
  editProjectFlagMaintainerAction,
  editProjectFlagSettingsAction,
  editRuleExclusionAction,
  editVariation,
  initializeConfigurationManager,
  initializeProjectFlagMaintainer,
  initializeVariationManager,
  removeVariation,
  requestDeleteFlagDone,
  requestDependentFlags,
  requestDependentFlagsDone,
  requestDependentFlagsFailed,
  requestDependentFlagsWithEnv,
  requestDependentFlagsWithEnvDone,
  requestDependentFlagsWithEnvFailed,
  requestExperimentSeriesResults,
  requestExperimentSeriesResultsDone,
  requestExperimentSeriesResultsFailed,
  requestExperimentSummaryResults,
  requestExperimentSummaryResultsDone,
  requestExperimentSummaryResultsFailed,
  requestFlag,
  requestFlagDone,
  requestFlagFailed,
  requestFlags,
  requestFlagsDone,
  requestFlagsFailed,
  requestFlagsAborted,
  requestFlagStatus,
  requestFlagStatusDone,
  requestFlagStatuses,
  requestFlagStatusesDone,
  requestFlagStatusesFailed,
  requestFlagStatusFailed,
  requestFlagStatusesByEnvKeysAndFlagKeys,
  requestFlagStatusesByEnvKeysAndFlagKeysDone,
  requestFlagStatusesByEnvKeysAndFlagKeysFailed,
  requestGoalResults,
  requestGoalResultsDone,
  requestGoalResultsFailed,
  requestResetExperiment,
  requestResetExperimentDone,
  requestResetExperimentFailed,
  resetConfiguration,
  resetPagination,
  resetVariations,
  selectOffVariation,
  startExperimentRequest,
  startExperimentRequestDone,
  startExperimentRequestFailed,
  stopExperimentRequest,
  stopExperimentRequestDone,
  stopExperimentRequestFailed,
  updateEnvironmentFlagSettingsRequest,
  updateEnvironmentFlagSettingsRequestDone,
  updateEnvironmentFlagSettingsRequestFailed,
  updateFlagDebug,
  updateFlagDebugDone,
  updateFlagDebugFailed,
  updateFlagGoalsRequest,
  updateFlagGoalsRequestDone,
  updateFlagGoalsRequestFailed,
  updateFlagMaintainerRequest,
  updateFlagMaintainerRequestDone,
  updateFlagMaintainerRequestFailed,
  updateFlagRequest,
  updateFlagRequestDone,
  updateFlagRequestFailed,
  updateProjectFlagSettingsRequest,
  updateProjectFlagSettingsRequestDone,
  updateProjectFlagSettingsRequestFailed,
  updateRuleExclusionRequest,
  updateRuleExclusionRequestDone,
  updateRuleExclusionRequestFailed,
  discardSpecificConfigChanges,
  syncFlagWithRestAPI,
};

export type FlagAction = GenerateActionType<typeof FlagActionCreators>;
