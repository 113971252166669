import { createTrackerForCategory } from '@gonfalon/analytics';

import { LegacyMeasuredRolloutState, MeasuredRolloutState } from './types';

const trackMeasuredRolloutsEvent = createTrackerForCategory('Guarded Rollouts');

const trackAddMetricsClicked = () => trackMeasuredRolloutsEvent('Add Metrics Clicked');
const trackMetricsClicked = () => trackMeasuredRolloutsEvent('Metrics Clicked');
const trackEditMetricsClicked = () => trackMeasuredRolloutsEvent('Edit Metrics Clicked');

const trackRemoveAllMetricsClicked = ({ previousMetrics }: { previousMetrics: string[] }) =>
  trackMeasuredRolloutsEvent('Remove All Metrics Clicked', {
    previousMetrics,
  });

const trackMetricsSaveClicked = ({ metrics, previousMetrics }: { metrics: string[]; previousMetrics?: string[] }) =>
  trackMeasuredRolloutsEvent('Metrics Save Clicked', {
    metrics,
    previousMetrics,
  });

const trackMetricsCancelClicked = () => trackMeasuredRolloutsEvent('Metrics Cancel Clicked');
const trackRandomizationUnitSelected = ({ randomizationUnit }: { randomizationUnit: string }) =>
  trackMeasuredRolloutsEvent('Randomization Unit Selected', { randomizationUnit });
const trackMetricsSelected = ({ metrics }: { metrics: string[] }) =>
  trackMeasuredRolloutsEvent('Metrics Selected', { metrics });
const trackReleaseGuardianAvailableChipShown = () =>
  trackMeasuredRolloutsEvent('Release Guardian Available Chip Shown');
const trackReleaseGuardianAddMetricsSuggestionShown = () =>
  trackMeasuredRolloutsEvent('Release Guardian Add Metrics Suggestion Shown');
const trackReleaseStrategyShown = () => trackMeasuredRolloutsEvent('Release Strategy Shown');
const trackReleaseStrategyClosed = () => trackMeasuredRolloutsEvent('Release Strategy Closed');
const trackReleaseStrategySkipped = () => trackMeasuredRolloutsEvent('Release Strategy Skipped');
const trackReleaseStrategyRuleSelected = ({ rule }: { rule: string }) =>
  trackMeasuredRolloutsEvent('Release Strategy Rule Selected', { rule });
const trackReleaseStrategyDurationSelected = ({ duration }: { duration: string }) =>
  trackMeasuredRolloutsEvent('Release Strategy Duration Selected', { duration });
const trackReleaseStrategyRegressionBehaviorSelected = ({ regressionBehavior }: { regressionBehavior: string }) =>
  trackMeasuredRolloutsEvent('Release Strategy Regression Behavior Selected', { regressionBehavior });
const trackReleaseStrategyCustomizeClicked = () => trackMeasuredRolloutsEvent('Release Strategy Customize Clicked');
const trackReleaseStrategyDismissCustomClicked = () =>
  trackMeasuredRolloutsEvent('Release Strategy Dismiss Custom Clicked');
const trackReleaseStrategyNextClicked = ({
  trafficAllocation,
  monitoringDuration,
  alertAction,
  randomizationUnit,
}: {
  trafficAllocation: number;
  monitoringDuration: { value: number; unit: string };
  alertAction: string;
  randomizationUnit?: string;
}) =>
  trackMeasuredRolloutsEvent('Release Strategy Next Clicked', {
    trafficAllocation,
    monitoringDuration,
    randomizationUnit,
    alertAction,
  });
const trackReleaseStrategyBackClicked = () => trackMeasuredRolloutsEvent('Release Strategy Back Clicked');

type Page = 'targeting' | 'insights';
const trackStatusBarViewInsightsClicked = ({
  page,
  state,
}: {
  page: Page;
  state: LegacyMeasuredRolloutState | MeasuredRolloutState;
}) => trackMeasuredRolloutsEvent('Status Bar View Insights Clicked', { page, state });
const trackStatusBarRollBackRuleClicked = ({
  page,
  state,
}: {
  page: Page;
  state: LegacyMeasuredRolloutState | MeasuredRolloutState;
}) => trackMeasuredRolloutsEvent('Status Bar Roll Back Rule Clicked', { page, state });
const trackStatusBarViewResultsClicked = ({
  page,
  state,
}: {
  page: Page;
  state: LegacyMeasuredRolloutState | MeasuredRolloutState;
}) => trackMeasuredRolloutsEvent('Status Bar View Results Clicked', { page, state });
const trackRuleSummaryShown = ({ state }: { state: LegacyMeasuredRolloutState | MeasuredRolloutState }) =>
  trackMeasuredRolloutsEvent('Rule Summary Shown', { state });
const trackRuleSummaryViewInsightsClicked = ({ state }: { state: LegacyMeasuredRolloutState | MeasuredRolloutState }) =>
  trackMeasuredRolloutsEvent('Rule Summary View Insights Clicked', { state });
const trackRuleSummaryStopMonitoringClicked = ({
  state,
}: {
  state: LegacyMeasuredRolloutState | MeasuredRolloutState;
}) => trackMeasuredRolloutsEvent('Rule Summary Stop Monitoring Clicked', { state });
const trackRuleSummaryViewResultsClicked = ({ state }: { state: LegacyMeasuredRolloutState | MeasuredRolloutState }) =>
  trackMeasuredRolloutsEvent('Rule Summary View Results Clicked', { state });
const trackTargetingSaveButtonClickedWhenPromptedForMonitoring = () => {
  trackMeasuredRolloutsEvent('Targeting Save Button Clicked When Prompted For Monitoring');
};

const trackGuardedRolloutQuickActionClicked = () => trackMeasuredRolloutsEvent('Guarded Rollout Quick Actions Clicked');
const trackGuardedRolloutCustomThresholdsClicked = () =>
  trackMeasuredRolloutsEvent('Guarded Rollout Custom Thresholds Clicked');
const trackGuardedRolloutDurationSelected = ({ duration }: { duration: string }) =>
  trackMeasuredRolloutsEvent('Guarded Rollout Duration Selected', { duration });
const trackGuardedRolloutMonitoringTabClicked = () =>
  trackMeasuredRolloutsEvent('Guarded Rollout Monitoring Tab Clicked');
const trackGuardedRolloutStarted = () => trackMeasuredRolloutsEvent('Guarded Rollout Started');
const trackGuardedRolloutStoppedRolledForward = () =>
  trackMeasuredRolloutsEvent('Guarded Rollout Stopped and Rolled Forward');
const trackGuardedRolloutStoppedRolledBack = () =>
  trackMeasuredRolloutsEvent('Guarded Rollout Stopped and Rolled Back');
const trackGuardedRolloutOnRuleClicked = () => trackMeasuredRolloutsEvent('Guarded Rollout On Rule Clicked');
const trackGuardianPagerDutyConfigured = () => trackMeasuredRolloutsEvent('Guardian PagerDuty Configured');
const trackGuardedRolloutsRegressionDismissed = () =>
  trackMeasuredRolloutsEvent('Guarded Rollouts Regression Dismissed');
const trackGuardedRolloutViewed = () => trackMeasuredRolloutsEvent('Guarded Rollout Viewed');

const trackRequestTrialButtonClicked = () =>
  trackMeasuredRolloutsEvent('Guarded Rollout Upsell Request Trial Button Clicked');
const trackReadBlogPostButtonClicked = () =>
  trackMeasuredRolloutsEvent('Guarded Rollout Upsell Read Blog Post Button Clicked');
const trackLearnMoreButtonClicked = () =>
  trackMeasuredRolloutsEvent('Guarded Rollout Empty State Learn More Button Clicked');

export {
  trackGuardedRolloutQuickActionClicked,
  trackGuardedRolloutCustomThresholdsClicked,
  trackGuardedRolloutDurationSelected,
  trackGuardedRolloutMonitoringTabClicked,
  trackGuardedRolloutStarted,
  trackGuardedRolloutStoppedRolledForward,
  trackGuardedRolloutStoppedRolledBack,
  trackGuardedRolloutOnRuleClicked,
  trackGuardianPagerDutyConfigured,
  trackGuardedRolloutsRegressionDismissed,
  trackAddMetricsClicked,
  trackMetricsClicked,
  trackEditMetricsClicked,
  trackRemoveAllMetricsClicked,
  trackMetricsSaveClicked,
  trackMetricsCancelClicked,
  trackRandomizationUnitSelected,
  trackMetricsSelected,
  trackReleaseGuardianAvailableChipShown,
  trackReleaseGuardianAddMetricsSuggestionShown,
  trackReleaseStrategyShown,
  trackReleaseStrategyClosed,
  trackReleaseStrategySkipped,
  trackReleaseStrategyRuleSelected,
  trackReleaseStrategyDurationSelected,
  trackReleaseStrategyCustomizeClicked,
  trackReleaseStrategyDismissCustomClicked,
  trackReleaseStrategyRegressionBehaviorSelected,
  trackReleaseStrategyNextClicked,
  trackReleaseStrategyBackClicked,
  trackStatusBarViewInsightsClicked,
  trackStatusBarRollBackRuleClicked,
  trackStatusBarViewResultsClicked,
  trackRuleSummaryShown,
  trackRuleSummaryViewInsightsClicked,
  trackRuleSummaryStopMonitoringClicked,
  trackRuleSummaryViewResultsClicked,
  trackTargetingSaveButtonClickedWhenPromptedForMonitoring,
  trackGuardedRolloutViewed,
  trackRequestTrialButtonClicked,
  trackReadBlogPostButtonClicked,
  trackLearnMoreButtonClicked,
};
