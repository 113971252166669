import { AnalyticEventData } from '@gonfalon/analytics';
import { isExperimentArchivingEnabled } from '@gonfalon/dogfood-flags';
import { Experiment as ExperimentNew, Iteration as IterationNew, Layer, LayerSnapshot } from '@gonfalon/experiments';

import {
  Experiment,
  ExperimentType,
  ExperimentV2,
  Flag,
  Iteration,
  NOT_STARTED,
  STOPPED,
  Treatment,
} from 'components/experimentation/common/types';
import { AnalyticAttributes, GetComponentAnalyticsArgs } from 'utils/analyticsUtils';
import { colorVariation } from 'utils/flagUtils';

export function getExperimentStartDate(experiment: Experiment, environmentKey: string) {
  const environment = experiment._environmentSettings[environmentKey];
  return environment?.startDate;
}

export function experimentHasStartDate(experiment: Experiment, environmentKey: string) {
  return getExperimentStartDate(experiment, environmentKey) !== undefined;
}

export function getExperimentStopDate(experiment: Experiment, environmentKey: string) {
  const environment = experiment._environmentSettings[environmentKey];
  return environment?.stopDate;
}

export function experimentHasStopDate(experiment: Experiment, environmentKey: string) {
  return getExperimentStopDate(experiment, environmentKey) !== undefined;
}

export function isExperimentActive(experiment: Experiment, environmentKey: string) {
  return experiment.environments.find((env) => env === environmentKey) !== undefined;
}

export function isExperimentArchived(experiment: ExperimentV2) {
  return isExperimentArchivingEnabled() && experiment.archivedDate !== undefined;
}

export function isExperimentPristine(experiment: Experiment, environmentKey: string) {
  return !isExperimentActive(experiment, environmentKey) && !experimentHasStartDate(experiment, environmentKey);
}

export function isExperimentPaused(experiment: Experiment, environmentKey: string) {
  return !isExperimentActive(experiment, environmentKey) && experimentHasStartDate(experiment, environmentKey);
}

export function isExportOnlyExperiment(experiment?: ExperimentV2 | ExperimentNew): boolean {
  return experiment?.methodology === 'export_only';
}

export function isSnowflakeExperiment(experiment?: ExperimentV2 | ExperimentNew): boolean {
  return experiment?.dataSource === 'snowflake';
}

export function getExperimentType(experiment: ExperimentV2 | ExperimentNew | undefined): ExperimentType {
  if (isExportOnlyExperiment(experiment)) {
    return 'export_only';
  }

  if (isSnowflakeExperiment(experiment)) {
    return 'snowflake_native';
  }

  return experiment?.currentIteration?.primaryFunnel ? 'funnel' : 'feature';
}

// converts an iteration and flag to a rollout
// `defaultVariation` is the flag variation index of the control treatment
// `variationWeights` is reduced from treatments, so flag variations not part
// of the experiment will not be included
export function iterationToRollout(iteration: Iteration, flag: Flag) {
  const baseline = iteration.treatments.find((t) => t.baseline);
  const [onlyParameter] = baseline?.parameters ?? [];
  const vIdx = flag.variations.findIndex((v) => v._id === onlyParameter?.variationId);
  return {
    canReshuffle: iteration.canReshuffleTraffic,
    defaultVariation: vIdx > -1 ? vIdx : 0,
    variationWeights: iteration.treatments.reduce(
      (acc, t) => ({
        ...acc,
        [t.parameters?.[0]?.variationId ?? '']: Number(t.allocationPercent) * 1000,
      }),
      {},
    ),
  };
}

export function getExperimentVariationWeights(iteration: Iteration, flag: Flag) {
  const { treatments } = iteration;
  const flagVariations = flag.variations;
  const treatmentsByVariation = treatmentsByVariationId(treatments ?? []);

  return flagVariations.reduce(
    (acc, v) => {
      const treatment = treatmentsByVariation[v._id];
      return {
        ...acc,
        [v._id]: treatment ? Number(treatment.allocationPercent) * 1000 : 0,
      };
    },
    {} as Record<string, number>,
  );
}

// For each treatment in each experiment iteration, assign a treatment color
// Will use the treatment parameter's corresponding flag variation color if it exists
export function attachTreatmentColors(
  experiment: ExperimentV2 | undefined,
  flag: Flag | undefined,
): ExperimentV2 | undefined {
  if (!experiment || !flag) {
    return experiment;
  }

  const colorByVariation = flag.variations.reduce(
    (acc, v, i) => ({
      ...acc,
      [v._id]: colorVariation(i),
    }),
    {} as Record<string, string>,
  );

  const allIterations = [
    experiment.currentIteration,
    experiment?.draftIteration,
    ...(experiment?.previousIterations ?? []),
  ];
  for (const itr of allIterations) {
    if (!itr) {
      continue;
    }
    itr.treatments = itr.treatments.map((t, i) => {
      const [onlyParameter] = t?.parameters ?? [];

      // if the variation color doesn't exist on flag (variation was deleted but still exists on experiment)
      // then assign a new color that isn't assigned to a variation yet
      const _color = onlyParameter?.variationId
        ? colorByVariation[onlyParameter?.variationId]
        : colorVariation(flag.variations.length + i);
      return { ...t, _color };
    });
  }

  return experiment;
}

export function treatmentsByVariationId(treatments: Treatment[]): { [variationId: string]: Treatment | undefined } {
  return treatments.reduce((acc, t) => {
    const [onlyParameter] = t?.parameters ?? []; // we currently only support a single treatment parameter

    if (!onlyParameter || !onlyParameter.variationId) {
      return acc;
    }
    return {
      ...acc,
      [onlyParameter.variationId]: t,
    };
  }, {});
}

export function getFlagConfigVersion(iteration: Iteration | undefined, flagKey: string): number | undefined {
  if (iteration?.flags[flagKey]) {
    return iteration.flags[flagKey].flagConfigVersion;
  }
}

// get the iteration that should be visible on screen
export function getVisibleIteration({ currentIteration, draftIteration }: ExperimentV2) {
  const { status } = currentIteration;

  if (status === NOT_STARTED || status === STOPPED) {
    return draftIteration ?? currentIteration;
  }

  return currentIteration;
}

export function getFlagKeysForIteration(iteration: Iteration) {
  return Object.keys(iteration?.flags ?? {});
}

export function getLayerReservation(layer: Layer, experimentKey: string, environmentKey: string): number {
  const reservations = (layer.environments && layer.environments[environmentKey]?.reservations) ?? [];
  return reservations.find((res) => res.experimentKey === experimentKey)?.reservationPercent ?? 0;
}

export function layerToSnapshot(layer: Layer, environmentKey: string, experimentKey: string): LayerSnapshot {
  const reservations = (layer.environments && layer.environments[environmentKey]?.reservations) ?? [];
  return reservations.reduce<LayerSnapshot>(
    (acc, r) => {
      if (r.experimentKey === experimentKey) {
        return {
          ...acc,
          reservationPercent: r.reservationPercent,
        };
      }
      return {
        ...acc,
        otherReservationPercent: acc.otherReservationPercent + r.reservationPercent,
      };
    },
    { name: layer.name, key: layer.key, reservationPercent: 0, otherReservationPercent: 0 },
  );
}

// returns the primary metric key for an iteration
// if the primary metric is a group, returns the key for the last metric in the group
// This is a temporary solution so features such as the Traffic Table, SRM alerts, and Sample Size Estimator
// can function by fetching results for a single metric.
export function mustGetPrimaryMetricKey(iteration: Iteration | IterationNew | undefined | null) {
  return iteration?.primarySingleMetric?.key ?? iteration?.primaryFunnel?.metrics?.slice(-1)?.[0]?.key ?? '';
}

export function updateExperimentArchiveStatus({
  isArchived,
  experimentQuery,
  trackExperimentEvent,
}: {
  isArchived: boolean | undefined;
  experimentQuery: { archive(): void; restore(): void; experiment?: ExperimentV2 };
  trackExperimentEvent: (eventName: string, eventProperties: Record<string, unknown>) => void;
}) {
  if (isArchived) {
    restoreExperiment({ experimentQuery, trackExperimentEvent });
  } else {
    experimentQuery.archive();
    trackExperimentEvent('Experiment Details Experiment Archived', {
      experimentStatus: experimentQuery.experiment?.currentIteration.status,
    });
  }
}

export function restoreExperiment({
  experimentQuery,
  trackExperimentEvent,
}: {
  experimentQuery: { archive(): void; restore(): void; experiment?: ExperimentV2 };
  trackExperimentEvent: (eventName: string, eventProperties: Record<string, unknown>) => void;
}) {
  experimentQuery.restore();
  trackExperimentEvent('Experiment Details Experiment Restored', {
    experimentStatus: experimentQuery.experiment?.currentIteration.status,
  });
}

export function handleStart({
  trackExperimentEvent,
  toggleModal,
}: {
  trackExperimentEvent: (event: string, attr?: AnalyticEventData) => void;
  toggleModal: () => void;
}) {
  trackExperimentEvent('Experiment Action Area Start Button Clicked');
  toggleModal();
}

export function handleStop({
  enablePostExperimentationExperience,
  trackPostAccessExperimentActionBtnClicked,
  getComponentAnalytics,
  toggleModal,
}: {
  enablePostExperimentationExperience: boolean;
  trackPostAccessExperimentActionBtnClicked: (analyticAttributes: AnalyticAttributes) => void;
  getComponentAnalytics: ({ component, cta, type }: GetComponentAnalyticsArgs) => AnalyticAttributes;
  toggleModal: () => void;
}) {
  if (enablePostExperimentationExperience) {
    trackPostAccessExperimentActionBtnClicked(
      getComponentAnalytics({
        component: 'ExperimentActionArea',
        cta: 'Stop',
        type: 'button',
      }),
    );
  }

  toggleModal();
}
