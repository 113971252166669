import { type JSX } from 'react';
import { getFlagVariationIndexFromId, getFlagVariationValueString } from '@gonfalon/flags';
import { toFlagTargetingConflict, toFlagVariationConflict, toHref } from '@gonfalon/navigator';
import { capitalize } from '@gonfalon/strings';
import { getVariationName } from 'ia-poc/services/flag-creation/utils';
import { List } from 'immutable';
import { Box } from 'launchpad';
import invariant from 'tiny-invariant';

import { ExpiringTargetsInstructionEntry } from 'components/InstructionList/ExpiringUserTargetsInstructionEntry';
import PrerequisiteEntryContainer from 'components/InstructionList/PrerequisiteEntryContainer';
import { RuleDescriptionKind, RuleInstructionDescription } from 'components/InstructionList/RuleInstructionDescription';
import OnOffChip from 'components/OnOffChip';
import Variation from 'components/Variation';
import { Clause, createClause } from 'utils/clauseUtils';
import { USER_CONTEXT_KIND } from 'utils/constants';
import { ContextTargetingExpirationInstruction } from 'utils/expiringContextTargetsUtils';
import { colorVariation, Flag, replaceRuleLabelText, Rule } from 'utils/flagUtils';
import { ClauseInstructionKind } from 'utils/instructions/clauses/types';
import { ExpiringTargetsInstructionKind } from 'utils/instructions/expiringTargets/types';
import { OnOffInstructionKind } from 'utils/instructions/onOff/types';
import { FlagPrerequisitesInstructionKind } from 'utils/instructions/prerequisites/types';
import { ProgressiveRolloutInstructionKind } from 'utils/instructions/progressive-rollouts/types';
import {
  convertRuleToAggregateRule,
  createAggregateRule,
  filterOutAggregateRules,
  sortRulesByTargetingOrder,
} from 'utils/instructions/rules/aggregateHelpers';
import { AggregateRule, RuleInstructionKind } from 'utils/instructions/rules/types';
import { getInstructionDescriptionText, isInstructionOfKind } from 'utils/instructions/shared/helpers';
import { SemanticInstruction } from 'utils/instructions/shared/types';
import { hasEmptyTargets } from 'utils/instructions/targets/helpers';
import { TargetsInstructionKind } from 'utils/instructions/targets/types';
import { hasEmptyUserTargets } from 'utils/instructions/userTargets/helpers';
import { UserTargetsInstructionKind } from 'utils/instructions/userTargets/types';
import { AddVariationSemanticInstruction, FlagVariationsInstructionKind } from 'utils/instructions/variations/types';

import { AddVariationInstructionEntry } from './AddVariationInstructionEntry';
import { ClauseValueInstructionEntry } from './ClauseValueInstructionEntry';
import { CollapsibleInstructionListItem } from './CollapsibleInstructionListItem';
import { DefaultVariationInstructionEntry, VariationInfo } from './DefaultVariationInstructionEntry';
import { InstructionListItem } from './InstructionListItem';
import {
  InstructionKindToInstructionListCategory,
  InstructionListCategory,
  InstructionListChangeKind,
  InstructionListSubCategory,
  makeInstructionElemsByCategory,
  makeInstructionElemsByRuleId,
  RULE_NOT_FOUND,
  TARGET_DISPLAY_LIMIT,
} from './instructionListUtils';
import { MeasuredRolloutRuleInstructionDescription } from './MeasuredRolloutRuleInstructionDescription';
import { NestedInstructionListItem } from './NestedInstructionListItem';
import { ProgressiveRolloutConfigurationDescription } from './ProgressiveRolloutConfigurationDescription';
import { RemoveVariationInstructionEntry } from './RemoveVariationInstructionEntry';
import { StopProgressiveRolloutInstructionEntry } from './StopProgressiveRolloutInstructionEntry';
import TargetEntryContainer from './TargetEntryContainer';
import { UpdateVariationInstructionEntry } from './UpdateVariationInstructionEntry';
import { VariationInstructionEntry } from './VariationInstructionEntry';
import {
  StopMeasuredRolloutInstructionEntry,
  VariationOrRolloutInstructionEntry,
} from './VariationOrRolloutInstructionEntry';

import './styles.css';
import defaultVariationStyles from './DefaultVariationInstructionEntry.module.css';

export type CollapsibleInstructionListProps = {
  instructions: Array<SemanticInstruction | undefined>;
  flag: Flag;
  projKey: string;
  envKey: string;
  expandTargetList?: boolean;
  shouldShowConflictLinks?: boolean;
  hideCategoryHeaders?: boolean;
  onExit?: () => void;
  isApprovalRequest?: boolean;
};

export function CollapsibleInstructionList({
  instructions,
  flag,
  projKey,
  envKey,
  expandTargetList,
  shouldShowConflictLinks,
  hideCategoryHeaders,
  onExit,
  isApprovalRequest,
}: CollapsibleInstructionListProps) {
  const instructionElemsByCategory = makeInstructionElemsByCategory();
  const filteredIns = instructions.filter((ins): ins is SemanticInstruction => !!ins);
  const editRuleInstructionElemsByRuleId = makeInstructionElemsByRuleId(filteredIns);
  const sortedRules = sortRulesByTargetingOrder({ flag, envKey, instructions: filteredIns });
  const ruleLabel = flag.getRuleLabel();

  const renderInstructionsWithCategories = (categoriesToExpand: string[] = []) => {
    const categoriesWithInstructions = Object.keys(instructionElemsByCategory).filter(
      (category) => instructionElemsByCategory[category as InstructionListCategory].length,
    );
    return categoriesWithInstructions.map((category, index) => {
      const categoryInsElems = instructionElemsByCategory[category as InstructionListCategory];
      const isCategoryExpanded = isApprovalRequest || index === 0 || categoriesToExpand.includes(category);
      return (
        <CollapsibleInstructionListItem
          key={category}
          hideCategoryHeaders={hideCategoryHeaders}
          initialOpen={isCategoryExpanded}
          categoryHeader={replaceRuleLabelText(category, ruleLabel)}
          categoryInsElems={categoryInsElems}
        />
      );
    });
  };

  const categorizeInstruction = ({
    category,
    insElem,
    shouldAddElemToFront,
  }: {
    category: InstructionListCategory;
    insElem: JSX.Element;
    shouldAddElemToFront?: boolean;
  }) => {
    shouldAddElemToFront
      ? instructionElemsByCategory[category].unshift(insElem)
      : instructionElemsByCategory[category].push(insElem);
  };

  const categorizeEditRuleInstruction = ({ ruleId, insElem }: { ruleId: string; insElem: JSX.Element }) => {
    editRuleInstructionElemsByRuleId[ruleId].push(insElem);
  };

  const categoriesToExpand: string[] = [];
  instructions.forEach((semanticInstruction, instructionIndex) => {
    if (!semanticInstruction) {
      return;
    }

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    const flagTab = instructions
      .filter(Boolean)
      .every((ins) => Object.values(FlagVariationsInstructionKind).includes(ins!.kind as FlagVariationsInstructionKind))
      ? 'variations'
      : 'targeting'; /* eslint-enable @typescript-eslint/no-non-null-assertion */
    const linker = flagTab === 'targeting' ? toFlagTargetingConflict : toFlagVariationConflict;
    const conflictLink = shouldShowConflictLinks
      ? toHref(
          linker({
            projectKey: projKey,
            environmentKey: envKey,
            flagKey: flag.key,
            conflictIndex: String(instructionIndex),
          }),
        )
      : undefined;
    const description = getInstructionDescriptionText(semanticInstruction, ruleLabel);
    const ruleId =
      filterOutAggregateRules(semanticInstruction.kind) || semanticInstruction.kind === RuleInstructionKind.REMOVE_RULE
        ? (semanticInstruction as AggregateRule).ruleId
        : '';

    switch (semanticInstruction.kind) {
      case OnOffInstructionKind.TURN_FLAG_ON:
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.CHANGE}
              onExit={onExit}
            >
              <OnOffChip isOn />
            </InstructionListItem>
          ),
        });
      case OnOffInstructionKind.TURN_FLAG_OFF:
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.CHANGE}
              onExit={onExit}
            >
              <OnOffChip isOn={false} />
            </InstructionListItem>
          ),
        });
      case FlagPrerequisitesInstructionKind.ADD_PREREQUISITE:
      case FlagPrerequisitesInstructionKind.UPDATE_PREREQUISITE:
      case FlagPrerequisitesInstructionKind.REMOVE_PREREQUISITE:
        let prerequisteChangeKind;
        if (semanticInstruction.kind === FlagPrerequisitesInstructionKind.ADD_PREREQUISITE) {
          prerequisteChangeKind = InstructionListChangeKind.ADD;
        } else if (semanticInstruction.kind === FlagPrerequisitesInstructionKind.UPDATE_PREREQUISITE) {
          prerequisteChangeKind = InstructionListChangeKind.CHANGE;
        } else if (semanticInstruction.kind === FlagPrerequisitesInstructionKind.REMOVE_PREREQUISITE) {
          prerequisteChangeKind = InstructionListChangeKind.REMOVE;
        }
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={prerequisteChangeKind}
              onExit={onExit}
            >
              <PrerequisiteEntryContainer
                instructionKind={semanticInstruction.kind}
                flagKey={semanticInstruction.key}
                projKey={projKey}
                envKey={envKey}
                variationId={
                  isInstructionOfKind(semanticInstruction, FlagPrerequisitesInstructionKind.REMOVE_PREREQUISITE)
                    ? undefined
                    : semanticInstruction?.variationId
                }
              />
            </InstructionListItem>
          ),
        });
      case FlagPrerequisitesInstructionKind.REPLACE_PREREQUISITES:
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.CHANGE}
              onExit={onExit}
            >
              {semanticInstruction.prerequisites.length > 0 ? (
                <ul className="InstructionItem-subList">
                  {semanticInstruction.prerequisites.map((prereq, i) => (
                    <li key={i}>
                      <PrerequisiteEntryContainer
                        instructionKind={semanticInstruction.kind}
                        flagKey={prereq.key}
                        projKey={projKey}
                        envKey={envKey}
                        variationId={prereq.variationId}
                      />
                    </li>
                  ))}
                </ul>
              ) : null}
            </InstructionListItem>
          ),
        });
      case UserTargetsInstructionKind.ADD_USER_TARGETS:
      case UserTargetsInstructionKind.REMOVE_USER_TARGETS:
        let userTargetsChangeKind;
        if (semanticInstruction.kind === UserTargetsInstructionKind.ADD_USER_TARGETS) {
          userTargetsChangeKind = InstructionListChangeKind.ADD;
        } else if (semanticInstruction.kind === UserTargetsInstructionKind.REMOVE_USER_TARGETS) {
          userTargetsChangeKind = InstructionListChangeKind.REMOVE;
        }
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={userTargetsChangeKind}
              onExit={onExit}
            >
              <TargetEntryContainer
                instructionKind={semanticInstruction.kind}
                contextKind={USER_CONTEXT_KIND}
                targetKeys={semanticInstruction.values}
                variationId={semanticInstruction.variationId}
                flag={flag}
                targetLimit={expandTargetList ? 0 : TARGET_DISPLAY_LIMIT}
                projKey={projKey}
                envKey={envKey}
              />
            </InstructionListItem>
          ),
        });
      case UserTargetsInstructionKind.REPLACE_USER_TARGETS:
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.CHANGE}
              onExit={onExit}
            >
              {!hasEmptyUserTargets(semanticInstruction) ? (
                <ul className="InstructionItem-subList">
                  {semanticInstruction.targets.map((v, i) => (
                    <li key={i}>
                      <TargetEntryContainer
                        instructionKind={semanticInstruction.kind}
                        contextKind={USER_CONTEXT_KIND}
                        targetKeys={v.values}
                        variationId={v.variationId}
                        flag={flag}
                        targetLimit={expandTargetList ? 0 : TARGET_DISPLAY_LIMIT}
                        projKey={projKey}
                        envKey={envKey}
                      />
                    </li>
                  ))}
                </ul>
              ) : null}
            </InstructionListItem>
          ),
        });
      case TargetsInstructionKind.ADD_TARGETS:
      case TargetsInstructionKind.REMOVE_TARGETS:
        let targetsChangeKind;
        if (semanticInstruction.kind === TargetsInstructionKind.ADD_TARGETS) {
          targetsChangeKind = InstructionListChangeKind.ADD;
        } else if (semanticInstruction.kind === TargetsInstructionKind.REMOVE_TARGETS) {
          targetsChangeKind = InstructionListChangeKind.REMOVE;
        }
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={targetsChangeKind}
              onExit={onExit}
            >
              <TargetEntryContainer
                instructionKind={semanticInstruction.kind}
                contextKind={semanticInstruction.contextKind}
                targetKeys={semanticInstruction.values}
                variationId={semanticInstruction.variationId}
                flag={flag}
                targetLimit={expandTargetList ? 0 : TARGET_DISPLAY_LIMIT}
                projKey={projKey}
                envKey={envKey}
              />
            </InstructionListItem>
          ),
        });
      case TargetsInstructionKind.REPLACE_TARGETS:
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.CHANGE}
              onExit={onExit}
            >
              {!hasEmptyTargets(semanticInstruction) ? (
                <ul className="InstructionItem-subList">
                  {semanticInstruction.targets.map((v, i) => (
                    <li key={i}>
                      <TargetEntryContainer
                        instructionKind={semanticInstruction.kind}
                        contextKind={v.contextKind}
                        targetKeys={v.values}
                        variationId={v.variationId}
                        flag={flag}
                        targetLimit={expandTargetList ? 0 : TARGET_DISPLAY_LIMIT}
                        projKey={projKey}
                        envKey={envKey}
                      />
                    </li>
                  ))}
                </ul>
              ) : null}
            </InstructionListItem>
          ),
        });
      case ExpiringTargetsInstructionKind.UPDATE_EXPIRE_USER_TARGET_DATE:
      case ExpiringTargetsInstructionKind.ADD_EXPIRE_USER_TARGET_DATE:
      case ExpiringTargetsInstructionKind.REMOVE_EXPIRE_USER_TARGET_DATE:
        let expiringTargetsChangeKind;
        if (semanticInstruction.kind === ExpiringTargetsInstructionKind.UPDATE_EXPIRE_USER_TARGET_DATE) {
          expiringTargetsChangeKind = InstructionListChangeKind.CHANGE;
        } else if (semanticInstruction.kind === ExpiringTargetsInstructionKind.ADD_EXPIRE_USER_TARGET_DATE) {
          expiringTargetsChangeKind = InstructionListChangeKind.ADD;
        } else if (semanticInstruction.kind === ExpiringTargetsInstructionKind.REMOVE_EXPIRE_USER_TARGET_DATE) {
          expiringTargetsChangeKind = InstructionListChangeKind.REMOVE;
        }
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={expiringTargetsChangeKind}
              onExit={onExit}
            >
              <ExpiringTargetsInstructionEntry
                expiringIns={semanticInstruction as unknown as ContextTargetingExpirationInstruction}
                projKey={projKey}
              />
            </InstructionListItem>
          ),
        });

      case ExpiringTargetsInstructionKind.UPDATE_EXPIRE_TARGET_DATE:
      case ExpiringTargetsInstructionKind.ADD_EXPIRE_TARGET_DATE:
      case ExpiringTargetsInstructionKind.REMOVE_EXPIRE_TARGET_DATE:
        let expiringContextTargetsChangeKind;
        if (semanticInstruction.kind === ExpiringTargetsInstructionKind.UPDATE_EXPIRE_TARGET_DATE) {
          expiringContextTargetsChangeKind = InstructionListChangeKind.CHANGE;
        } else if (semanticInstruction.kind === ExpiringTargetsInstructionKind.ADD_EXPIRE_TARGET_DATE) {
          expiringContextTargetsChangeKind = InstructionListChangeKind.ADD;
        } else if (semanticInstruction.kind === ExpiringTargetsInstructionKind.REMOVE_EXPIRE_TARGET_DATE) {
          expiringContextTargetsChangeKind = InstructionListChangeKind.REMOVE;
        }
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={expiringContextTargetsChangeKind}
              onExit={onExit}
            >
              <ExpiringTargetsInstructionEntry
                expiringIns={semanticInstruction as ContextTargetingExpirationInstruction}
                projKey={projKey}
              />
            </InstructionListItem>
          ),
        });
      case RuleInstructionKind.REORDER_RULES:
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <NestedInstructionListItem
              subCategoryHeader={replaceRuleLabelText(InstructionListSubCategory.REORDER_RULES, ruleLabel)}
              isOrderedList
              showConflictLink
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.REORDER}
              key={instructionIndex}
              onExit={onExit}
            >
              {sortedRules.map((rule, i) => (
                <InstructionListItem
                  key={i}
                  showConflictLink={false}
                  onExit={onExit}
                  changeKind={InstructionListChangeKind.DECIMAL}
                >
                  <RuleInstructionDescription
                    projKey={projKey}
                    envKey={envKey}
                    kind={RuleDescriptionKind.REORDERED_RULE}
                    rule={rule}
                    showServeVariation
                    variations={flag.variations}
                  />
                </InstructionListItem>
              ))}
            </NestedInstructionListItem>
          ),
        });
      case RuleInstructionKind.REPLACE_RULES:
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.CHANGE}
              onExit={onExit}
            >
              {semanticInstruction.rules.length > 0 ? (
                <ul className="InstructionItem-subList">
                  {semanticInstruction.rules.map((rule, i) => (
                    <li key={i}>
                      <RuleInstructionDescription
                        projKey={projKey}
                        envKey={envKey}
                        rule={rule}
                        showServeVariation
                        variations={flag.variations}
                      />
                    </li>
                  ))}
                </ul>
              ) : null}
            </InstructionListItem>
          ),
        });
      case RuleInstructionKind.ADD_RULE:
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.ADD}
              onExit={onExit}
            >
              {semanticInstruction.progressiveRolloutConfiguration ? (
                <ProgressiveRolloutConfigurationDescription
                  variations={flag.variations.toArray()}
                  progressiveRolloutConfiguration={semanticInstruction.progressiveRolloutConfiguration}
                  rolloutContextKind={semanticInstruction.rolloutContextKind}
                />
              ) : (
                <RuleInstructionDescription
                  projKey={projKey}
                  envKey={envKey}
                  rule={semanticInstruction}
                  showServeVariation
                  variations={flag.variations}
                />
              )}
            </InstructionListItem>
          ),
          shouldAddElemToFront: true,
        });
      case RuleInstructionKind.REMOVE_RULE:
        const rule: Rule | undefined = flag.getRuleById(envKey, ruleId);
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.REMOVE}
              onExit={onExit}
            >
              {rule ? (
                <RuleInstructionDescription
                  projKey={projKey}
                  envKey={envKey}
                  rule={convertRuleToAggregateRule(rule, flag.variations)}
                  showServeVariation
                  variations={flag.variations}
                />
              ) : null}
            </InstructionListItem>
          ),
          shouldAddElemToFront: true,
        });
      case ClauseInstructionKind.ADD_CLAUSES:
      case ClauseInstructionKind.UPDATE_CLAUSE:
        let clauseChangeKind;
        if (semanticInstruction.kind === ClauseInstructionKind.ADD_CLAUSES) {
          clauseChangeKind = InstructionListChangeKind.ADD;
        } else if (semanticInstruction.kind === ClauseInstructionKind.UPDATE_CLAUSE) {
          clauseChangeKind = InstructionListChangeKind.CHANGE;
        }
        return categorizeEditRuleInstruction({
          ruleId,
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={clauseChangeKind}
              onExit={onExit}
            >
              <RuleInstructionDescription
                projKey={projKey}
                envKey={envKey}
                rule={createAggregateRule({
                  ...semanticInstruction,
                  clauses:
                    semanticInstruction.kind === ClauseInstructionKind.ADD_CLAUSES
                      ? // @ts-expect-error clause value here is unknown[] when "createClause" expects ClauseValueType[]
                        semanticInstruction.clauses.map(createClause)
                      : undefined,
                })}
                variations={flag.variations}
              />
            </InstructionListItem>
          ),
        });
      case ClauseInstructionKind.ADD_VALUES_TO_CLAUSE:
      case ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE:
        let clauseValueChangeKind;
        if (semanticInstruction.kind === ClauseInstructionKind.ADD_VALUES_TO_CLAUSE) {
          clauseValueChangeKind = InstructionListChangeKind.ADD;
        } else if (semanticInstruction.kind === ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE) {
          clauseValueChangeKind = InstructionListChangeKind.REMOVE;
        }
        const clauseValueRule: Rule | undefined = flag.getRuleById(envKey, ruleId);
        const clause = clauseValueRule?.get('clauses').find((c: Clause) => c._id === semanticInstruction.clauseId);
        return categorizeEditRuleInstruction({
          ruleId,
          insElem: (
            <InstructionListItem
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={clauseValueChangeKind}
              onExit={onExit}
            >
              <ClauseValueInstructionEntry
                projKey={projKey}
                envKey={envKey}
                clause={clause || new Clause()}
                values={List(semanticInstruction.values)}
                kind={semanticInstruction.kind}
              />
            </InstructionListItem>
          ),
        });
      case ClauseInstructionKind.REMOVE_CLAUSES:
        const clauseRule: Rule | undefined = flag.getRuleById(envKey, ruleId);
        const removedClauses = clauseRule
          ?.get('clauses')
          .filter((d: Clause) => semanticInstruction.clauseIds.includes(d._id));
        return categorizeEditRuleInstruction({
          ruleId,
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.REMOVE}
              onExit={onExit}
            >
              <RuleInstructionDescription
                projKey={projKey}
                envKey={envKey}
                rule={clauseRule ? createAggregateRule({ clauses: removedClauses?.toArray() }) : clauseRule}
                variations={flag.variations}
              />
            </InstructionListItem>
          ),
        });
      case RuleInstructionKind.UPDATE_RULE_DESCRIPTION:
        const { description: ruleDescription } = semanticInstruction;
        return categorizeEditRuleInstruction({
          ruleId,
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.CHANGE}
              onExit={onExit}
            >
              <span>
                to <span className="u-fw-semibold">{ruleDescription}</span>
              </span>
            </InstructionListItem>
          ),
        });
      case RuleInstructionKind.STOP_MEASURED_ROLLOUT_ON_RULE:
        categoriesToExpand.push(InstructionKindToInstructionListCategory[semanticInstruction.kind]);
        return categorizeEditRuleInstruction({
          ruleId,
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.CHANGE}
              onExit={onExit}
            >
              <StopMeasuredRolloutInstructionEntry instruction={semanticInstruction} flag={flag} />
            </InstructionListItem>
          ),
        });
      case OnOffInstructionKind.STOP_MEASURED_ROLLOUT_ON_FALLTHROUGH:
        categoriesToExpand.push(InstructionKindToInstructionListCategory[semanticInstruction.kind]);
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.CHANGE}
              onExit={onExit}
            >
              <StopMeasuredRolloutInstructionEntry instruction={semanticInstruction} flag={flag} />
            </InstructionListItem>
          ),
        });
      case RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT:
        return categorizeEditRuleInstruction({
          ruleId,
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.CHANGE}
              onExit={onExit}
            >
              {semanticInstruction.progressiveRolloutConfiguration ? (
                <ProgressiveRolloutConfigurationDescription
                  variations={flag.variations.toArray()}
                  progressiveRolloutConfiguration={semanticInstruction.progressiveRolloutConfiguration}
                  rolloutContextKind={semanticInstruction.rolloutContextKind}
                />
              ) : (
                <VariationOrRolloutInstructionEntry flag={flag} instruction={semanticInstruction} />
              )}
            </InstructionListItem>
          ),
        });
      case OnOffInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT:
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.CHANGE}
              onExit={onExit}
            >
              {semanticInstruction.progressiveRolloutConfiguration ? (
                <ProgressiveRolloutConfigurationDescription
                  variations={flag.variations.toArray()}
                  progressiveRolloutConfiguration={semanticInstruction.progressiveRolloutConfiguration}
                  rolloutContextKind={semanticInstruction.rolloutContextKind}
                />
              ) : (
                <VariationOrRolloutInstructionEntry flag={flag} instruction={semanticInstruction} />
              )}
            </InstructionListItem>
          ),
        });
      case OnOffInstructionKind.UPDATE_OFF_VARIATION:
        const { variationId: offVariationId } = semanticInstruction;
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              description={offVariationId === null ? 'Clear off variation' : description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.CHANGE}
              onExit={onExit}
            >
              {offVariationId === null ? undefined : (
                <VariationInstructionEntry flag={flag} variationId={offVariationId} />
              )}
            </InstructionListItem>
          ),
        });
      case FlagVariationsInstructionKind.ADD_VARIATION:
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.ADD}
              onExit={onExit}
            >
              <AddVariationInstructionEntry
                variationType={flag.getVariationType()}
                value={getFlagVariationValueString(semanticInstruction.value)}
                name={semanticInstruction.name}
                description={semanticInstruction.description}
              />
            </InstructionListItem>
          ),
        });
      case FlagVariationsInstructionKind.REMOVE_VARIATION: {
        const variationFromFlag = flag.variations.get(flag.getVariationIndexFromId(semanticInstruction.variationId));
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.REMOVE}
              onExit={onExit}
            >
              <RemoveVariationInstructionEntry
                variationType={flag.getVariationType()}
                isVariationNotFound={!variationFromFlag}
                value={variationFromFlag ? getFlagVariationValueString(variationFromFlag.value) : ''}
              />
            </InstructionListItem>
          ),
        });
      }
      case FlagVariationsInstructionKind.UPDATE_VARIATION: {
        const variationIndex = flag.getVariationIndexFromId(semanticInstruction.variationId);
        const variationFromFlag = variationIndex < 0 ? undefined : flag.variations.get(variationIndex);
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.CHANGE}
              onExit={onExit}
            >
              <UpdateVariationInstructionEntry
                variationType={flag.getVariationType()}
                currentVariationName={variationFromFlag?.name}
                isVariationNotFound={!variationFromFlag}
                name={
                  'name' in semanticInstruction
                    ? {
                        current: variationFromFlag?.name,
                        updated: semanticInstruction.name,
                      }
                    : undefined
                }
                description={
                  'description' in semanticInstruction
                    ? {
                        current: variationFromFlag?.description,
                        updated: semanticInstruction.description,
                      }
                    : undefined
                }
                value={
                  'value' in semanticInstruction
                    ? {
                        current: variationFromFlag ? getFlagVariationValueString(variationFromFlag.value) : '',
                        updated:
                          semanticInstruction.value !== undefined
                            ? getFlagVariationValueString(semanticInstruction.value)
                            : undefined,
                      }
                    : undefined
                }
              />
            </InstructionListItem>
          ),
        });
      }
      case FlagVariationsInstructionKind.UPDATE_DEFAULT_VARIATION:
        const onVariationInfo: VariationInfo = {
          index: -1,
          displayValue: '',
        };
        const offVariationInfo: VariationInfo = {
          index: -1,
          displayValue: '',
        };

        if (semanticInstruction.onVariationValue !== undefined) {
          const isVariationWithValueBeingRemoved = instructions.some(
            (ins) =>
              ins &&
              ins.kind === FlagVariationsInstructionKind.REMOVE_VARIATION &&
              flag.variations.some(
                (fv) => fv._id === ins.variationId && fv.value === semanticInstruction.onVariationValue,
              ),
          );
          const newVariationInstructionWithValue = instructions.find(
            (ins) =>
              ins &&
              ins.kind === FlagVariationsInstructionKind.ADD_VARIATION &&
              ins.value === semanticInstruction.onVariationValue,
          ) as AddVariationSemanticInstruction | undefined;

          if (isVariationWithValueBeingRemoved && newVariationInstructionWithValue) {
            onVariationInfo.displayValue =
              newVariationInstructionWithValue.name ||
              getFlagVariationValueString(newVariationInstructionWithValue.value);
          } else {
            onVariationInfo.index = flag.getVariationIndexFromValue(semanticInstruction.onVariationValue);
            const existingVariation =
              onVariationInfo.index > -1 ? flag.variations.get(onVariationInfo.index) : undefined;
            onVariationInfo.displayValue =
              existingVariation?.getDisplay() || getFlagVariationValueString(semanticInstruction.onVariationValue);
          }
        }

        if (semanticInstruction.offVariationValue !== undefined) {
          const isVariationWithValueBeingRemoved = instructions.some(
            (ins) =>
              ins &&
              ins.kind === FlagVariationsInstructionKind.REMOVE_VARIATION &&
              flag.variations.some(
                (fv) => fv._id === ins.variationId && fv.value === semanticInstruction.offVariationValue,
              ),
          );
          const newVariationInstructionWithValue = instructions.find(
            (ins) =>
              ins &&
              ins.kind === FlagVariationsInstructionKind.ADD_VARIATION &&
              ins.value === semanticInstruction.offVariationValue,
          ) as AddVariationSemanticInstruction | undefined;

          if (isVariationWithValueBeingRemoved && newVariationInstructionWithValue) {
            offVariationInfo.displayValue =
              newVariationInstructionWithValue.name ||
              getFlagVariationValueString(newVariationInstructionWithValue.value);
          } else {
            offVariationInfo.index = flag.getVariationIndexFromValue(semanticInstruction.offVariationValue);
            const existingVariation =
              offVariationInfo.index > -1 ? flag.variations.get(offVariationInfo.index) : undefined;
            offVariationInfo.displayValue =
              existingVariation?.getDisplay() || getFlagVariationValueString(semanticInstruction.offVariationValue);
          }
        }

        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              className={defaultVariationStyles.container}
              description={description}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.CHANGE}
              onExit={onExit}
            >
              <DefaultVariationInstructionEntry
                variationType={flag.getVariationType()}
                onVariation={semanticInstruction.onVariationValue !== undefined ? onVariationInfo : undefined}
                offVariation={semanticInstruction.offVariationValue !== undefined ? offVariationInfo : undefined}
              />
            </InstructionListItem>
          ),
        });
      case RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT:
      case RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT_V2:
      case OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT:
      case OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT_V2:
      case RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT:
      case RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT_V2: {
        const variationIndex = getFlagVariationIndexFromId([...flag.variations], semanticInstruction.testVariationId);
        const variationName = getVariationName([...flag.variations], variationIndex);

        const renderVariation = () => <Variation color={colorVariation(variationIndex)} value={variationName} />;

        const customDescription = (
          <Box width="100%">
            <Box>{description}</Box>
            <Box display="flex" gap="$200">
              Progressively rollout {renderVariation()} by context <code>{semanticInstruction.randomizationUnit}</code>
            </Box>
          </Box>
        );

        categoriesToExpand.push(InstructionKindToInstructionListCategory[semanticInstruction.kind]);

        /* For the time being, we need to support both V1 and V2 add rule with measured rollout instructions */
        const addRuleWithMeasuredRolloutInstruction =
          semanticInstruction.kind === RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT ||
          semanticInstruction.kind === RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT_V2;

        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              description={customDescription}
              key={instructionIndex}
              conflictLink={conflictLink}
              changeKind={InstructionListChangeKind.CHANGE}
              onExit={onExit}
            >
              {addRuleWithMeasuredRolloutInstruction && (
                <RuleInstructionDescription
                  projKey={projKey}
                  envKey={envKey}
                  rule={semanticInstruction as AggregateRule}
                  variations={flag.variations}
                />
              )}
              <MeasuredRolloutRuleInstructionDescription
                semanticInstruction={semanticInstruction}
                variations={flag.variations}
              />
            </InstructionListItem>
          ),
          shouldAddElemToFront: true,
        });
      }
      case ProgressiveRolloutInstructionKind.STOP_PROGRESSIVE_ROLLOUT: {
        return categorizeInstruction({
          category: InstructionKindToInstructionListCategory[semanticInstruction.kind],
          insElem: (
            <InstructionListItem
              description={description}
              key={instructionIndex}
              changeKind={InstructionListChangeKind.REMOVE}
              onExit={onExit}
            >
              <StopProgressiveRolloutInstructionEntry
                flag={flag}
                environmentKey={envKey}
                ruleIdOrFallthrough={semanticInstruction.ruleId}
                endVariationId={semanticInstruction.endVariationId}
              />
            </InstructionListItem>
          ),
        });
      }
      default:
        return;
    }
  });

  Object.keys(editRuleInstructionElemsByRuleId).forEach((ruleId) => {
    if (editRuleInstructionElemsByRuleId[ruleId].length === 0) {
      return;
    }
    const ruleIdx = sortedRules.findIndex((rule) => rule && rule.ruleId === ruleId);
    const ruleNumber = ruleIdx + 1;
    const rule = sortedRules[ruleIdx];
    let ruleDescription = rule ? rule.description || `${capitalize(ruleLabel)} ${ruleNumber}` : RULE_NOT_FOUND;
    if (rule?.experimentAllocation && rule?.experimentAllocation?.type !== 'measuredRollout') {
      ruleDescription = 'experiment';
    }
    const insElem = (
      <NestedInstructionListItem
        subCategoryHeader={replaceRuleLabelText(InstructionListSubCategory.EDIT_RULE, ruleLabel)}
        subCategoryDetails={ruleDescription}
        changeKind={InstructionListChangeKind.CHANGE}
        onExit={onExit}
        key={ruleId}
      >
        {editRuleInstructionElemsByRuleId[ruleId].map((ins) => ins)}
      </NestedInstructionListItem>
    );

    categorizeInstruction({ category: InstructionListCategory.RULES, insElem });
  });

  return <ul className="InstructionList">{renderInstructionsWithCategories(categoriesToExpand)}</ul>;
}
export const getMeasuredRolloutRuleName = (
  ruleInstruction: SemanticInstruction | undefined,
  flag: Flag,
  environmentKey: string,
) => {
  if (
    ruleInstruction?.kind !== RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT &&
    ruleInstruction?.kind !== RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT_V2 &&
    ruleInstruction?.kind !== OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT &&
    ruleInstruction?.kind !== OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT_V2 &&
    ruleInstruction?.kind !== OnOffInstructionKind.STOP_MEASURED_ROLLOUT_ON_FALLTHROUGH &&
    ruleInstruction?.kind !== RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT &&
    ruleInstruction?.kind !== RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT_V2
  ) {
    throw new Error('Rule instruction must be a measured rollout instruction');
  }

  const ruleId = 'ruleId' in ruleInstruction ? ruleInstruction.ruleId : 'fallthrough';
  const envFlag = flag.environments.get(environmentKey);
  const targetRule = envFlag?.rules.find((_rule) => _rule._id === ruleId || _rule._key === ruleId);

  if (ruleId === 'fallthrough') {
    return 'Default rule';
  }

  if (targetRule?.description) {
    return targetRule.description;
  }

  if (targetRule) {
    const ruleIndex = envFlag?.rules.indexOf(targetRule);
    invariant(typeof ruleIndex !== 'undefined', 'Rule index should not be undefined');
    return `Rule ${ruleIndex + 1}`;
  }

  if (ruleInstruction?.kind === RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT) {
    if (typeof ruleInstruction.description !== 'undefined') {
      return ruleInstruction.description;
    }

    const beforeRuleId = ruleInstruction.beforeRuleId;
    const beforeRule = envFlag?.rules.find((_rule) => _rule._id === beforeRuleId || _rule._key === beforeRuleId);
    if (beforeRule) {
      const beforeIndex = envFlag?.rules.indexOf(beforeRule);
      invariant(typeof beforeIndex !== 'undefined', 'Before index should not be undefined');
      return `Rule ${beforeIndex - 1}`;
    }
  }

  const ruleCount = envFlag?.rules.size;
  invariant(typeof ruleCount !== 'undefined', 'Rule count should not be undefined');
  return `Rule ${ruleCount + 1}`;
};
