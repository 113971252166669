import { createTrackerForCategory } from '@gonfalon/analytics';
import {
  campaignDaysRemaining,
  Campaigns,
  canMakeSelfServeChanges,
  getCampaignEndDate,
  getCampaignStartDate,
  getEnterpriseCampaign,
  getLimitPlanUpperLimit,
  getSeatLimit,
  getTrialDaysRemaining,
  hasCampaignBeenExtended,
  hasCampaignBeenStarted,
  hasPlan,
  is2021Plan,
  isCampaignActive,
  isCampaignExpired,
  isExpiredTrialWithNoPlan,
  isInvoiced,
  isSelfServe,
  isTrialExpired,
  PlanTypes,
  SubscriptionStates,
} from '@gonfalon/billing';
import {
  enableDeveloperPlanCheckout,
  enablePrimaryContextsInUiOverride,
  enableSelfServeExperimentationKeysLimit,
  enableSelfServePaygFreeUnits,
  enableSelfServeUBBWithAnnualCommits,
  foundation2023PlanVersion,
  is2018ProTo2021PlanTransitionEnabled,
  switchBillingYearlyToMonthlyGracePeriod,
} from '@gonfalon/dogfood-flags';
import { isNil } from '@gonfalon/es6-utils';
import { differenceInDays } from 'date-fns';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, Record } from 'immutable';

import { amberfloMeter } from 'components/amberflo/utils/amberfloUtils';
import { NEAR_OVERAGES_THRESHOLD } from 'components/usage/constants';
import { objectKeys } from 'utils/collectionUtils';
import { CreateFunctionInput, ImmutableMap, valueExists } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import { titlecase } from 'utils/stringUtils';

export const MAX_PROJECT_LIMIT = 2;
export const MAX_ENVIRONMENT_LIMIT = 4;

export enum billingIntervals {
  YEARLY = 'AnnualBilling',
  MONTHLY = 'MonthlyBilling',
}

export enum limitNames {
  HOSTS = 'Hosts',
  SEATS = 'SeatCount',
  EVENTS_PUBLISHED = 'EventsPublished',
  EVENTS_RECEIVED = 'EventsReceived',
  MONTHLY_ACTIVE_USERS = 'MonthlyActiveUsers',
  SSO = 'SSO',
  EXPERIMENTATION_KEYS = 'ExperimentationKeys',
  SERVICE_CONNECTIONS = 'ServiceConnections',
  EXPERIMENTATION_MAU = 'ExperimentationMAU',
}

export const isAmberfloMeter = (limitName: limitNames) =>
  [limitNames.EXPERIMENTATION_KEYS, limitNames.MONTHLY_ACTIVE_USERS].includes(limitName);

export const getAmberfloMeterNameForLimit = (limitName: limitNames) => {
  switch (limitName) {
    case limitNames.EXPERIMENTATION_KEYS:
      return amberfloMeter.EXPERIMENTATION_KEYS;
    case limitNames.MONTHLY_ACTIVE_USERS:
      return amberfloMeter.CLIENT_SIDE_CONTEXTS;
    default:
      return;
  }
};

export const getLimitNameForOverageType = (value: string): limitNames | undefined => {
  switch (value) {
    case limitNames.SEATS:
      return limitNames.SEATS;

    case limitNames.EVENTS_PUBLISHED:
      return limitNames.EVENTS_PUBLISHED;

    case limitNames.EVENTS_RECEIVED:
      return limitNames.EVENTS_RECEIVED;

    case limitNames.SSO:
      return limitNames.SSO;

    case limitNames.MONTHLY_ACTIVE_USERS:
      return limitNames.MONTHLY_ACTIVE_USERS;

    case limitNames.EXPERIMENTATION_KEYS:
      return limitNames.EXPERIMENTATION_KEYS;

    case limitNames.EXPERIMENTATION_MAU:
      return limitNames.EXPERIMENTATION_MAU;

    // TBD ServerConnections
    default:
      return;
  }
};

type LegacyPlanLimitsType = {
  multipleProjects: boolean;
  multipleEnvironments: boolean;
  abTesting: boolean;
  teams: boolean;
  customRoles: boolean;
  auditLog: boolean;
  mauLimit: number;
};

class LegacyPlanLimits extends Record<LegacyPlanLimitsType>({
  multipleProjects: false,
  multipleEnvironments: false,
  abTesting: false,
  teams: false,
  customRoles: false,
  auditLog: false,
  mauLimit: 0,
}) {
  getMauLimit() {
    return this.mauLimit;
  }

  getProjectLimit() {
    return this.multipleProjects ? -1 : MAX_PROJECT_LIMIT;
  }

  getEnvironmentLimit() {
    return this.multipleEnvironments ? -1 : MAX_ENVIRONMENT_LIMIT;
  }
}

type PlanLimitsType = {
  [K in limitNames]: number | null;
};

export class PlanLimits extends Record<PlanLimitsType>({
  [limitNames.SEATS]: null,
  [limitNames.MONTHLY_ACTIVE_USERS]: null,
  [limitNames.EVENTS_RECEIVED]: null,
  [limitNames.EVENTS_PUBLISHED]: null,
  [limitNames.SSO]: null,
  [limitNames.EXPERIMENTATION_KEYS]: null,
  [limitNames.SERVICE_CONNECTIONS]: null,
  [limitNames.HOSTS]: null,
  [limitNames.EXPERIMENTATION_MAU]: null,
}) {}

type BasePriceType = {
  monthly: number;
  yearly: number;
};

class BasePrice extends Record<BasePriceType>({
  monthly: 0,
  yearly: 0,
}) {
  getMonthlyPrice() {
    return this.monthly;
  }
  getYearlyPrice() {
    return this.yearly;
  }
}

export type LimitTierType = { limit: number };

export class LimitTier extends Record<LimitTierType>({ limit: 0 }) {}

export type LimitPlanType = {
  _links: ImmutableMap<{ self: Link }>;
  name: string;
  version: number;
  tiers: List<LimitTier>;
  included?: number;
};

export class LimitPlan extends Record<LimitPlanType>({
  _links: Map(),
  name: '',
  version: 0,
  tiers: List(),
  included: 0,
}) {
  getLowerLimit() {
    return this.tiers.first(new LimitTier()).limit;
  }

  getUpperLimit() {
    return getLimitPlanUpperLimit({ tiers: this.tiers.toJS() });
  }

  getIncluded(): number {
    return this.included || 0;
  }

  subtractIncludedFromTotal(totalVal: number = 0, included: number = 0) {
    return Math.max(0, totalVal - included); // Ensure we don't return a negative number
  }

  getFixedLimit() {
    return this.getLowerLimit();
  }
}

export type LimitPlansType = {
  [K in limitNames]: LimitPlan;
};

export class LimitPlans extends Record<LimitPlansType>({
  [limitNames.EVENTS_PUBLISHED]: createLimitPlan(),
  [limitNames.EVENTS_RECEIVED]: createLimitPlan(),
  [limitNames.MONTHLY_ACTIVE_USERS]: createLimitPlan(),
  [limitNames.SEATS]: createLimitPlan(),
  [limitNames.SSO]: createLimitPlan(),
  [limitNames.EXPERIMENTATION_KEYS]: createLimitPlan(),
  [limitNames.SERVICE_CONNECTIONS]: createLimitPlan(),
  [limitNames.HOSTS]: createLimitPlan(),
  [limitNames.EXPERIMENTATION_MAU]: createLimitPlan(),
}) {}

export const getStarterPlanMauLimit = (activePlan: Plan, monthlyActiveUsersLimitPlan: LimitPlan) =>
  // 2018 starter plans get their fixed limit from a flag
  activePlan.type === planTypes.STARTER
    ? activePlan.limits[limitNames.MONTHLY_ACTIVE_USERS]
    : monthlyActiveUsersLimitPlan.getFixedLimit();

export type LegacyPlanType = {
  _links: ImmutableMap<{ self: Link }>;
  _limits: LegacyPlanLimits;
  name: string;
  version: number;
  monthlyPrice: number;
};

export class LegacyPlan extends Record<LegacyPlanType>({
  _links: Map(),
  _limits: new LegacyPlanLimits(),
  name: '',
  version: 0,
  monthlyPrice: 0,
}) {}

export enum planTypes {
  LEGACY = 'Legacy',
  STARTER = 'Starter',
  STARTER2021 = 'Starter2021',
  STANDARD_TRIAL = 'StandardTrial',
  STANDARD_TRIAL2021 = 'StandardTrial2021',
  PROFESSIONAL = 'Professional',
  PROFESSIONAL2021 = 'Professional2021',
  ENTERPRISE_TRIAL = 'EnterpriseTrial',
  ENTERPRISE = 'Enterprise',
  ENTERPRISE2023 = 'Enterprise2023',
  FOUNDATION2023 = 'Foundation2023',
  FOUNDATION_TRIAL2023 = 'FoundationTrial2023',
  PARTNER = 'Partner',
  DEVELOPER = 'Developer',
  GUARDIAN2024 = 'Guardian2024',
  GUARDIAN_SEATS2024 = 'Guardian2024S',
  GUARDIAN_TRIAL2024 = 'GuardianTrial2024',
}

export enum billingProcess {
  STANDARD = 'StandardBilling',
  USAGE_BASED_BILLING = 'UsageBasedBilling', // monthly usage based plans
  COMMIT_WITH_OVERAGE = 'CommitWithOverageBilling', // annual usage based plans
}

export const formatPlanType = (pt: planTypes) =>
  ({
    [planTypes.STARTER]: 'Starter',
    [planTypes.STARTER2021]: 'Starter',
    [planTypes.STANDARD_TRIAL]: 'Professional trial',
    [planTypes.STANDARD_TRIAL2021]: 'Professional trial',
    [planTypes.PROFESSIONAL]: is2018ProTo2021PlanTransitionEnabled() ? 'Legacy professional' : 'Professional',
    [planTypes.PROFESSIONAL2021]: 'Professional',
    [planTypes.ENTERPRISE_TRIAL]: 'Enterprise trial',
    [planTypes.ENTERPRISE]: 'Enterprise',
    [planTypes.ENTERPRISE2023]: 'Enterprise',
    [planTypes.FOUNDATION2023]: 'Foundation',
    [planTypes.LEGACY]: 'Legacy',
    [planTypes.FOUNDATION_TRIAL2023]: 'Foundation trial',
    [planTypes.PARTNER]: 'Partner',
    [planTypes.DEVELOPER]: 'Developer',
    [planTypes.GUARDIAN2024]: 'Guardian',
    [planTypes.GUARDIAN_SEATS2024]: 'Guardian',
    [planTypes.GUARDIAN_TRIAL2024]: 'Guardian trial',
  })[pt];

export type PlanType = {
  _links: ImmutableMap<{ self: Link }>;
  limits: PlanLimits;
  basePriceInCents: BasePrice;
  type: planTypes;
  version: number;
};

export class Plan extends Record<PlanType>({
  _links: Map(),
  limits: new PlanLimits(),
  basePriceInCents: new BasePrice(),
  type: planTypes.STANDARD_TRIAL,
  version: 0,
}) {
  getMonthlyPrice() {
    return this.basePriceInCents.getMonthlyPrice() / 100;
  }
  getYearlyPrice() {
    return this.basePriceInCents.getYearlyPrice() / 100;
  }
  getType() {
    return this.type;
  }
  isStarter() {
    return this.type === planTypes.STARTER || this.type === planTypes.STARTER2021;
  }
  isEnterprise() {
    return this.type === planTypes.ENTERPRISE || this.type === planTypes.ENTERPRISE2023;
  }
  isFoundation() {
    return this.type === planTypes.FOUNDATION2023;
  }
  isDeveloper() {
    return this.type === planTypes.DEVELOPER;
  }
  isGuardian() {
    return this.type === planTypes.GUARDIAN2024;
  }
  isProfessional() {
    return this.type === planTypes.PROFESSIONAL || this.type === planTypes.PROFESSIONAL2021;
  }

  isTrial() {
    return this.type === planTypes.STANDARD_TRIAL || this.type === planTypes.STANDARD_TRIAL2021;
  }
  is2021Plan() {
    return (
      this.type === planTypes.STARTER2021 ||
      this.type === planTypes.PROFESSIONAL2021 ||
      this.type === planTypes.STANDARD_TRIAL2021
    );
  }
  hasAnyConfigurableLimit() {
    // 2018 starter plans are the only plan without any configurable option;
    return this.type !== planTypes.STARTER;
  }
  hasConfigurableLimit(limit: limitNames) {
    switch (limit) {
      case limitNames.SEATS:
        return this.type !== planTypes.STARTER;
      case limitNames.MONTHLY_ACTIVE_USERS:
        return !this.isStarter();
      case limitNames.SSO:
        return this.is2021Plan();
      case limitNames.EVENTS_RECEIVED:
        return !this.isStarter();
      case limitNames.EXPERIMENTATION_KEYS:
        return !this.isStarter();
      case limitNames.EVENTS_PUBLISHED:
        return this.isEnterprise();
      case limitNames.EXPERIMENTATION_MAU:
        return this.isFoundation();
      default:
        return false;
    }
  }
}

type PlanListType = {
  items: List<Plan>;
};

export class PlanList extends Record<PlanListType>({
  items: List(),
}) {
  getDeveloperPlan() {
    let plan = this.items.filter((d) => d.type === planTypes.FOUNDATION2023).first<undefined>();
    plan = plan?.set('type', planTypes.DEVELOPER);
    return plan;
  }
  getFoundationPlan() {
    return this.items.filter((d) => d.type === planTypes.FOUNDATION2023).first<undefined>();
  }
  getProfessionalPlan() {
    return this.items.filter((d) => d.type === planTypes.PROFESSIONAL).first<undefined>();
  }
  getProfessional2021Plan() {
    return this.items.filter((d) => d.type === planTypes.PROFESSIONAL2021).first<undefined>();
  }
  getStarterPlan() {
    return this.items.filter((d) => d.type === planTypes.STARTER).first<undefined>();
  }
  getStarter2021Plan() {
    return this.items.filter((d) => d.type === planTypes.STARTER2021).first<undefined>();
  }
  getEnterprisePlan() {
    return this.items.filter((d) => d.type === planTypes.ENTERPRISE).first<undefined>();
  }
  getGuardianPlan() {
    return this.items.filter((d) => d.type === planTypes.GUARDIAN2024).first<undefined>();
  }
}

export type PaymentCardType = {
  _links: ImmutableMap<{ self: Link }>;
  last4: string;
  brand: string;
  expMonth: number;
  expYear: number;
};

export class PaymentCard extends Record<PaymentCardType>({
  _links: Map(),
  last4: '',
  brand: '',
  expMonth: 0,
  expYear: 0,
}) {}

type MauCountType = {
  total: number;
  anonymous: number;
  nonAnonymous: number;
  client: number;
  server: number;
};

class MauCount extends Record<MauCountType>({
  total: 0,
  anonymous: 0,
  nonAnonymous: 0,
  client: 0,
  server: 0,
}) {}

type LegacyUsageType = {
  mauCount: MauCount;
  memberCount: number;
  environmentCount: number;
  projectCount: number;
  ABTesting: boolean;
};

class LegacyUsage extends Record<LegacyUsageType>({
  mauCount: new MauCount(),
  memberCount: 0,
  environmentCount: 0,
  projectCount: 0,
  ABTesting: false,
}) {}

type SubscriptionUsageType = {
  [K in limitNames]: number;
};

export class SubscriptionUsage extends Record<SubscriptionUsageType>({
  [limitNames.HOSTS]: 0,
  [limitNames.EVENTS_PUBLISHED]: 0,
  [limitNames.EVENTS_RECEIVED]: 0,
  [limitNames.MONTHLY_ACTIVE_USERS]: 0,
  [limitNames.SEATS]: 0,
  [limitNames.SSO]: 0,
  [limitNames.EXPERIMENTATION_KEYS]: 0,
  [limitNames.SERVICE_CONNECTIONS]: 0,
  [limitNames.EXPERIMENTATION_MAU]: 0,
}) {}

export type ScheduledSubscriptionChange = ImmutableMap<{
  effectiveDate: string;
  updatedAt: string;
  primaryContext: string;
  _limits: ImmutableMap<{
    [k in limitNames]: number;
  }>;
}>;

export type SubscriptionType = {
  _links: ImmutableMap<{ self: Link }>;
  _limits: PlanLimits;
  _hasCustomLimits: boolean;
  _next?: ScheduledSubscriptionChange | null;
  version: number;
  billingProcess: billingProcess;
  planType: planTypes;
  state: SubscriptionStates;
  billingInterval: billingIntervals;
  trialStartDate: string;
  trialEndDate: string;
  periodStartDate?: string;
  periodEndDate?: string;
  latestAmberfloPlanUpdateDate?: string;
  monthlyBillingExpiration?: string;
  usagePeriodStartDate?: string;
  usagePeriodEndDate?: string;
  isFree?: boolean;
  trialExtensionCount: number;
  campaigns: Map<string, Campaign>;
  primaryContextKind: string;
};

export class Subscription extends Record<SubscriptionType>({
  billingProcess: billingProcess.STANDARD,
  _next: null,
  _links: Map(),
  _limits: new PlanLimits(),
  _hasCustomLimits: false,
  planType: planTypes.STANDARD_TRIAL2021,
  version: 0,
  billingInterval: billingIntervals.YEARLY,
  trialStartDate: '',
  trialEndDate: '',
  periodStartDate: undefined,
  periodEndDate: undefined,
  latestAmberfloPlanUpdateDate: undefined,
  monthlyBillingExpiration: undefined,
  usagePeriodStartDate: undefined,
  usagePeriodEndDate: undefined,
  state: SubscriptionStates.UNKNOWN,
  isFree: false, // for free and invoiced customers
  trialExtensionCount: 0,
  campaigns: Map(),
  primaryContextKind: 'user',
}) {
  static states = SubscriptionStates;

  isStarter() {
    return this.planType === planTypes.STARTER;
  }

  isStarter2021() {
    return this.planType === planTypes.STARTER2021;
  }

  isFoundation() {
    return [planTypes.FOUNDATION2023, planTypes.FOUNDATION_TRIAL2023].includes(this.planType);
  }

  isProfessional() {
    return [planTypes.STANDARD_TRIAL, planTypes.PROFESSIONAL].includes(this.planType);
  }

  isProfessional2021() {
    return [planTypes.STANDARD_TRIAL2021, planTypes.PROFESSIONAL2021].includes(this.planType);
  }

  isEnterprise() {
    return [planTypes.ENTERPRISE_TRIAL, planTypes.ENTERPRISE, planTypes.ENTERPRISE2023].includes(this.planType);
  }

  isInvoiced() {
    return isInvoiced({ state: this.state, periodEndDate: this.periodEndDate });
  }

  hasSubscription() {
    return [
      Subscription.states.ACTIVE_SUBSCRIPTION,
      Subscription.states.ACTIVE_TRIAL_WITH_SUBSCRIPTION,
      Subscription.states.FAILED_PAYMENT_SUBSCRIPTION,
      Subscription.states.LAPSED_SUBSCRIPTION,
    ].includes(this.state);
  }

  hasPlan() {
    return this.hasSubscription() || [Subscription.states.PENDING_CANCELED_SUBSCRIPTION].includes(this.state);
  }

  isTrial() {
    return (
      this.planType === planTypes.STANDARD_TRIAL ||
      this.planType === planTypes.STANDARD_TRIAL2021 ||
      this.planType === planTypes.ENTERPRISE_TRIAL
    );
  }

  isActiveTrial() {
    return [Subscription.states.ACTIVE_TRIAL, Subscription.states.ACTIVE_TRIAL_WITH_SUBSCRIPTION].includes(this.state);
  }

  // Amberflo trials end the on date of the trial end date at midnight UTC regardless of trial end date time
  isAmberfloActiveTrialWithSubscription() {
    const trialEndDateObj = new Date(this.trialEndDate);
    const trialEndDateTruncatedUTC = Date.UTC(
      trialEndDateObj.getFullYear(),
      trialEndDateObj.getMonth(),
      trialEndDateObj.getDate(),
    );
    const today = new Date();
    const todayTruncatedUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
    const amberfloBillingPeriodHasStarted = todayTruncatedUTC >= trialEndDateTruncatedUTC;

    return (
      this.state === Subscription.states.ACTIVE_TRIAL_WITH_SUBSCRIPTION &&
      !amberfloBillingPeriodHasStarted &&
      this.billingProcess === billingProcess.USAGE_BASED_BILLING
    );
  }

  hasSubscriptionOrActiveTrial() {
    return this.hasSubscription() || this.isActiveTrial();
  }

  isActiveTrialWithNoPlan() {
    return this.state === Subscription.states.ACTIVE_TRIAL;
  }

  isActiveTrialWithPlan() {
    return this.state === SubscriptionStates.ACTIVE_TRIAL_WITH_SUBSCRIPTION;
  }

  isExpiredTrialOrCanceled() {
    return [
      Subscription.states.GRACE_PERIOD_TRIAL,
      Subscription.states.LAPSED_TRIAL,
      Subscription.states.LAPSED_SUBSCRIPTION,
    ].includes(this.state);
  }

  isExpiredTrialWithNoPlan() {
    return isExpiredTrialWithNoPlan({ state: this.state });
  }

  isPendingCancelation() {
    return this.state === Subscription.states.PENDING_CANCELED_SUBSCRIPTION;
  }

  isCancelled() {
    return this.state === Subscription.states.CANCELED_SUBSCRIPTION;
  }

  isLapsed() {
    return this.state === Subscription.states.LAPSED_SUBSCRIPTION;
  }

  hasFailedPayment() {
    return this.state === Subscription.states.FAILED_PAYMENT_SUBSCRIPTION;
  }

  isCanceledOrLapsed() {
    return this.isCancelled() || this.isLapsed();
  }

  hasUpcomingPayment() {
    return [
      Subscription.states.ACTIVE_TRIAL_WITH_SUBSCRIPTION,
      Subscription.states.ACTIVE_SUBSCRIPTION,
      Subscription.states.FAILED_PAYMENT_SUBSCRIPTION,
    ].includes(this.state);
  }

  hasOverduePayment() {
    return this.state === Subscription.states.FAILED_PAYMENT_SUBSCRIPTION;
  }

  isYearly() {
    return this.billingInterval === billingIntervals.YEARLY;
  }

  canUpdateBillingInterval() {
    if (switchBillingYearlyToMonthlyGracePeriod() !== 0) {
      if (!isNil(this.monthlyBillingExpiration) && new Date().valueOf() < Date.parse(this.monthlyBillingExpiration)) {
        return true;
      }
    }
    return !(this.hasPlan() && this.billingInterval === billingIntervals.YEARLY);
  }

  updateBillingIntervalExpiration() {
    return !isNil(this.monthlyBillingExpiration) && switchBillingYearlyToMonthlyGracePeriod() !== 0
      ? Date.parse(this.monthlyBillingExpiration)
      : null;
  }

  isSelfServe() {
    return isSelfServe({ state: this.state, periodEndDate: this.periodEndDate });
  }

  canMakeSelfServeChanges() {
    return canMakeSelfServeChanges({
      periodEndDate: this.periodEndDate,
      _hasCustomLimits: this._hasCustomLimits,
      state: this.state,
      planType: this.planType as unknown as PlanTypes,
    });
  }

  cannotUpdateSubscription() {
    return !this.canMakeSelfServeChanges();
  }

  isCustomPlan() {
    return this._hasCustomLimits;
  }

  is2021Plan() {
    return is2021Plan({ state: this.state, planType: this.planType as unknown as PlanTypes });
  }

  isUsageBasedBilling() {
    return (
      this.billingProcess === billingProcess.USAGE_BASED_BILLING ||
      this.billingProcess === billingProcess.COMMIT_WITH_OVERAGE
    );
  }
  hasSSO() {
    return this._limits.SSO === 1;
  }

  getPerSeatPrice() {
    if (!this.is2021Plan()) {
      return;
    }
    const cost = this.planType === planTypes.STARTER2021 ? 10 : 20;
    return this.hasSSO() ? cost + 10 : cost;
  }

  getSeatCount() {
    return this._limits.SeatCount;
  }

  getTrialDaysRemaining() {
    return getTrialDaysRemaining({
      state: this.state,
      trialEndDate: this.trialEndDate,
      campaigns: this.campaigns.toJS(),
    });
  }

  getSeatLimit() {
    return getSeatLimit({ state: this.state, _limits: this._limits.toJS() });
  }

  getEnterpriseCampaign() {
    const campaign = getEnterpriseCampaign({ state: this.state, campaigns: this.campaigns.toJS() });
    if (campaign) {
      // conversion of plainJS to class
      return new Campaign(campaign);
    }
    return;
  }

  canStartEnterpriseCampaign() {
    const enterpriseCampaign = this.getEnterpriseCampaign();
    const enterpriseCampaignNotActive = !enterpriseCampaign?.isActive();
    return (
      [planTypes.PROFESSIONAL, planTypes.PROFESSIONAL2021, planTypes.LEGACY].includes(this.planType) &&
      enterpriseCampaignNotActive
    );
  }

  isDefaultedOrActiveTrialWithNoPlan() {
    return (
      this.isExpiredTrialOrCanceled() || this.isActiveTrialWithNoPlan() || !!this.getEnterpriseCampaign()?.isActive()
    );
  }

  isEnterpriseTrial() {
    return this.planType === planTypes.ENTERPRISE_TRIAL || this.hasActiveEnterpriseCampaign();
  }

  hasActiveEnterpriseCampaign() {
    return !!this.getEnterpriseCampaign()?.isActive();
  }

  hasExpiredEnterpriseCampaign() {
    return !!this.getEnterpriseCampaign()?.isExpired();
  }

  isAmberfloSourcedUsage() {
    if (enablePrimaryContextsInUiOverride()) {
      return true;
    }
    return (
      this.isSelfServe() || this.planType === planTypes.ENTERPRISE2023 || this.planType === planTypes.FOUNDATION2023
    );
  }

  isCMetricsSourcedUsage() {
    return !this.isAmberfloSourcedUsage();
  }
}

type LegacySubscriptionType = {
  _links: ImmutableMap<{ self: Link; plan: Link }>;
  _limits: LegacyPlanLimits;
  _usage: LegacyUsage;
  name: string;
  version: number;
  trialStartDate: string;
  trialEndDate: string;
  periodStartDate?: string;
  periodEndDate?: string;
  graceStartDate?: string;
  graceEndDate?: string;
  seatCount?: number;
  isBeta: boolean;
  isTrial: boolean;
  isActive: boolean;
  isCurrent: boolean;
  isCanceled: boolean;
};

export class LegacySubscription extends Record<LegacySubscriptionType>({
  _links: Map(),
  _limits: new LegacyPlanLimits(),
  _usage: new LegacyUsage(),
  name: '',
  version: 0,
  trialStartDate: '',
  trialEndDate: '',
  periodStartDate: undefined,
  periodEndDate: undefined,
  graceStartDate: undefined,
  graceEndDate: undefined,
  seatCount: undefined,
  // isBeta is a legacy status that was equivalent to a free unlimited subscription.
  isBeta: false,
  isTrial: false,
  isActive: false,
  isCurrent: false,
  isCanceled: false,
}) {
  hasPlan() {
    return hasPlan({ _links: this._links?.toJS() as { [key: string]: { href?: string; type?: string } } | undefined });
  }

  hasABTesting() {
    return this._limits.abTesting;
  }

  hasTeams() {
    return this._limits.teams;
  }

  hasCustomRoles() {
    return this._limits.customRoles;
  }

  isInvoiced() {
    return !this.periodEndDate && this.hasPlan();
  }

  hasMultipleEnvironments() {
    return this._limits.multipleEnvironments;
  }

  hasAuditLog() {
    return this._limits.auditLog;
  }

  // A trial is expired if it ended before today.
  isTrialExpired() {
    return isTrialExpired({ trialEndDate: this.trialEndDate });
  }

  isActiveTrial() {
    return !this.isTrialExpired() && !this.isCanceled && !this.isBeta;
  }

  isActiveTrialWithNoPlan() {
    return this.hasPlan() && this.isTrial;
  }

  hasSubscriptionOrActiveTrial() {
    return this.hasSubscription() || this.isActiveTrial();
  }

  isExpiredTrialWithNoPlan() {
    return isExpiredTrialWithNoPlan({
      isCanceled: this.isCanceled,
      isBeta: this.isBeta,
      trialEndDate: this.trialEndDate,
      _links: this._links.toJS() as { [key: string]: { href?: string; type?: string } } | undefined,
    });
  }

  isLapsed() {
    return !this.isActive;
  }

  isExpiredTrialOrCanceled() {
    return (this.isTrial && this.isTrialExpired()) || this.isCanceled;
  }

  getPlanName() {
    if (this.isBeta) {
      return 'Custom plan';
    }

    return this.hasPlan() ? titlecase(this.name) : 'Trial';
  }

  isCancelled() {
    return this.isCanceled;
  }

  hasUpcomingPayment() {
    return this.hasPlan() && !this.isCanceled && !this.isLapsed();
  }

  hasOverduePayment() {
    return this.hasPlan() && !this.isCurrent;
  }

  hasSubscription() {
    return !this.isCanceled && this.hasPlan();
  }

  getTrialDaysRemaining() {
    return getTrialDaysRemaining({ trialEndDate: this.trialEndDate });
  }

  getEnterpriseCampaign(): undefined {
    // not supporrted on legacy subscriptions
    return;
  }

  isDefaultedOrActiveTrialWithNoPlan() {
    return this.isExpiredTrialOrCanceled() || this.isActiveTrialWithNoPlan();
  }

  isEnterpriseTrial() {
    // not supported on legacy subscriptions
    return false;
  }

  hasActiveEnterpriseCampaign() {
    // not supported on legacy subscriptions
    return false;
  }

  hasExpiredEnterpriseCampaign() {
    // not supported on legacy subscriptions
    return false;
  }

  isSelfServe() {
    // not supported on legacy subscriptions
    return false;
  }

  canMakeSelfServeChanges() {
    // not supported on legacy subscriptions
    return false;
  }
}

export function getPlan(subscription: LegacySubscription, publicPlans: List<LegacyPlan>) {
  const currentPlanId = subscription.getIn(['_links', 'plan', 'href']);
  return publicPlans.find((plan) => plan.getIn(['_links', 'self', 'href']) === currentPlanId);
}

// A custom plan is a plan that is not publicly available.
export function isCustomPlan(subscription: LegacySubscription, publicPlans: List<LegacyPlan>) {
  return subscription.hasPlan() && !getPlan(subscription, publicPlans);
}

export function isCancelationWithin30Days(periodEndDate?: string) {
  return !!periodEndDate && differenceInDays(new Date(periodEndDate), new Date()) < 30;
}

export function hasAnySubscription(subscription?: Subscription, legacySubscription?: LegacySubscription) {
  return (
    (subscription && subscription.hasSubscription()) || (legacySubscription && legacySubscription.hasSubscription())
  );
}

type InvoiceType = {
  _links: ImmutableMap<{ self: Link; doc: Link }>;
  date: string;
  key?: string;
};

export class Invoice extends Record<InvoiceType>({
  _links: Map(),
  date: '',
  key: '',
}) {
  selfLink() {
    return this._links.getIn(['self', 'href']);
  }

  docLink() {
    return this._links.getIn(['doc', 'href']);
  }
}

type SubscriptionChangeType = {
  planType: planTypes | null;
  billingInterval: billingIntervals | null;
  billingProcess: billingProcess | null;
  limits: PlanLimits;
};

export class SubscriptionChange extends Record<SubscriptionChangeType>({
  planType: null,
  billingInterval: null,
  billingProcess: null,
  limits: new PlanLimits(),
}) {
  areLimitsEmpty() {
    return Object.values(limitNames).every((v) => isNil(this.limits[v]));
  }
  areLimitsZero() {
    return Object.values(limitNames).every((v) => this.limits[v] === 0);
  }
  hasSSO() {
    return this.limits.SSO === 1;
  }
  getSeatCount() {
    return this.limits.SeatCount;
  }
  isYearly() {
    return this.billingInterval === billingIntervals.YEARLY;
  }
  isMonthly() {
    return this.billingInterval === billingIntervals.MONTHLY;
  }
  toRepLimits() {
    let rep = Map<limitNames, number>();

    for (const k of objectKeys(this.limits.toObject())) {
      const v = this.limits[k];
      if (!isNil(v) && v >= 0) {
        rep = rep.set(k, v);
      }
    }

    return rep;
  }

  isDeveloperPlan() {
    return this.planType === planTypes.DEVELOPER;
    // a Developer Plan is a monthly Foundation Plan
  }

  isFoundationPlan() {
    return this.planType === planTypes.FOUNDATION2023;
  }

  isProfessionalPlan() {
    return this.planType === planTypes.PROFESSIONAL || this.planType === planTypes.PROFESSIONAL2021;
  }

  isEnterprisePlan() {
    return this.planType === planTypes.ENTERPRISE || this.planType === planTypes.ENTERPRISE2023;
  }

  isGuardianPlan() {
    return this.planType === planTypes.GUARDIAN2024;
  }

  toRep() {
    let rep: ImmutableMap<{
      planType?: planTypes;
      billingInterval?: billingIntervals;
      billingProcess?: billingProcess;
      limits?: Map<limitNames, number>;
    }> = Map();

    if (this.planType) {
      rep = rep.set('planType', this.planType);
    }

    if (this.billingInterval) {
      rep = rep.set('billingInterval', this.billingInterval);
    }

    if (this.billingProcess) {
      rep = rep.set('billingProcess', this.billingProcess);
    }

    if (
      enableSelfServeUBBWithAnnualCommits() &&
      this.planType === planTypes.FOUNDATION2023 &&
      this.billingInterval === billingIntervals.YEARLY
    ) {
      // Foundation annual commits should send monthly active users, HOSTS.
      const limits = [limitNames.MONTHLY_ACTIVE_USERS, limitNames.HOSTS];
      // It should also send Experimentation MAU if we're not using version 1 of Foundation2023 plan.
      if (foundation2023PlanVersion() !== 1) {
        limits.push(limitNames.EXPERIMENTATION_MAU);
      }
      // It should also send Experimentation Keys if enableSelfServeExperimentationKeysLimit flag is serving TRUE.
      if (enableSelfServeExperimentationKeysLimit()) {
        limits.push(limitNames.EXPERIMENTATION_KEYS);
      }

      rep = rep.set(
        'limits',
        this.toRepLimits().filter((value, key) => limits.includes(key)),
      );
    } else if (this.planType !== planTypes.STARTER) {
      const limitRep = this.toRepLimits();
      if (!limitRep.isEmpty()) {
        rep = rep.set('limits', limitRep);
      }
    } else {
      rep = rep.remove('limits');
    }

    return rep.toJS();
  }
}

type SubscriptionPriceType = {
  monthly: number;
  yearly: number;
  promoCode?: string;
};

export class SubscriptionPrice extends Record<SubscriptionPriceType>({
  monthly: 0,
  yearly: 0,
  promoCode: '',
}) {
  getMonthlyPrice() {
    return this.monthly / 100;
  }

  getYearlyPrice() {
    return this.yearly / 100;
  }
}

export type SubscriptionPreview = ImmutableMap<{
  prorationTime: number;
  prices?: SubscriptionPrice;
  proratedPrices?: SubscriptionPrice;
  creditDue?: SubscriptionPrice;
  renewalTime: number;
}>;

export const HumanCampaignNames: { [key in Campaigns]: string } = {
  [Campaigns.ENTERPRISE]: 'enterprise',
};

type CampaignType = {
  startDateTime: string;
  endDateTime: string;
  extensionCount: number;
};

export class Campaign extends Record<CampaignType>({
  startDateTime: '',
  endDateTime: '',
  extensionCount: 0,
}) {
  getStartDate() {
    return getCampaignStartDate({
      startDateTime: this.startDateTime,
      endDateTime: this.endDateTime,
      extensionCount: this.extensionCount,
    });
  }

  getEndDate() {
    return getCampaignEndDate({
      startDateTime: this.startDateTime,
      endDateTime: this.endDateTime,
      extensionCount: this.extensionCount,
    });
  }

  daysRemaining() {
    return campaignDaysRemaining({
      startDateTime: this.startDateTime,
      endDateTime: this.endDateTime,
      extensionCount: this.extensionCount,
    });
  }

  isActive() {
    return isCampaignActive({
      startDateTime: this.startDateTime,
      endDateTime: this.endDateTime,
      extensionCount: this.extensionCount,
    });
  }

  isExpired() {
    return isCampaignExpired({
      startDateTime: this.startDateTime,
      endDateTime: this.endDateTime,
      extensionCount: this.extensionCount,
    });
  }

  hasBeenStarted() {
    return hasCampaignBeenStarted({
      startDateTime: this.startDateTime,
      endDateTime: this.endDateTime,
      extensionCount: this.extensionCount,
    });
  }

  hasBeenExtended() {
    return hasCampaignBeenExtended({
      startDateTime: this.startDateTime,
      endDateTime: this.endDateTime,
      extensionCount: this.extensionCount,
    });
  }
}

export function createCampaign(props?: CreateFunctionInput<CampaignType>) {
  return new Campaign(fromJS(props));
}

export function createPlanLimits(props?: CreateFunctionInput<PlanLimits>) {
  return new PlanLimits(fromJS(props));
}

function createBasePrice(props?: CreateFunctionInput<BasePrice>) {
  return new BasePrice(props);
}

function createLegacyPlanLimits(props?: CreateFunctionInput<LegacyPlanLimits>) {
  return new LegacyPlanLimits(props);
}

export function createSubscriptionUsage(props?: CreateFunctionInput<SubscriptionUsage>) {
  return props ? new SubscriptionUsage(props) : new SubscriptionUsage();
}

function createLegacyUsage(props?: CreateFunctionInput<LegacyUsage>) {
  return props ? new LegacyUsage(fromJS(props).update('mauCount', createMauCount)) : new LegacyUsage();
}

function createMauCount(props: CreateFunctionInput<MauCount>) {
  return new MauCount(props);
}

export function createLegacyPlan(props?: CreateFunctionInput<LegacyPlan>) {
  return props ? new LegacyPlan(fromJS(props).update('_limits', createLegacyPlanLimits)) : new LegacyPlan();
}

export function createPlan(props?: CreateFunctionInput<Plan>) {
  return props
    ? new Plan(fromJS(props)).update('limits', createPlanLimits).update('basePriceInCents', createBasePrice)
    : new Plan();
}

export function createPlanList(props?: CreateFunctionInput<PlanList>) {
  return props ? new PlanList(fromJS(props)).update('items', (items) => items.map(createPlan)) : new PlanList();
}

export function createLimitPlans(props?: CreateFunctionInput<LimitPlans>) {
  return props
    ? new LimitPlans(fromJS(props))
        .update(limitNames.SEATS, createLimitPlan)
        .update(limitNames.MONTHLY_ACTIVE_USERS, createLimitPlan)
        .update(limitNames.EVENTS_RECEIVED, createLimitPlan)
        .update(limitNames.EXPERIMENTATION_KEYS, createLimitPlan)
        .update(limitNames.EVENTS_PUBLISHED, createLimitPlan)
        .update(limitNames.SSO, createLimitPlan)
        .update(limitNames.HOSTS, createLimitPlan)
        .update(limitNames.EXPERIMENTATION_MAU, createLimitPlan)
    : new LimitPlans();
}

export function createLimitTier(props?: CreateFunctionInput<LimitTier>) {
  return props ? new LimitTier(fromJS(props)) : new LimitTier();
}

export function createLimitPlan(props?: CreateFunctionInput<LimitPlan>) {
  return props
    ? new LimitPlan(fromJS(props)).update('tiers', (tiers) => (tiers ? tiers.map(createLimitTier) : List()))
    : new LimitPlan();
}

export function createPaymentCard(props?: CreateFunctionInput<PaymentCard>) {
  return props ? new PaymentCard(fromJS(props)) : new PaymentCard();
}

export function createLegacySubscription(props?: CreateFunctionInput<LegacySubscription>) {
  return props
    ? new LegacySubscription(
        fromJS(props).update('_limits', createLegacyPlanLimits).update('_usage', createLegacyUsage),
      )
    : new LegacySubscription();
}

export function createSubscription(props?: CreateFunctionInput<Subscription>) {
  return props
    ? new Subscription(fromJS(props))
        .update('_limits', createPlanLimits)
        .update('campaigns', (campaigns) => (campaigns ? campaigns.map(createCampaign) : Map()))
    : new Subscription();
}

export function createInvoice(props?: CreateFunctionInput<Invoice>) {
  return props instanceof Invoice ? props : new Invoice(fromJS(props));
}

export const createSubscriptionChange = (props?: CreateFunctionInput<SubscriptionChange>) =>
  props instanceof SubscriptionChange
    ? props
    : new SubscriptionChange(fromJS(props)).update('limits', createPlanLimits);

export const createSubscriptionPrice = (props?: CreateFunctionInput<SubscriptionPrice>) =>
  props instanceof SubscriptionPrice ? props : new SubscriptionPrice(fromJS(props));

const getLimitChange = (subscriptionChange: SubscriptionChange) =>
  subscriptionChange.limits.toSeq().toMap().filter(valueExists);

export const isSubscriptionDirty = ({
  subscription,
  subscriptionChange,
  activePlan,
}: {
  subscription: Subscription;
  subscriptionChange?: SubscriptionChange;
  activePlan?: Plan;
}) => {
  if (!subscriptionChange) {
    return false;
  }
  const limitChange = getLimitChange(subscriptionChange);
  const hasCurrentAnnualFoundationPlan =
    subscription.planType === planTypes.FOUNDATION2023 && subscription.billingInterval === billingIntervals.YEARLY;
  const hasSelectedAnnualFoundationPlan =
    subscriptionChange.planType === planTypes.FOUNDATION2023 &&
    subscriptionChange.billingInterval === billingIntervals.YEARLY;

  const nextChanges = subscription._next?.get('_limits');

  if (hasCurrentAnnualFoundationPlan && hasSelectedAnnualFoundationPlan && nextChanges) {
    // Check if subscription changes currently in browser match the upcoming scheduled subscription changes from the backend (in _next). If these match, return false, else return true.
    return !nextChanges?.every((value, key) => limitChange.get(key) === value);
  }

  if (activePlan?.isDeveloper()) {
    return (
      subscription.planType !== planTypes.FOUNDATION2023 ||
      subscription.billingInterval !== subscriptionChange.billingInterval
    );
  }

  return (
    subscription.planType !== activePlan?.type ||
    subscription.billingInterval !== subscriptionChange.billingInterval ||
    !limitChange.every((value, key) => subscription._limits.get(key) === value)
  );
};

export const shouldShowManagePlanPageLeaveConfirmation = ({
  subscription,
  subscriptionChange,
}: {
  subscription: Subscription;
  subscriptionChange?: SubscriptionChange;
}) => {
  if (!subscriptionChange) {
    return false;
  }
  const limitChange = getLimitChange(subscriptionChange);
  const initialAddOnValues =
    limitChange.get(limitNames.SEATS) === (enableSelfServeUBBWithAnnualCommits() ? 0 : 5) &&
    (limitChange.get(limitNames.MONTHLY_ACTIVE_USERS) === 10000 ||
      limitChange.get(limitNames.MONTHLY_ACTIVE_USERS) === 1000) &&
    limitChange.get(limitNames.SSO) === 0 &&
    limitChange.get(limitNames.EVENTS_PUBLISHED) === 0 &&
    limitChange.get(limitNames.EXPERIMENTATION_KEYS) === 0 &&
    limitChange.get(limitNames.EVENTS_RECEIVED) === 0;

  if (subscription.isTrial() && initialAddOnValues) {
    return false;
  }

  return (
    subscription.billingInterval !== subscriptionChange.billingInterval ||
    !limitChange.every((value, key) => subscription._limits.get(key) === value)
  );
};

export const isUpgradeNeededToAddSeats = ({
  isSeatLimitEnforced,
  numberOfNewSeatsToPurchase,
  planHasUnlimitedSeats,
  planSeatLimit,
  subscription,
  subscriptionSeatLimit,
}: {
  isSeatLimitEnforced: boolean;
  numberOfNewSeatsToPurchase: number;
  planHasUnlimitedSeats: boolean;
  planSeatLimit: number;
  subscription: Subscription;
  subscriptionSeatLimit: number;
}) => {
  if (!isSeatLimitEnforced || planHasUnlimitedSeats || numberOfNewSeatsToPurchase <= 0) {
    return false;
  }

  // If they invited everyone in the form (in addition to people already on their team) they’d use this many seats:
  const numberOfTotalSeatsAfterPurchase = subscriptionSeatLimit + numberOfNewSeatsToPurchase;

  // They have this many seats available before they need to upgrade to the next level:
  const seatsAvailableToPurchaseOnCurrentPlan = planSeatLimit - numberOfTotalSeatsAfterPurchase;

  return (
    [planTypes.STARTER2021, planTypes.PROFESSIONAL2021].includes(subscription.planType) &&
    seatsAvailableToPurchaseOnCurrentPlan < 0
  );
};

type SanitizedPlanLimits = {
  [key in limitNames]?: number;
};

function filterOutNilLimits(limits: PlanLimits): SanitizedPlanLimits {
  return limits.toSeq().toMap().filter(valueExists).toObject();
}

// The UI does not support creating enterprise subscriptions, so it's ignored here.
export const createSubscriptionChangeFromSelection = (
  subscription: Subscription,
  activePlan: Plan | null | undefined,
  limitPlans: LimitPlans,
  selectedLimits: PlanLimits,
  selectedBillingInterval: billingIntervals | null,
  usage: SubscriptionUsage,
) => {
  if (!activePlan) {
    return;
  }

  let baseLimits: SanitizedPlanLimits = {};
  if (activePlan.isProfessional() || activePlan.type === planTypes.STARTER2021) {
    baseLimits = {
      [limitNames.SEATS]: limitPlans.get(limitNames.SEATS).getLowerLimit(),
    };
  }

  let limits;
  //If on same plan as subscription, use selected limits
  if (subscription.hasPlan() && subscription.planType === activePlan.type) {
    limits = subscription._limits;
  } else {
    //If switching plans, use the current plan usage
    limits = preSetLimitsBasedOnUsage(usage, limitPlans, subscription, activePlan);
  }

  return createSubscriptionChange({
    planType: activePlan.type,
    billingInterval: getSelectedBillingInterval(activePlan, subscription, selectedBillingInterval),
    billingProcess:
      (enableSelfServeUBBWithAnnualCommits() && activePlan.isFoundation()) ||
      (enableDeveloperPlanCheckout() && activePlan.isDeveloper())
        ? billingProcess.COMMIT_WITH_OVERAGE
        : null,
    limits: {
      ...baseLimits,
      // TODO We should not have to cast {} to PlanLimitsType: it's not accurate, but we currently need to because we mix plan limits from different contexts
      ...(limits ? limits.toObject() : ({} as PlanLimitsType)),
      ...filterOutNilLimits(selectedLimits),
    },
  });
};

export function derivePlanFromLegacyPlan({
  usage,
  limitPlans,
  plans,
}: {
  usage: SubscriptionUsage;
  limitPlans: LimitPlans;
  plans: PlanList;
}) {
  if (Object.values(usage.toObject()).reduce((a, b) => a + b) === 1) {
    return plans.getStarter2021Plan();
  } else if (
    usage.SeatCount >= limitPlans.SeatCount.getUpperLimit() ||
    usage.MonthlyActiveUsers >= limitPlans.MonthlyActiveUsers.getUpperLimit() ||
    (usage.EventsReceived !== 0 && usage.EventsReceived >= limitPlans.EventsReceived.getUpperLimit())
  ) {
    return plans.getEnterprisePlan();
  }
  return plans.getProfessional2021Plan();
}

export function findSelectedPlan({
  planType,
  plans,
  usage,
  limitPlans,
}: {
  planType: planTypes;
  plans: PlanList;
  usage: SubscriptionUsage;
  limitPlans: LimitPlans;
}) {
  switch (planType) {
    case planTypes.DEVELOPER:
      return plans.getDeveloperPlan();
    case planTypes.FOUNDATION_TRIAL2023:
      return plans.getFoundationPlan();
    case planTypes.FOUNDATION2023:
      return plans.getFoundationPlan();
    case planTypes.ENTERPRISE:
      return plans.getEnterprisePlan();
    case planTypes.ENTERPRISE_TRIAL:
      return plans.getEnterprisePlan();
    case planTypes.GUARDIAN2024:
      return plans.getGuardianPlan();
    case planTypes.GUARDIAN_TRIAL2024:
      return plans.getDeveloperPlan();
    case planTypes.STANDARD_TRIAL:
      return plans.getProfessionalPlan();
    case planTypes.PROFESSIONAL:
      return plans.getProfessionalPlan();
    case planTypes.STANDARD_TRIAL2021:
      return plans.getProfessional2021Plan();
    case planTypes.PROFESSIONAL2021:
      return plans.getProfessional2021Plan();
    case planTypes.STARTER:
      return plans.getStarterPlan();
    case planTypes.STARTER2021:
      return plans.getStarter2021Plan();
    case planTypes.LEGACY:
      return derivePlanFromLegacyPlan({ usage, limitPlans, plans });
    default:
      return;
  }
}

function findClosestMaxValue(tiers: LimitTier[], usage: number) {
  let limit;
  tiers.forEach((t, i) => {
    if (usage !== null && tiers[i + 1]) {
      if (usage === t.limit) {
        limit = t.limit;
      } else if (usage > t.limit && usage >= tiers[i + 1].limit) {
        limit = tiers[tiers.length - 1].limit;
      } else if (usage > t.limit && usage <= tiers[i + 1].limit) {
        limit = tiers[i + 1].limit;
      }
    }
  });
  return limit || (tiers.length ? tiers[0].limit : 0);
}

export function preSetLimitsBasedOnUsage(
  usage: SubscriptionUsage,
  limitPlans: LimitPlans,
  subscription?: Subscription,
  activePlan?: Plan,
) {
  let newLimits = createPlanLimits();
  (Object.keys(limitPlans.toObject()) as limitNames[]).forEach((key: limitNames) => {
    newLimits = newLimits.set(key, findClosestMaxValue(limitPlans.get(key).tiers.toArray(), usage[key]));
  });
  const subscriptionSeatCount = subscription?.getSeatCount();
  const updatedLimitsSeatCount = newLimits.SeatCount;
  // For new plans with seats in increments of 1 we set the seats to the purchased amount rather than usage amount
  // if the subscribed limit does not exceed the available limit for that plan
  if (activePlan?.is2021Plan() && subscriptionSeatCount && updatedLimitsSeatCount) {
    const subscriptionSeatLimitExceedsMaxSeatLimit = limitPlans.SeatCount.getUpperLimit() < subscriptionSeatCount;
    if (subscriptionSeatCount > updatedLimitsSeatCount && !subscriptionSeatLimitExceedsMaxSeatLimit) {
      newLimits = newLimits.set(limitNames.SEATS, subscriptionSeatCount);
    }
  }
  // avoid removing SSO via switching plans
  if (subscription?.hasSSO()) {
    newLimits = newLimits.set(limitNames.SSO, 1);
  }
  return newLimits;
}

export function getUsageOverageList({
  subscriptionUsage,
  activePlan,
  selectedExperimentation,
  selectedClientMAUs,
  limitPlans,
}: {
  subscriptionUsage: SubscriptionUsage;
  activePlan: Plan;
  selectedExperimentation: number | undefined;
  selectedClientMAUs: number | undefined;
  limitPlans: LimitPlans;
}) {
  const selectedLimits = [selectedExperimentation, selectedClientMAUs];
  const limitNameList = [limitNames.EVENTS_RECEIVED, limitNames.MONTHLY_ACTIVE_USERS];

  const usageTypesOverLimit = limitNameList.filter((ln, i) => {
    if (!activePlan.hasAnyConfigurableLimit()) {
      //use 2018 starter plan limits for comparison
      const limit = activePlan.limits[ln];
      return !!limit && subscriptionUsage[ln] > limit;
    } else {
      const limit = selectedLimits[i];
      return limit ? subscriptionUsage[ln] > limit : subscriptionUsage[ln] > limitPlans[ln].getLowerLimit();
    }
  });
  return usageTypesOverLimit;
}

export function showSeatLimitError({
  subscription,
  subscriptionUsage,
  activePlan,
  selectedSeat,
}: {
  subscription: Subscription;
  subscriptionUsage: SubscriptionUsage;
  activePlan: Plan;
  selectedSeat: number | undefined;
}) {
  if (!activePlan.hasAnyConfigurableLimit()) {
    const seats = activePlan.limits[limitNames.SEATS];
    return !isNil(seats) && subscriptionUsage.SeatCount > seats;
  } else if (activePlan.isEnterprise()) {
    return false;
  } else if (activePlan.isFoundation()) {
    return false;
  }

  //for Standard Trial and Professional plans
  if (selectedSeat) {
    return !activePlan.isEnterprise() && subscriptionUsage.SeatCount > selectedSeat;
  }

  const seats = subscription._limits[limitNames.SEATS];
  return !isNil(seats) && !activePlan.isEnterprise() && subscriptionUsage.SeatCount > seats;
}

export const computeLimitRatio = (usage: number, limit: number) => (limit === -1 ? Infinity : usage / limit);

export const computePlanLimit = (usage: number, limit: number | null) => {
  if (!limit || limit === -1) {
    return null;
  }
  const limitRatio = computeLimitRatio(usage, limit);
  return limitRatio > NEAR_OVERAGES_THRESHOLD ? limit : null;
};

export const trackBillingEvent = createTrackerForCategory('Billing');

export const trackPaymentMethodButtonClick = (attrs: object) =>
  trackBillingEvent('Edit Payment Method Button Clicked', attrs);
export const trackPlanComparisonButtonClick = (attrs: object) =>
  trackBillingEvent('Plan Comparison Button Clicked', attrs);
export const trackPlanPickerButtonClick = () => trackBillingEvent('Plan Picker Button Clicked');
export const trackBillingContactButtonClick = (attrs: object) =>
  trackBillingEvent('Edit Billing Contact Button Clicked', attrs);
export const trackSavePaymentButtonClicked = (attrs: object) => trackBillingEvent('Save Payment Button Clicked', attrs);

export const trackBillingIntervalChange = (interval: billingIntervals) =>
  trackBillingEvent('Billing Frequency Toggle Clicked', {
    interval,
  });

export const trackPromoCodeEvent = createTrackerForCategory('Promo Codes');
export const trackPromoCodeSuccess = (attrs: { promoCode: string }) =>
  trackPromoCodeEvent('Valid Promo Code Added', attrs);
export const trackPromoCodeFail = (attrs: { promoCode: string }) =>
  trackPromoCodeEvent('Invalid Promo Code Added', attrs);
export const trackPromoCodeApplyButtonClicked = (attrs: { promoCode: string }) =>
  trackPromoCodeEvent('Apply Promo Code Button Clicked', attrs);

const limitEventNames = {
  [limitNames.MONTHLY_ACTIVE_USERS]: 'Client MAUs Adjusted',
  [limitNames.EVENTS_RECEIVED]: 'Experimentation Events Adjusted',
  [limitNames.SEATS]: 'Seats Adjusted',
  [limitNames.EVENTS_PUBLISHED]: 'Data Export Events Adjusted',
  [limitNames.EXPERIMENTATION_KEYS]: 'Unique Experimentation Keys Adjusted',
  [limitNames.SSO]: 'Single Sign On Adjusted',
  [limitNames.SERVICE_CONNECTIONS]: 'Service Connections Adjusted',
  [limitNames.HOSTS]: 'Hosts (Service Connections) Adjusted',
  [limitNames.EXPERIMENTATION_MAU]: 'Experimentation MAUs Adjusted',
};

export const trackLimitAdjustment = (limitName: limitNames, quantity: number) =>
  trackBillingEvent(limitEventNames[limitName], {
    quantity,
  });

export const trackInvoiceIconClick = () => trackBillingEvent('Invoice Download Icon Clicked');

export const trackUpdateSubscriptionButtonClick = ({
  selectedSubscription, // the subscription that's being previewed for purchase
  selectedSubscriptionPrice,
  subscription, // the subscription that the account currently has.
}: {
  selectedSubscription: SubscriptionChange;
  selectedSubscriptionPrice?: SubscriptionPrice;
  subscription: Subscription;
}) => {
  let price;

  if (
    selectedSubscription?.planType === planTypes.FOUNDATION2023 ||
    subscription?.billingProcess === billingProcess.USAGE_BASED_BILLING ||
    subscription?.billingProcess === billingProcess.COMMIT_WITH_OVERAGE
  ) {
    // Don't try to get the price for usage based billing.
    price = null;
  } else if (selectedSubscription.isYearly()) {
    price = selectedSubscriptionPrice?.getYearlyPrice();
  } else {
    price = selectedSubscriptionPrice?.getMonthlyPrice();
  }

  trackBillingEvent('Update Subscription Button Clicked', {
    seatCount: selectedSubscription.getSeatCount(),
    hasSSO: selectedSubscription.hasSSO(),
    price,
    planType: selectedSubscription.planType,
    oldPlan: subscription,
  });
};

export const trackBillingLoaded = () => trackBillingEvent('Main Billing Page Loaded');
export const trackPlanPickerLoaded = () => trackBillingEvent('Billing Plan Picker Page Loaded');
export const trackPlanConfirmationLoaded = () => trackBillingEvent('Billing Plan Confirmation Page Loaded');

export const trackTaxAddressBannerClick = () => trackBillingEvent('Tax Address Collection Banner Clicked');
export const trackTaxAddressBannerDismiss = () => trackBillingEvent('Tax Address Collection Banner Dismissed');

type TrackGetPlanClickedOptions = {
  planYear?: number;
  component: string;
  plan: string;
  unit?: string;
};

export const trackGetPlanClicked = ({ planYear, component, plan, unit }: TrackGetPlanClickedOptions) => {
  const options: TrackGetPlanClickedOptions & { url: string; path: string } = {
    component,
    plan,
    url: window.location.href,
    path: window.location.pathname,
  };

  if (planYear) {
    options.planYear = planYear;
  }

  if (unit) {
    options.unit = unit;
  }

  trackBillingEvent(`Get The ${plan} Plan Button Clicked`, options);
};

export const trackSSOButtonClicked = (verb: string) => trackBillingEvent(`${verb} SSO Button Clicked`);

type TrackLearnMoreOptions = {
  feature: string;
  component: string;
  learnMoreUrl: string;
};

export const trackLearnMoreClicked = ({ feature, component, learnMoreUrl }: TrackLearnMoreOptions) =>
  trackBillingEvent('Billing learn more clicked', {
    feature,
    component,
    url: window.location.href,
    path: window.location.pathname,
    learnMoreUrl,
  });

type TrackMaxPlanOptionReachedOptions = {
  component: string;
  plan: string;
  unit: string;
  planYear?: number;
};

export const trackMaxPlanOptionReached = ({ component, plan, unit, planYear }: TrackMaxPlanOptionReachedOptions) => {
  const options: TrackMaxPlanOptionReachedOptions & { url: string; path: string } = {
    component,
    plan,
    unit,
    url: window.location.href,
    path: window.location.pathname,
  };

  if (planYear) {
    options.planYear = planYear;
  }

  trackBillingEvent('Billing option limit reached', options);
};

export const ubbCMauNewPricingDescription = enableSelfServePaygFreeUnits()
  ? 'First 50,000 free, then $6 per 1,000 client-side MAU, per month'
  : '$6 per 1,000 client-side MAU, per month';
export const ubbCMauPricingDescription = enableSelfServePaygFreeUnits()
  ? 'First 1,000 free, then $10 per 1,000 client-side MAU, per month'
  : '$10 per 1,000 client-side MAU, per month';
export const ubbExperimentationKeysPricingDescription = enableSelfServePaygFreeUnits()
  ? 'First 10,000 free, then $33 per 10,000 Experimentation keys, per month'
  : '$33 per 10,000 Experimentation keys, per month';
export const ubbExperimentationMauPricingDescription = enableSelfServePaygFreeUnits()
  ? 'First 100,000 free, then $3 per 1,000 Experimentation MAU, per month'
  : '$3 per 1,000 Experimentation MAU, per month';
export const ubbEnterpriseContextPricingDescription = 'Variable pricing based on volume';
export const ubbBasePriceDescription = enableSelfServePaygFreeUnits()
  ? 'First 5 free, then $12 per service connection, per month'
  : '$12 per service connection, per month';

export const getSelectedBillingInterval = (
  activePlan?: Plan,
  subscription?: Subscription,
  selectedBillingInterval?: billingIntervals | null,
): billingIntervals => {
  const hasSelectedNewPlanType = activePlan?.type !== subscription?.planType;
  const hasPaidPlan = subscription && subscription.hasPlan() && !subscription.isTrial();

  if (selectedBillingInterval) {
    // If the user selected a billing interval manually, use it.
    return selectedBillingInterval;
  } else if (hasPaidPlan && !hasSelectedNewPlanType) {
    // Use the current paid subscription's billing interval, unless they selected
    // a different plan that they are not yet subscribed to. In that case, use the plans default.
    return subscription.billingInterval;
  }

  if (activePlan?.isDeveloper()) {
    return billingIntervals.MONTHLY;
  }

  // Otherwise fallback to defaults. Foundation defaults to monthly.
  const defaultFoundationPlan = subscription?.billingInterval || billingIntervals.MONTHLY;
  return activePlan?.isFoundation() ? defaultFoundationPlan : billingIntervals.YEARLY;
};
